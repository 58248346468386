/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H1, GhostButton, flexCenteredSpaceBetweenRow, flexRow, Button } from '@bonitour/components'
import { marginBottom, marginRight, hidden } from 'assets/styles/global'
import { CompanyInvoiceInfo } from './CompanyInvoiceInfo'
import { useBinamikInvoiceDetail } from '../../BinamikInvoice/hooks/useBinamikInvoiceDetail'
import { useHistory } from 'react-router-dom'
import { ItemsTable, PaymentsValues } from 'Domains/Financial/components'

const buttonStyle = css`
  padding: 12px 30px;
`
const marginBottom20 = marginBottom(20)
const marginRight16 = marginRight(16)

export const CompanyInvoiceDetails = ({ match }) => {
  const history = useHistory()
  const emitBackClickEvent = () => history.goBack()
  const invoiceId = match.params.binamikInvoiceId ?? 1
  const { onPaymentConfirmation, invoice, receivedAmount, totalAmount, pendingAmount } = useBinamikInvoiceDetail({ invoiceId })
  const isStateClosed = invoice.state === 'closed'

  return (
    <>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <H1>Fatura {invoiceId}</H1>
        <div>
          <Button onClick={onPaymentConfirmation} css={[buttonStyle, marginRight16, !isStateClosed && hidden]}>
            Confirmar pagamento
          </Button>
          <GhostButton onClick={emitBackClickEvent} css={[buttonStyle]}>
            Voltar
          </GhostButton>
        </div>
      </div>
      <Card>
        <CompanyInvoiceInfo invoiceInfo={invoice} isReadOnly />
        <div css={flexRow}>
          <ItemsTable items={invoice.binamikItems} paidValueLabel='Total recebido' />
          <PaymentsValues
            receivedAmount={receivedAmount}
            totalAmount={totalAmount}
            pendingAmount={pendingAmount}
            firstLabel='Total a receber'
            secondLabel='Total recebido'
          />
        </div>
      </Card>
    </>
  )
}
