/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, Card, Checkbox, CircleThumbnail, colors, flexColumnCentered, Label, PartnersIcon } from '@bonitour/components'
import { clickable, marginTop } from 'assets/styles/global'
import { useEffect, useState } from 'react'
import { identity } from '@bonitour/common-functions'
import { useUser } from 'Shared/contexts/User'

const marginTop20 = marginTop(20)

const container = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
`

const roleCard = css`
  position: relative;
  ${flexColumnCentered};
  text-align: center;
`

const iconContainer = css`
  background-color: ${colors.gray12};
`

const icon = css`
  font-size: 28px;
  color: ${colors.gray4};
`

const checkbox = css`
  position: absolute;
  top: 10px;
  left: 10px;
`

const RoleCard = ({
  id = '',
  name = '',
  selected = false,
  disabled = false,
  onRoleClick: emitRoleClick = identity
}) => {
  return (
    <Card css={[roleCard, !disabled && clickable]} onClick={!disabled && emitRoleClick(id)}>
      <Checkbox css={checkbox} checked={selected} disabled={disabled} />
      <CircleThumbnail css={iconContainer} size={80}>
        <PartnersIcon css={icon} />
      </CircleThumbnail>
      <Label css={marginTop20}>{name}</Label>
    </Card>
  )
}

export const RolesForm = ({
  userId = '',
  allRoles = [],
  roles = [],
  onSuccess: emitSuccess = identity
}) => {
  const { user = {} } = useUser()
  const { id: currentEmployeeId } = user?.company?.current_employee || {}
  const isDisabled = currentEmployeeId === userId
  const [selectedRolesId, setSelectedRolesId] = useState([])

  const isRoleSelected = roleId => selectedRolesId.find((id) => id === roleId)

  const onRoleClick = id => () => {
    if (selectedRolesId.includes(id)) {
      setSelectedRolesId(selectedRolesId.filter(item => item !== id))
    } else {
      setSelectedRolesId(selectedRolesId.concat(id))
    }
  }

  const onSaveClick = () => emitSuccess(selectedRolesId)

  useEffect(() => {
    setSelectedRolesId(roles.map(({ id }) => id))
  }, [roles])

  return (
    <>
      <div css={container}>
        {allRoles.map(({ id, name }) => (
          <RoleCard
            key={id}
            id={id}
            name={name}
            disabled={isDisabled}
            selected={isRoleSelected(id)}
            onRoleClick={onRoleClick}
          />
        ))}
      </div>

      <Button css={marginTop20} onClick={onSaveClick} disabled={isDisabled}>Salvar</Button>
    </>
  )
}
