export const UsersParser = {
  user (employee = {}) {
    const {
      id: employeeId,
      user_id: userId,
      company_id: companyId,
      roles,
      active: isActive,
      user
    } = employee

    return {
      employeeId,
      userId,
      companyId,
      roles,
      isActive,
      user
    }
  },
  card ({ meta = {}, employees = [] }) {
    const { current_page: currentPage, total_pages: totalPages = 0, total_entries: total = 0 } = meta
    const users = employees.map(({ id, user_name: name, image, active, roles }) => ({
      id,
      name,
      image,
      roles,
      isActive: active
    }))

    return { users, currentPage, totalPages, total }
  },
  list ({ employees = [] }) {
    const users = employees.map(({ id, user_name: name, image, active, roles }) => ({
      id,
      name,
      image,
      roles,
      isActive: active
    }))

    return { users }
  }
}

export const AllUsersParser = {
  list ({ meta = {}, employees = [] }) {
    const { current_page: page, total_pages: totalPages = 0, total_entries: total = 0 } = meta
    const users = employees.map(({ id, company_name: companyName, user_name: userName, user_email: userEmail }) => ({
      id,
      companyName,
      userName,
      userEmail
    }))

    return { users, page, totalPages, total }
  }
}
