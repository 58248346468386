/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, Card } from '@bonitour/components'

const card = css`
  background-color: ${colors.white};
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 1px 5px #00000014;
  padding: 20px 20px;
  margin-top: 30px;
`

const flexColumn = css`
  flex-direction: column;
  display: flex;
`

const firstText = css`
  font-size: 18px;
  margin-top: 20px;
  color: ${colors.gray2};
  font-weight: bold;
`

const secondText = css`
  font-size: 15px;
  margin-top: 20px;
  color: ${colors.gray2};
  font-weight: 500;
`

const logo = css`
  width: 125px;
`

const detailsCard = css`
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-around;
`

const valueLabel = css`
  font-size: 22px;
  color: ${colors.primary};
  font-weight: bold;
  text-align: center;
`

const infoLabel = css`
  font-size: 14px;
  color: ${colors.gray4};
  margin-top: 5px;
  text-align: center;
`

export const FeesCard = ({ seller, vendor, src, title, subTitle }) => {
  return (
    <Card css={card}>
      <div css={flexColumn}>
        <img css={logo} src={src} alt='Logo pay' />
        <div css={firstText}>{title}</div>
        <div css={secondText}>{subTitle}</div>
      </div>
      {vendor && seller &&
        <Card css={detailsCard}>
          <div css={flexColumn}>
            <div css={valueLabel}>{vendor !== 0 ? `${vendor}%` : 'Grátis'}</div>
            <div css={infoLabel}>do Fornecedor</div>
          </div>
          <div css={flexColumn}>
            <div css={valueLabel}>{seller !== 0 ? `${seller}%` : 'Grátis'}</div>
            <div css={infoLabel}>do Vendedor</div>
          </div>
        </Card>}
    </Card>
  )
}
