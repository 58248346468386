/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Column, Email, Input, InputFormGroup, Row } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { userSchema } from './user.schema'
import { marginTop } from 'assets/styles/global'

const marginTop20 = marginTop(20)

export const UserForm = ({
  user: resetUser = {},
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(resetUser, userSchema)
  const onSave = onSubmit(emitSuccessEvent, emitValidationError)
  const { name = '', email = '' } = form

  return (
    <>
      <Row>
        <Column phone={12} desktop={5}>
          <InputFormGroup errorMessage={errors.name} label='Nome'>
            <Input
              value={name}
              onChange={onInputChange('name')}
              onBlur={onInputBlur('name')}
              disabled
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={5}>
          <InputFormGroup errorMessage={errors.email} label='E-mail'>
            <Email
              name='email'
              value={email}
              onChange={onInputChange('email')}
              onBlur={onInputBlur('email')}
              disabled
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Button css={marginTop20} onClick={onSave} disabled>Salvar</Button>
    </>
  )
}
