import { colors, BREAK_POINTS } from '@bonitour/components'

import { css } from '@emotion/core'
import { marginBottom, marginRight, marginTop } from 'assets/styles/global'

export const marginTop50 = marginTop(50)
export const marginBottom20 = marginBottom(20)
export const marginRight20 = marginRight(20)

export const width130 = css`
  width: 130px;
`

export const inputGroup = css`
  width: 100%;
  ${marginRight20};
`

export const buttonStyle = css`
  height: 20px;
  margin-top: 28px;
  padding: 5px 10px;
  background-color: ${colors.primaryVariant};
  font-size: 14px;
  font-weight: 400;
  color: ${colors.white};
  border-radius: 5px;
  cursor: pointer;
`

export const cep = css`
   @media (max-width: ${BREAK_POINTS.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const padding0 = css`
  @media (max-width: ${BREAK_POINTS.tablet}) {
    padding: 0;
  }
`

export const marginTop7 = css`
  @media (max-width: ${BREAK_POINTS.tablet}) {
    ${marginTop(7)}
  }
`
