import React, { createContext, useContext, useCallback, useMemo, useEffect } from 'react'
import { useUser } from './User'
import { useHistory } from 'react-router-dom'
import { UserStore } from 'Core/Store/User'

export const AuthorizationContext = createContext({})

export const useAuthorization = () => useContext(AuthorizationContext)

const validateRouteType = (route, profileType) => {
  if (route?.startsWith('/app')) {
    return profileType === 'agent'
  }
  if (route?.startsWith('/affiliate-app')) {
    return profileType === 'affiliate'
  }
  return true
}

export const AuthorizationProvider = (props) => {
  const history = useHistory()
  const { authKey, updateAuthKey, profileType } = useUser()

  useEffect(() => {
    if (props?.requireLogin ?? true) {
      if (!authKey) {
        history.push('/auth/login')
      }
    }
    if (!validateRouteType(history.location.pathname, profileType)) {
      history.push('/')
    }
  }, [authKey, profileType, history, props?.requireLogin])

  const logout = useCallback(() => {
    updateAuthKey()
    localStorage.clear()
    UserStore.clear()
  }, [updateAuthKey])

  const providerData = useMemo(() => ({ logout }), [logout])

  return (
    <AuthorizationContext.Provider value={providerData} {...props} />
  )
}
