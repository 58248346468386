import Axios from 'axios'

function LocalityListParser (localityList = []) {
  return localityList
    .map(({ id, name, abbreviation, state_code: stateCode }) => ({
      value: id,
      label: name,
      initials: abbreviation || stateCode
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

const LocalitiesService = Axios.create({
  baseURL: process.env.REACT_APP_API_LOCALITIES
})

export const LocalitiesApi = {
  listCities (countryId, stateId) {
    const stateListResource = `/v1/countries/${countryId}/states/${stateId}/cities`
    return LocalitiesService.get(stateListResource).then(({ data }) => LocalityListParser(data))
  },
  listCountries () {
    const countryListResource = '/v1/countries'
    return LocalitiesService.get(countryListResource).then(({ data }) => LocalityListParser(data))
  },
  listStates (countryId) {
    const stateListResource = `/v1/countries/${countryId}/states`
    return LocalitiesService.get(stateListResource).then(({ data }) => LocalityListParser(data))
  }
}
