/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PlusIcon, UnderlineNavContainer, UnderlineNavItem, TabContainer, UnderlineTabPane, Label, InputWithSuffix, SearchIcon, BREAK_POINTS, HeaderPage } from '@bonitour/components'
import { padding0, cardButton, cardIcon, labelCardButton } from './ListUsers.styles'
import { usePaginatedUsers } from './hooks/usePaginatedUsers'
import { useHistory } from 'react-router-dom'
import { useUserToggleActive } from './hooks/useUserToggleActive'
import { marginBottom } from 'assets/styles/global'
import { useQuery } from 'Shared/hooks/useQuery'
import { CardButton } from 'Shared/components/CardButton'
import { InviteUser } from './InviteUser'
import { PaginationUsers } from './PaginationUsers'
import { RolesList } from '../ListRoles/RolesList'
import { useUnpaginatedRoles } from '../ListRoles/hooks/useUnpaginatedRoles'
import { useDebounce } from '@bonitour/app-functions'
import { useEffect, useMemo, useState } from 'react'

const marginBottom10 = marginBottom(10)

const search = css`
  width: 440px;
  margin-top: 20px;
  margin-left: 10px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
    margin-left: 0px;
  }
`

const searchIcon = css`
  font-size: 20px;
`

export const ListUsers = () => {
  const { push } = useHistory()
  const { usersInfo, onFiltersSubmit, onPaginationChange, pagination: usersPaginationData, isLoading } = usePaginatedUsers()
  const { allRoles, onRoleDelete, loading: loadingRoles } = useUnpaginatedRoles()
  const { onToggleActive, loadingToggleActiveId } = useUserToggleActive()
  const { selectedNav = 'users' } = useQuery()
  const setSelectedNav = (value) => push(`?selectedNav=${value}`)
  const backToDashboard = () => push('/app')
  const onClickUser = id => push(`/app/users/${id}/edit`)
  const [query, setQuery] = useState('')
  const queryWithDebounce = useDebounce(query, 200)
  const onRoleCardClick = roleId => push(`/app/role/${roleId}`)
  const onCreateNewRoleCardClick = () => push('/app/role/create')

  const paginationDataProp = useMemo(() => ({ ...usersPaginationData, pagesQuantity: usersInfo?.totalPages, totalEntries: usersInfo?.total }), [usersInfo?.total, usersInfo?.totalPages, usersPaginationData])

  useEffect(() => {
    queryWithDebounce ? onFiltersSubmit({ name: query }) : onFiltersSubmit({})
    // eslint-disable-next-line
  }, [queryWithDebounce])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Usuários' />

      <UnderlineNavContainer selectedNav={selectedNav} onChange={setSelectedNav}>
        <UnderlineNavItem navId='users'>Todos usuários</UnderlineNavItem>
        <UnderlineNavItem navId='roles'>Papéis de permissão</UnderlineNavItem>
      </UnderlineNavContainer>
      <TabContainer activeTab={selectedNav}>
        <UnderlineTabPane tabId='users' css={padding0}>
          <InviteUser roles={allRoles}>
            <CardButton css={cardButton}>
              <PlusIcon css={cardIcon} />
              <Label css={labelCardButton}>Convidar usuário</Label>
            </CardButton>
          </InviteUser>

          {usersInfo?.users &&
            <InputWithSuffix css={search} value={query} onChange={setQuery} placeholder='Buscar usuário'>
              <SearchIcon css={searchIcon} />
            </InputWithSuffix>}

          <PaginationUsers
            paginationData={paginationDataProp}
            users={usersInfo?.users}
            onPagination={onPaginationChange}
            onClickToggleActive={onToggleActive}
            onClickUser={onClickUser}
            loadingToggleActiveId={loadingToggleActiveId}
            isLoading={isLoading}
          />
        </UnderlineTabPane>

        <UnderlineTabPane tabId='roles' css={padding0}>
          <CardButton css={[cardButton, marginBottom10]} onClick={onCreateNewRoleCardClick}>
            <PlusIcon css={cardIcon} />
            <Label css={labelCardButton}>Novo papel</Label>
          </CardButton>

          <RolesList
            roles={allRoles}
            onRoleCardClick={onRoleCardClick}
            onRoleDelete={onRoleDelete}
            loading={loadingRoles}
          />
        </UnderlineTabPane>
      </TabContainer>
    </>
  )
}
