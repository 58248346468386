import { OrbStore } from 'Core/Service/Orb/Store'
const ACTIVITY_ID_KEY = 'orb-id'
const SELECTED_ID_KEY = 'orb-selected'
const LIST_ID_KEY = 'orb-list'

export const ActivityStore = {
  getId () {
    return OrbStore.get(ACTIVITY_ID_KEY) || ''
  },
  setId (value) {
    OrbStore.set(ACTIVITY_ID_KEY, value)
    return value
  },
  getList () {
    try {
      return JSON.parse(OrbStore.get(LIST_ID_KEY)) || []
    } catch (error) {
      return []
    }
  },
  setList (value) {
    try {
      OrbStore.set(LIST_ID_KEY, JSON.stringify(value))
      return value
    } catch (error) {
      return []
    }
  },
  getActivity () {
    try {
      return JSON.parse(OrbStore.get(SELECTED_ID_KEY)) || {}
    } catch (error) {
      return {}
    }
  },
  setActivity (value) {
    try {
      OrbStore.set(SELECTED_ID_KEY, JSON.stringify(value))
      return value
    } catch (error) {
      return {}
    }
  },
  clear () {
    OrbStore.remove(ACTIVITY_ID_KEY)
    OrbStore.remove(SELECTED_ID_KEY)
    OrbStore.remove(LIST_ID_KEY)
  }
}
