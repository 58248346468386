/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AffiliateCompanySalesTotalizer } from './AffiliateCompanySalesTotalizer'
import { pageContainer } from './AffiliateCompany.style'
import { AffiliateSalesHeader } from 'Domains/Affiliates/AffiliateSalesHeader'
import { AffiliateSalesBody } from 'Domains/Affiliates/AffiliateSalesBody'

import { useAffiliate } from '../../hooks/useAffiliate'

export const AffiliateCompany = () => {
  const {
    meta,
    salesData,
    affiliate,
    goToAffiliateHome,
    totalizerData,
    isLoading,
    filters,
    onFilterSubmit,
    onPageChange,
    onPerPageChange,
    batchTotals
  } = useAffiliate()

  return (
    <div css={pageContainer}>
      <AffiliateSalesHeader onBack={goToAffiliateHome} affiliate={affiliate} />
      <AffiliateCompanySalesTotalizer
        totalizerData={totalizerData}
        affiliate={affiliate}
        batchTotals={batchTotals}
      />
      <AffiliateSalesBody
        sales={salesData}
        meta={meta}
        isLoading={isLoading}
        onFilterSubmit={onFilterSubmit}
        filters={filters}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
    </div>
  )
}
