/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { Accordion, CollapsableSection, HeaderPage } from '@bonitour/components'
import { useQuery } from 'Shared/hooks/useQuery'
import { CreateCompanyRole } from '../CreateCompanyRole/CreateCompanyRole'
import { useMacroPermissions } from '../hooks/useMacroPermissions'
import { useCreateCompanyRole } from '../hooks/useCreateCompanyRole'

const defaultCursor = css`
  cursor: default;
`

export const CreateRole = () => {
  const { push } = useHistory()
  const backToDashboard = () => push('/app/users?selectedNav=roles')
  const { macroPermissions } = useMacroPermissions()
  const { onCreateRole, loading } = useCreateCompanyRole()
  const { accordion = 1 } = useQuery()
  const setIndicatorActive = accordionValue => push({ search: `?accordion=${accordionValue}` })
  const role = { name: '', permissions: '' }

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Novo papel de permissão' />

      <Accordion value={Number(accordion)} onClick={setIndicatorActive}>
        <CollapsableSection indicator={1} css={defaultCursor} title='Configuração de papel' topics='Nome do papel / Permissões'>
          <CreateCompanyRole macroPermissions={macroPermissions} role={role} onSubmit={onCreateRole} loading={loading} />
        </CollapsableSection>
      </Accordion>
    </>
  )
}
