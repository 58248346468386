/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Terms } from '../Terms'

export const TouristTerms = () => {
  return (
    <Terms type='tourist_terms_of_use' />
  )
}
