/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, Card } from '@bonitour/components'

const cardButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  min-width: 98px;
  padding: 6px;
  margin-right: 20px;
  color: ${colors.gray4};
  box-shadow: 0px 0px 6px #0000001a;
  cursor: pointer;

  label {
    width: 110px;
    text-align: center;
    line-height: 1.3;
  }
`

export const CardButton = props => <Card css={cardButton} {...props} />
