import { css } from '@emotion/core'
import { colors, BREAK_POINTS } from '@bonitour/components'

export const width5 = css`
  width: 5%;
`

export const width95 = css`
  width: 95%;
`

export const iconSize = css`
  font-size: 23px;
`

export const containedInput = css`
  width: 200px;
`

export const sameLineInput = css`
  margin: 0 10px;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    margin: 7px 0;
  }
`

export const customCardPadding = css`
  padding: 10px 20px;
`

export const overwriteSizes = css`
  width: 45px;
  background-size: 40px;
`

export const stylishCard = css`
  border: none;
  color: ${colors.gray3};

  &:nth-of-type(odd) {
    background-color: ${colors.gray11};
  }

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    position: relative;
  }
`
export const fieldSection = css`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
  }
`

export const fieldContainer = css`
   @media (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
  }
`
