/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const cardIcon = css`
  font-size: 24px;
  padding: 4px 5px;
  color: ${colors.gray4};
`

export const redeemCardIcon = css`
  ${cardIcon}
  font-size: 18px;
`

export const integrationsCardIcon = css`
  ${cardIcon}
  font-size: 20px;
`

export const smallIcons = css`
  transform: scale(1.2)
`

export const labelCardButton = css`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  color: ${colors.gray4};
`

export const floating = css`
  align-items: flex-end;
  position: fixed;
  bottom: 40px;
  right: 60px;
`

export const buttonContainer = css`
  margin-bottom: 10px;
  margin-right: 5px;
`

export const whiteButton = css`
  background-color: ${colors.white};
  color: ${colors.gray3};
  border-color: transparent;
  box-shadow: 0 0 5px 0.02em rgba(0, 0, 0, 0.25);
`

export const angleDownStyle = css`
  font-size: 12px;
  color: ${colors.gray4};
`

export const linkColor = css`
  color: ${colors.gray1};
  padding: 5px;
  margin: 5px 0;
`

export const cardItems = css`
  min-width: 120px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
`

export const cardItemIcon = css`
  min-height: 28px;
`

export const cardButton = css`
  height: 75px;
  box-sizing: border-box;
  margin: 0 0 10px 0;
`

export const loadingCard = css`
  background: ${colors.gray13};
  pointer-events: none;
  cursor: default;
`

export const percentageIconStyle = css`
  font-size: 1.25em;
  margin-right: 0.25ch;
`

export const commingSoonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding: 5px 8px;
  margin-left: 4px;
  border-radius: 6px;
  background-color: ${colors.primary};
  
  font-size: 10px;
  font-weight: 700;
  color: ${colors.white};
`
