export const parseActivityRules = (activity = {}) => {
  const {
    min_age: minAge,
    max_weight: maxWeight,
    min_height: minHeight
  } = activity.restrictions || {}

  const maxWeightNormalized = maxWeight ? Number(maxWeight) : 0
  const minHeightNormalized = minHeight ? Number(minHeight) : 0

  return {
    restrictions: {
      minAge: minAge.toString(),
      maxWeight: maxWeightNormalized.toString(),
      minHeight: minHeightNormalized.toString()
    }
  }
}
