import { useState, useCallback, useEffect } from 'react'
import { RolesService } from 'Core/Service/Roles'
import { usePermissions } from 'Shared/hooks/usePermissions'
import { useToast } from '@bonitour/components'

export const useCompanyRole = roleId => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [companyRole, setCompanyRole] = useState({})
  const [macroPermissions, setMacroPermissions] = useState([])
  const [loading, setLoading] = useState(false)

  const updateRole = async ({ id, name, selectedPermissions }) => {
    setLoading(true)
    return RolesService.updateCompanyRole({ name, selectedPermissions }, id)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de editar papéis')
        hasPermission && addToast('Ocorreu um erro ao editar o papel de permissão')
      })
      .finally(() => setLoading(false))
  }

  const fetchRole = useCallback(() => {
    setLoading(true)
    RolesService.getCompanyRole(roleId).then((role) => setCompanyRole(role)).finally(() => setLoading(false))
  }, [roleId])

  const fetchMacroPermissions = useCallback(() => {
    RolesService.getMacroPermissions().then((macroPermissions) => setMacroPermissions(macroPermissions))
  }, [])

  const onRemoveUsers = users => {
    RolesService.removeEmployees(roleId, users)
      .then(() => {
        addToast('Usuários desvinculados do papel de permissão com sucesso', 'success')
        fetchRole()
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão para desvincular usuários de papéis')
        hasPermission && addToast('Ocorreu um erro ao desvincular os usuários')
      })
  }

  useEffect(() => {
    fetchRole()
  }, [fetchRole])

  useEffect(() => { fetchMacroPermissions() }, [fetchMacroPermissions])

  return {
    submit: updateRole,
    onRemoveUsers,
    companyRole,
    macroPermissions,
    loading
  }
}
