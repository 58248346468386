/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react'
import { Accordion, CollapsableSection, useToast, HeaderPage, LoadingAnimation } from '@bonitour/components'
import { useQuery } from 'Shared/hooks/useQuery'
import { useHistory, useParams } from 'react-router-dom'
import { UserForm } from '../Form/UserForm'
import { UserService } from 'Core/Service/User'
import { useUnpaginatedRoles } from '../ListRoles/hooks/useUnpaginatedRoles'
import { RolesForm } from '../Form/RolesForm'
import { loadingContainer } from 'assets/styles/global'

export const EditUser = () => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { accordion = 1 } = useQuery()
  const { userId } = useParams()
  const [employee, setEmployee] = useState({})
  const { user, roles } = employee || {}
  const { allRoles, loading } = useUnpaginatedRoles()

  const backToDashboard = () => push('/app/users')

  const setIndicatorActive = accordionValue => push({ search: `?accordion=${accordionValue}` })

  useEffect(() => {
    UserService.getById(userId).then(setEmployee)
  }, [userId])

  const onSuccess = async user => {
    try {
      // await UserService.update(user)
      addToast('Usuário editado com sucesso', 'success')
    } catch (error) {
      addToast('Houve um erro na edição do usuário')
    }
  }

  const onRolesSuccess = async roles => {
    try {
      await UserService.editUserRoles(userId, roles)
      addToast('Papéis de permissão atualizado com sucesso', 'success')
      backToDashboard()
    } catch (error) {
      addToast('Ocorreu um erro ao salvar os papéis de permissão do usuário')
    }
  }

  const onValidationError = async () => {
    addToast('Preencha corretamente o formulário')
  }

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Usuário' />

      <Accordion value={Number(accordion)} onClick={setIndicatorActive}>
        <CollapsableSection indicator={1} title='Informações básicas' topics='Nome / Contato / Endereço'>
          <UserForm user={user} onSuccess={onSuccess} onValidationError={onValidationError} />
        </CollapsableSection>

        <CollapsableSection indicator={2} title='Papéis de permissão' topics='Grupos de usuários'>
          {loading && <LoadingAnimation size={30} css={loadingContainer} />}
          <RolesForm userId={userId} allRoles={allRoles} roles={roles} onSuccess={onRolesSuccess} />
        </CollapsableSection>
      </Accordion>
    </>
  )
}
