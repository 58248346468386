/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H4, Card, flexColumn, Input, Label, Column, TrashIcon, EmptyResult, TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, ConfirmDialog, TooltipOnHover, scrollBar } from '@bonitour/components'
import { NavLink } from 'react-router-dom'
import { AddLabel } from 'Shared/components/AddLabel'
import { head, identity } from '@bonitour/common-functions'
import { clickable, marginTop } from 'assets/styles/global'
import { useState } from 'react'

const marginTop30 = marginTop(30)

export const card = css`
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const icon = css`
  font-size: 22px;
`

export const noDecoration = css`
  text-decoration: none;
`
const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

const ActivitiesIntegrationTableRow = ({ activity = {}, onDeleteActivity: emitDeleteActivity = identity }) => {
  const { integrationId, id: integrationActivityId, activityName, externalId } = activity

  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)
  const handleClickDelete = (integrationId, integrationActivityId) => () => {
    emitDeleteActivity(integrationId, integrationActivityId)
    toggleConfirmActionHidden()
  }

  return (
    <>
      <ConfirmDialog
        title='Desvinculação de atividade'
        message='A atividade será desvinculada do voucher. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={handleClickDelete(integrationId, integrationActivityId)}
        cancelCallback={toggleConfirmActionHidden}
      />

      <TableBodyRow key={integrationActivityId}>
        <TableBodyCell>{activityName}</TableBodyCell>
        <TableBodyCell>{externalId}</TableBodyCell>
        <TableBodyCell fixed>
          <TooltipOnHover text='Desvincular atividade' size={150}>
            <TrashIcon onClick={toggleConfirmActionVisible} css={[icon, clickable]} />
          </TooltipOnHover>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

export const ExpandRegistrations = ({ integration = {}, loading, deleteActivity: emitDeleteActivity = identity }) => {
  const { externalId = '', activities = [], id: integrationId } = integration
  const registerActivityUrl = `/app/integrations/${integrationId}/register`
  const hasActivities = Boolean(head(activities))

  return (
    <>
      <H4>Empresa</H4>
      <Card css={card}>
        <Column phone={12} desktop={4} css={flexColumn}>
          <Label>Código da empresa</Label>
          <Input value={externalId} disabled />
        </Column>
      </Card>

      <H4 css={marginTop30}>Atividades</H4>

      {!hasActivities && <EmptyResult size={144} title='Não há atividades vinculadas' subtitle='' />}

      {hasActivities &&
        <div css={tableContainer}>
          <TableContainer>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell>Nome da atividade</TableHeaderCell>
                <TableHeaderCell>Código da atividade</TableHeaderCell>
                <TableHeaderCell fixed />
              </TableHeaderRow>
            </TableHeader>
            <TableBody loading={loading}>
              {activities.map((activity, index) => {
                return <ActivitiesIntegrationTableRow key={index} activity={activity} onDeleteActivity={emitDeleteActivity} />
              })}
            </TableBody>
          </TableContainer>
        </div>}

      <NavLink to={registerActivityUrl} css={noDecoration}>
        <AddLabel>Vincular nova Atividade</AddLabel>
      </NavLink>
    </>
  )
}
