/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Card, H3, Row, Column, Select, Input, useToast, colors, Button, InputFormGroup, HeaderPage } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { jsx, css } from '@emotion/core'
import { useMemo, useCallback } from 'react'
import { registerActivitySchema } from './RegisterActivity.schema'
import { useRegisterActivity } from './../hooks/useRegisterActivity'
import { useActivity } from 'Shared/contexts/Activity'
import { useHistory } from 'react-router-dom'

const rowBlock = css`
  padding: 20px;
  border-radius: 10px;
  margin: 40px 0px 40px 0;
  background-color: ${colors.gray14};
`

const labelActivities = (activities) => {
  const activitiesLabeled = []
  activities.map((activity) => {
    const acvitityLabeled = {}
    acvitityLabeled.value = activity.id
    acvitityLabeled.label = activity.name
    activitiesLabeled.push(acvitityLabeled)
    return null
  })
  return activitiesLabeled
}

export const RegisterActivity = ({ integration = {} }) => {
  const { add: addToast } = useToast()
  const { submit } = useRegisterActivity()
  const { companyActivities = [] } = useActivity()
  const history = useHistory()
  const redirectToList = () => history.goBack()

  const integrationBase = useMemo(
    () =>
      Object.keys(integration).length > 0 || {
        id: '',
        code: '',
        active: true
      },
    // eslint-disable-next-line
    []
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(integrationBase, registerActivitySchema)

  const {
    id = integrationBase.id,
    code = integrationBase.code
  } = form

  const onValidationError = useCallback(() => {
    addToast('Preencha corretamente o formulário')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationSuccess = useCallback((data) => {
    submit(data)
      .then(() => {
        addToast('Atividade vinculada com sucesso', 'success')
        redirectToList()
      })
      .catch((e) => {
        const errorMessage = e?.data?.errors_msg?.[0]

        const errorMessages = {
          'ticket_seller::integration_service::service_id::taken': 'Atividade já vinculada',
          default: 'Erro ao adicionar atividade'
        }

        addToast(errorMessages[errorMessage] ?? errorMessages.default)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [
    onSubmit,
    onValidationError,
    onValidationSuccess
  ])

  return (
    <>
      <HeaderPage onBack={redirectToList} title='Vincular atividade' />
      <Card>
        <H3>Nome e código</H3>
        <Row css={rowBlock}>
          <Column phone={12} desktop={6}>
            <InputFormGroup errorMessage={errors.id} label='Nome da atividade'>
              <Select
                placeholder='Selecione uma atividade'
                options={labelActivities(companyActivities)}
                value={id}
                error={errors.id}
                onChange={onInputChange('id')}
              />
            </InputFormGroup>
          </Column>
          <Column phone={12} desktop={6}>
            <InputFormGroup errorMessage={errors.code} label='Código da atividade'>
              <Input value={code} onChange={onInputChange('code')} onBlur={onInputBlur('code')} />
            </InputFormGroup>
          </Column>
        </Row>
        <Button onClick={handleSubmit}>Adicionar</Button>
      </Card>
    </>
  )
}
