/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, Card, Button } from '@bonitour/components'

const card = css`
  background-color: ${colors.white};
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 225px;
  height: 350px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px #00000014;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 30px;
  margin-right: 20px;
`

const price = css`
  font-size: 22px;
  color: ${colors.primary};
  font-weight: bold;
`
const title = css`
  font-size: 18px;
  color: ${colors.gray2};
  font-weight: bold;
  margin-bottom: 8px;
`

const subTitle = css`
  font-size: 12px;
  color: ${colors.gray4};
  margin-top: 5px;
`

const textInfo = css`
  font-size: 12px;
  color: ${colors.red1};
  margin-top: 15px;
  max-width: 150px;
`

const priceContainer = css`
  text-align: center;
`

const separationLine = css`
  background-color: ${colors.gray6};
  opacity: 20%;
  width: 175px;
  height: 2px;
`

const limitContainer = css`
  text-align: center;
  justify-content: center;
`

const startText = css`
  font-size: 16px;
  color: ${colors.gray2};
  font-weight: 600;
`

const finishText = css`
  font-size: 16px;
  color: ${colors.primary};
  font-weight: 600;
  margin-left: 5px;
`

const directionRow = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`

const choosePlanButtonSelected = css`
  margin-top: 20px;
  border: 1px solid ${colors.secondary};
  color: ${colors.secondary};
  background-color: transparent;
  width: 175px;
`

export const PlanCard = ({ plan }) => {
  const { monthlyPrice, activitiesNumber, name, activityCost } = plan
  return (
    <Card css={card}>
      <div css={priceContainer}>
        <div css={title}>Plano {name}</div>
        {monthlyPrice ? <div css={price}>{monthlyPrice === 0 ? 'Grátis' : `R$ ${monthlyPrice}`}</div> : '-'}
        <div css={subTitle}>{monthlyPrice === 0 ? null : 'mensal'} + taxas*</div>
        <div css={textInfo}>{monthlyPrice === 0 ? null : 'Abatimento do plano na cobrança das taxas.'}</div>
      </div>
      <div css={separationLine} />
      <div css={limitContainer}>
        {Boolean(activitiesNumber) &&
          <div css={directionRow}>
            <div css={startText}>{activitiesNumber === 0 ? 'Atividades' : 'Até'}</div>
            <div css={finishText}>{activitiesNumber === 0 ? 'Ilimitadas' : `${activitiesNumber} atividades`}</div>
          </div>}
        {Boolean(activityCost) &&
          <div css={directionRow}>
            <div css={startText}>{activityCost === 0 ? 'Atividades gratuitas' : `R$ ${activityCost} por atividade`}</div>
          </div>}
        <Button css={choosePlanButtonSelected}>Escolhido</Button>
      </div>
    </Card>
  )
}
