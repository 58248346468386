/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, H3, flexRowCentered, flexColumnCentered, HeaderPage } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { marginTop } from 'assets/styles/global'
import { useHistory } from 'react-router-dom'
import { PlanCard } from '../Card/PlanCard'
import { FeesCard } from '../Card/FeesCard'
import PayLogo from 'Shared/svgs/logoBinamikPay.svg'
import OrbLogo from 'Shared/svgs/logoOrb.svg'
import XpertLogo from 'Shared/svgs/logoXpert.svg'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'
import { useFinancialPlans } from '../hooks/useFinancialPlans'

const marginTop40 = marginTop(40)

const directionRow = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`

const width120 = css`
  width: 120px;
`

export const Plans = () => {
  const { push } = useHistory()
  const backToHome = () => push('/app')
  const { selectedPlan, loading } = useFinancialPlans()

  if (loading) {
    return (
      <div css={[flexRowCentered, flexColumnCentered]}>
        <img css={width120} src={BinamikLogo} alt='Logo binamik' />
      </div>
    )
  }

  return (
    <>
      <HeaderPage onBack={backToHome} title='Planos e taxas' />

      <Card>
        <H3>Seu plano</H3>

        <div css={directionRow}>
          <PlanCard plan={selectedPlan} />
        </div>

        <H3 css={marginTop40}>*Taxas cobradas</H3>

        <FeesCard
          src={OrbLogo}
          title='Reservas offline'
          subTitle='Para reservas feitas com o Binamik Orb, sem pagamento online.'
          seller={selectedPlan.orbSellerTax}
          vendor={selectedPlan.orbVendorTax}
        />

        <FeesCard
          src={PayLogo}
          title='Reservas com pagamento online'
          subTitle='Para reservas feitas com o Binamik Pay onde os pagamentos são feitos por link e de forma online.'
          seller={selectedPlan.paySellerTax}
          vendor={selectedPlan.payVendorTax}
        />

        <FeesCard
          src={XpertLogo}
          title='Reservas com pagamento online + inteligência'
          subTitle='Para reservas feitas com o Binamik Xpert onde as reservas são organizadas com inteligência em nossa interface.'
          seller={selectedPlan.xpertSellerTax}
          vendor={selectedPlan.xpertVendorTax}
        />
      </Card>

    </>
  )
}
