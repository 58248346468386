import { MetaParser } from './Meta'
import { formatISOTime } from 'Utils/time'

export const BinamikInvoiceParser = {
  single: (binamikInvoice = {}) => {
    const {
      company_id: companyId,
      state,
      due_date: dueDate,
      monthly_value: monthlyValue,
      value,
      id,
      invoice_code: code,
      observation,
      document_number: documentNumber,
      discharge_date: dischargeDate,
      end_competence: endCompetence,
      start_competence: startCompetence,
      binamik_invoice_items: binamikItems,
      company_name: companyName,
      plan_name: planName
    } = binamikInvoice

    return {
      companyName,
      companyId,
      planName,
      state,
      dueDate: dueDate ? formatISOTime(dueDate) : undefined,
      monthlyValue,
      value,
      id,
      code,
      observation,
      documentNumber,
      dischargeDate: dischargeDate ? formatISOTime(dischargeDate) : undefined,
      endCompetence: endCompetence ? formatISOTime(endCompetence) : undefined,
      startCompetence: startCompetence ? formatISOTime(startCompetence) : undefined,
      binamikItems: binamikItems || {}
    }
  },
  list: ({ meta, binamik_invoices: binamikInvoices = [] }) => {
    return {
      binamikInvoices: binamikInvoices.map(BinamikInvoiceParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  details (binamikInvoice = {}) {
    const {
      company_id: companyId,
      state,
      due_date: dueDate,
      monthly_value: monthlyValue,
      value,
      id,
      invoice_code: code,
      observation,
      document_number: documentNumber,
      discharge_date: dischargeDate,
      end_competence: endCompetence,
      start_competence: startCompetence,
      binamik_invoice_items: binamikItems,
      company_name: companyName,
      plan_name: planName
    } = binamikInvoice.binamik_invoice

    return {
      companyName,
      companyId,
      planName,
      state,
      dueDate: dueDate ? formatISOTime(dueDate) : undefined,
      monthlyValue,
      value,
      id,
      code,
      observation,
      documentNumber,
      dischargeDate: dischargeDate ? formatISOTime(dischargeDate) : undefined,
      endCompetence: endCompetence ? formatISOTime(endCompetence) : undefined,
      startCompetence: startCompetence ? formatISOTime(startCompetence) : undefined,
      binamikItems
    }
  }

}
