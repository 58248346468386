export const alertsType = [
  'reservation_canceled',
  'ticket_near_deadline',
  'ticket_expired_deadline'
]

export const alertTypeMap = {
  reservation_canceled: {
    label: 'Reserva cancelada',
    detail: 'Alertar quando houver cancelamento de reserva'
  },
  ticket_near_deadline: {
    label: 'Deadline dos ingressos',
    detail: 'Alertar sobre ingressos sem confirmação 24 horas antes da data de deadline'
  },
  ticket_expired_deadline: {
    label: 'Ingressos que chegaram ao deadline',
    detail: 'Alertar sobre ingressos que passaram da data de deadline'
  }
}
