import { useState, useCallback, useEffect } from 'react'
import { RolesService } from 'Core/Service/Roles'

export const useMacroPermissions = () => {
  const [macroPermissions, setMacroPermissions] = useState([])

  const fetchMacroPermissions = useCallback(() => {
    RolesService.getMacroPermissions().then((macroPermissions) => setMacroPermissions(macroPermissions))
  }, [])

  useEffect(() => { fetchMacroPermissions() }, [fetchMacroPermissions])

  return {
    macroPermissions
  }
}
