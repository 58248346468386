export const integrationsOptions = [
  {
    label: 'Voucher Único',
    value: 'Voucher Unico'
  },
  {
    label: 'Portal Turis',
    value: 'Voucher Turis'
  },
  {
    label: 'Twilio SMS',
    value: 'Twilio SMS'
  }
]
