import { css } from '@emotion/core'

export const totalizerFieldModalStyle = css`
  .box {
    min-height: 3rem;
    margin-top: 1rem;

    > label {
      font-weight: 500;
      font-size: 1rem;
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: justify;
    }
  }
`
