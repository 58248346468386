import { useState, useCallback } from 'react'

export const useSimpleStorage = (storageGetter, storageSetter) => {
  const [state, setState] = useState(() => storageGetter())
  const changeValues = useCallback(
    value => {
      setState(value)
      storageSetter(value)
    },
    [storageSetter]
  )
  return [state, changeValues]
}
