export const TimeZoneParser = {
  listFromApi ({ timezones }) {
    return timezones.map(({
      identifier,
      utc_offset
    }) => ({
      identifier,
      utcOffset: utc_offset
    }))
  }
}
