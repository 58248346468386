/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Terms } from '../Terms'

export const CompanyTerms = () => {
  return (
    <Terms type='company_terms_of_use' />
  )
}
