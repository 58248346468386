import React, { createContext, useContext, useMemo, useCallback, useEffect, useState } from 'react'
import { ActivityStore } from 'Core/Store/Activity'
import { useSimpleStorage } from 'Shared/hooks/useSimpleStorage'
import { ActivityService } from 'Core/Service/Activity/Service'
import { useParams, useHistory } from 'react-router-dom'

export const ActivityContext = createContext({})

export const useActivity = () => {
  const { activityId } = useParams()
  const methods = useContext(ActivityContext)
  const { id, setId } = methods

  useEffect(() => {
    if (activityId && activityId !== id) {
      setId(activityId)
    }
  }, [activityId, setId, id])

  return methods
}

let wasActivityPreviouslyRequested = false

export const ActivityProvider = ({ children }) => {
  const [id, setId] = useSimpleStorage(ActivityStore.getId, ActivityStore.setId)
  const [list, setList] = useSimpleStorage(ActivityStore.getList, ActivityStore.setList)
  const [activity, setActivity] = useSimpleStorage(ActivityStore.getActivity, ActivityStore.setActivity)
  const [loading, setLoading] = useState(true)

  const updateList = useCallback(() => {
    ActivityService.list().then(setList).finally(() => setLoading(false))
  }, [setList])

  const companyActivities = useMemo(() => list.filter(({ isPartner }) => !isPartner), [list])
  const partnerActivities = useMemo(() => list.filter(({ isPartner }) => isPartner), [list])

  const getActivityById = useCallback((searchId) => list.find(({ id: activityId }) => activityId === searchId), [list])

  const { location } = useHistory()
  const enableFetchSellableList = useMemo(() => {
    return ['affiliate', 'integrations'].some((route) => location.pathname.includes(route))
  }, [location.pathname])

  useEffect(() => {
    const selectedActivity = list.find(({ id: activityId }) => activityId === id)
    if (selectedActivity) {
      setActivity(selectedActivity)
    } else {
      if (enableFetchSellableList && !wasActivityPreviouslyRequested) {
        wasActivityPreviouslyRequested = true
        updateList()
      } else {
        wasActivityPreviouslyRequested = false
        setActivity({})
      }
    }
  }, [id, list, updateList, setActivity, enableFetchSellableList])

  const contextData = useMemo(() => ({
    getActivityById,
    id,
    list,
    setId,
    activity,
    companyActivities,
    partnerActivities,
    loading,
    updateList
  }), [id, list, setId, activity, updateList, companyActivities, partnerActivities, loading, getActivityById])

  return (
    <ActivityContext.Provider value={contextData}>
      {children}
    </ActivityContext.Provider>
  )
}
