import { colors } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginBottom, marginLeft, marginTop } from 'assets/styles/global'

export const marginBottom20 = marginBottom(20)
export const marginBottom10 = marginBottom(10)
export const marginBottom0 = marginBottom(0)
export const marginLeft5 = marginLeft(5)
export const marginTop20 = marginTop(20)

export const width180 = css`
  width: 180px;
`

export const cardStyle = css`
  margin: 10px 0;
  padding: 30px 20px;

  & span:nth-child(n+2) {
    margin-top: 10px;
  }
`

export const flex = css`
  display: flex;
  align-items: center;
`

export const roleIcon = css`
  color: ${colors.primary};
`

export const grayText = css`
  color: ${colors.gray3};
`

export const boldText = css`
  font-weight: bold;
`

export const descriptionText = css`
  line-height: 1.5;
  text-indent: 10px;
`
