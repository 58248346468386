import { publicRoutes } from './public'
import { privateRoutes } from './private'
import { testComponentRoutes } from './test-component'

const isDevelopment = ['development', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT)

export const routes = [
  ...(isDevelopment ? [testComponentRoutes] : []),
  ...privateRoutes,
  ...publicRoutes
]
