import { css } from '@emotion/core'
import { flexColumn, colors } from '@bonitour/components'

export const container = css`
  ${flexColumn};
  justify-content: space-between;
  width: 200px;
  height: 150px;
  color: ${colors.gray3};
  border: none;
  margin: 60px 20px 0 20px;
`

export const bold = css`
  font-weight: bold;
`

export const receivedIcon = css`
  background-color: ${colors.primaryVariant};
  color: ${colors.white};
  margin-left: 20px;
`

export const amountDueContainer = css`
  padding: 20px;
`

export const amountDueLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

export const amountDueStyle = css`
  font-size: 24px;
  color: ${colors.gray1};
`
