import React from 'react'
import { FormCheckinSettings } from '../Form'
import { useQuery } from 'Shared/hooks/useQuery'

const CreateCheckinSettingsPage = () => {
  const { regionsOccuped = '' } = useQuery()

  return <FormCheckinSettings isEdit={false} regionsOccuped={regionsOccuped.split(',')} />
}

export { CreateCheckinSettingsPage }
