export const PlanParser = (company) => {
  const {
    id = '',
    plan_name: name,
    transaction_type: transactionType,
    number_of_activities: activitiesNumber,
    cost_per_activity: activityCost,
    offline_seller_tax: orbSellerTax,
    offline_vendor_tax: orbVendorTax,
    pay_seller_tax: paySellerTax,
    pay_vendor_tax: payVendorTax,
    xpert_seller_tax: xpertSellerTax,
    xpert_vendor_tax: xpertVendorTax,
    plan_expiration_date: expirationDate,
    hiring_date: hiringDate,
    fixed_price_per_month: monthlyPrice
  } = company.company_plan

  return {
    name,
    transactionType,
    activitiesNumber,
    id,
    activityCost,
    orbSellerTax,
    orbVendorTax,
    xpertSellerTax,
    xpertVendorTax,
    payVendorTax,
    paySellerTax,
    expirationDate,
    hiringDate,
    monthlyPrice
  }
}
