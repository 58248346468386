/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Header, Logo } from '@bonitour/components'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'

const headerLogo = css`
  display: flex;
  text-align: center;
  padding: 10px;
  box-shadow: none;
`

const logo = css`
  margin: auto;
`

const message = css`
  text-align: center;
  margin: 50px;
`

export const TicketCanceledConfirmation = () => {
  return (
    <>
      <Header css={headerLogo}>
        <Logo css={logo} src={BinamikLogo} />
      </Header>
      <p css={message}>Obrigado por confirmar a visualização do e-mail de cancelamento de ingresso</p>
    </>
  )
}
