import { unformatMoney } from '@bonitour/common-functions'

export const PlanModel = (plan) => {
  const {
    name,
    activitiesNumber,
    id,
    activityCost,
    orbSellerTax,
    orbVendorTax,
    xpertSellerTax,
    xpertVendorTax,
    payVendorTax,
    paySellerTax,
    expirationDate,
    hiringDate,
    monthlyPrice
  } = plan

  return {
    id,
    plan_name: name,
    number_of_activities: Number(activitiesNumber),
    cost_per_activity: unformatMoney(activityCost),
    offline_seller_tax: Number(orbSellerTax),
    offline_vendor_tax: Number(orbVendorTax),
    pay_seller_tax: Number(paySellerTax),
    pay_vendor_tax: Number(payVendorTax),
    xpert_seller_tax: Number(xpertSellerTax),
    xpert_vendor_tax: Number(xpertVendorTax),
    plan_expiration_date: expirationDate,
    hiring_date: hiringDate,
    fixed_price_per_month: unformatMoney(monthlyPrice)
  }
}
