/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { Button, FilterIcon, GhostButton, H3, Input, InputFormGroup, Manager, Popover, Reference, Row, Select } from '@bonitour/components'
import { buttonRow, filterButton, filterDrawerContainer, filtersContainer, iconStyle } from './Affiliates.style'

export const AffiliatesFilter = ({ onFilter }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [filters, setFilters] = useState({
    status: '',
    name: ''
  })

  const hasFilters = useMemo(() => Object.values(filters).some(Boolean), [filters])

  const onResetFilters = useCallback(() => {
    setFilters({
      status: '',
      name: ''
    })
    onFilter({
      status: '',
      name: ''
    })
  }, [onFilter])

  return (
    <div css={filtersContainer}>

      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              ref={ref}
              onClick={() => setIsOpen(v => !v)}
              css={iconStyle({ hasFilters, isOpen })}
            >
              <FilterIcon />
            </div>
          )}
        </Reference>

        {isOpen && (
          <>
            <Popover position='bottom-end'>
              <>
                <div css={filterDrawerContainer}>
                  <H3>Filtros</H3>
                  <Row>
                    <InputFormGroup label='Nome do afiliado'>
                      <Input
                        onChange={v =>
                          setFilters(data => ({ ...data, name: v }))}
                        placeholder='Digite o nome'
                        value={filters.name}
                      />
                    </InputFormGroup>
                  </Row>
                  <Row>
                    <InputFormGroup label='Status'>
                      <Select
                        onChange={v =>
                          setFilters(data => ({ ...data, status: v }))}
                        options={[
                          { label: 'Todos', value: '' },
                          { label: 'Ativo', value: 'active' },
                          { label: 'Pendente', value: 'pending' },
                          { label: 'Inativo', value: 'inactive' }
                        ]}
                        value={filters.status}
                      />
                    </InputFormGroup>
                  </Row>
                  <Row
                    css={buttonRow}
                    className='buttonsContainer'
                  >
                    <GhostButton
                      onClick={onResetFilters}
                    >
                      Limpar
                    </GhostButton>
                    <Button
                      type='submit'
                      onClick={() => {
                        setIsOpen(false)
                        onFilter(filters)
                      }}
                    >
                      Filtrar
                    </Button>
                  </Row>
                </div>
              </>
            </Popover>
            <button
              css={filterButton}
              onClick={() => setIsOpen(v => !v)}
            />
          </>
        )}
      </Manager>
    </div>
  )
}
