import { useTerms } from 'Domains/Terms/hooks/useTerms'
import { useCallback, useEffect, useState } from 'react'
import { COOKIE_CONSENT_CONFIG } from 'Shared/constants/cookieConsent'
import { useUser } from 'Shared/contexts/User'
import { DataMonitoringIntegrations } from 'Shared/external/Integrations'

export const useCookieConsent = () => {
  const [isCookieConsentVisible, setCookieConsentVisibility] = useState(false)
  const { term: cookiesTerm, acceptTerm } = useTerms('cookies_policy')
  const { user, updateUser } = useUser()
  const { legal_agreements: legalAgreements = {} } = user
  const { cookies_policy: isCookieConsent } = legalAgreements || {}

  isCookieConsent && DataMonitoringIntegrations()

  const [cookieConsentment, setCookieConsentment] =
    useState({
      isLoading: true,
      version: COOKIE_CONSENT_CONFIG.version,
      lastConsent: '',
      hasAccepted: false
    })

  const updateConsentment = useCallback((newConsent) => {
    setCookieConsentment(newConsent)
    localStorage.setItem(COOKIE_CONSENT_CONFIG.name, JSON.stringify(newConsent))
    setCookieConsentVisibility(false)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }, [])

  const onClickAccept = () => {
    const cookiesAccept = Promise.resolve(acceptTerm(cookiesTerm?.id))
    cookiesAccept.then(userData => {
      userData && updateUser({ ...user, legal_agreements: userData?.user?.legal_agreements })
      const newConsent = {
        ...cookieConsentment,
        lastConsent: new Date().toISOString(),
        hasAccepted: true
      }
      updateConsentment(newConsent)
    })
  }

  const onClickReject = () => {
    const newConsent = {
      ...cookieConsentment,
      lastConsent: new Date().toISOString(),
      hasAccepted: false
    }
    updateConsentment(newConsent)
  }

  useEffect(() => {
    if (!COOKIE_CONSENT_CONFIG.version || isCookieConsent) return

    const cookieConsentmentString = localStorage.getItem(
      COOKIE_CONSENT_CONFIG.name
    )

    const undefinedConsentment = {
      isLoading: false,
      lastConsent: '',
      version: COOKIE_CONSENT_CONFIG.version,
      hasAccepted: false
    }

    if (cookieConsentmentString) {
      const parsedCookieConsentment = JSON.parse(cookieConsentmentString)
      if (parsedCookieConsentment?.version !== COOKIE_CONSENT_CONFIG.version) {
        setCookieConsentment(undefinedConsentment)
        setCookieConsentVisibility(true)
      }
      setCookieConsentment({ ...parsedCookieConsentment, isLoading: false })
      setCookieConsentVisibility(false)
    } else {
      setCookieConsentment(undefinedConsentment)
      setCookieConsentVisibility(true)
    }
  }, [isCookieConsent])

  return {
    isCookieConsentVisible,
    cookieConsentment,
    onClickAccept,
    onClickReject
  }
}
