import { orbCore } from './Orb/Service'
import { TimeZoneParser } from 'Core/Parser/TimeZone'

const { timeZoneDomain } = orbCore

export const TimeZoneService = {
  list (payload) {
    return timeZoneDomain.list(payload)
      .then(TimeZoneParser.listFromApi)
  }
}
