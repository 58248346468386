import { css } from '@emotion/react'
import { colors } from '@bonitour/components'
import styled from '@emotion/styled'

export const TableCheckinStyle = css`
  width: 100%;

  a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 17.5rem; // 280px
  }
`

export const cardButton = css`
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  padding: 10px 20px;
  margin: 1.2rem 0 2.4rem 0;
`

export const labelCardButton = css`
  color: ${colors.gray2};
  text-align: center;
  font-weight: bold;
  margin-top: 7px;
`

export const deleteModalTitle = css`
  color: ${colors.gray2};
  font-weight: 900;
`

export const closeModalIcon = css`
  position: inherit;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const sizeTooltip = css`
  .tooltip-on-hover {
    width: 110px;
  }
`

export const sizeIcon = css`
  font-size: 23px;
  margin: 0 5px;
`

export const cardIcon = css`
  background-color: ${colors.primary};
  color: ${colors.white};
  cursor: pointer;
  width: 22px;
  height: 22px;
  padding: 5px;
  border-radius: var(--round-element-radius, 100%);
`
