/* eslint-disable */
export const AuthParser = {
  payload (response) {
    const { headers: { authorization }, data: { user = {} } } = response
    const { id, name, avatar, company = {}, email, legal_agreements, pending_invites, personal_info_id, affiliates_info } = user || {}
    const { id: companyId = '', name: companyName = '', image: companyImage = '', current_employee: companyCurrentEmployee = {} } = company || {}
    const { id: companyCurrentEmployeeId = '', active: companyCurrentEmployeeActive = false } = companyCurrentEmployee || {}

    const userParsed = {
      id, 
      name, 
      avatar, 
      company: {
        id: companyId,
        name: companyName,
        image: companyImage,
        current_employee: {
          id: companyCurrentEmployeeId,
          active: companyCurrentEmployeeActive
        }
      }, 
      email, 
      legal_agreements, 
      pending_invites, 
      personal_info_id,
      affiliates_info
    }

    return {
      user: userParsed,
      authorization,
      company: userParsed?.company || {}
    }
  },
  authorization (response) {
    const { headers: { authorization } } = response
    return { authorization }
  },
  switchCompanyPayload (response) {
    const { headers: { authorization }, data: { company = {} } } = response || {}
    const { id, name, image, companyCurrentEmployee } = company || {}
    const { id: companyCurrentEmployeeId = '', active: companyCurrentEmployeeActive = false } = companyCurrentEmployee || {}

    const companyParsed = {
      id,
      name,
      image
    }

    const userCompanyParsed = {
      ...companyParsed,
      current_employee: {
        id: companyCurrentEmployeeId,
        active: companyCurrentEmployeeActive
      }
    }

    return {
      company: companyParsed,
      userCompany: userCompanyParsed,
      authorization
    }
  }
}
