import React, { useCallback, useEffect, useMemo } from 'react'
import { useCheckinSettings } from '../hooks/useCheckinSettings'
import {
  Button,
  HeaderPage,
  Input,
  InputFormGroup,
  Select,
  ToggleInputGroup,
  Card,
  Row,
  flexCenteredSpaceBetweenRow,
  Label,
  useToast
} from '@bonitour/components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { CheckinSettingSchema } from '../Checkin.schema'
import { useForm } from '@bonitour/app-functions'
import { customLabel, headerPageStyle, inputGroupMargin } from './style'
import { marginTop20 } from 'Domains/Integrations/Card/IntegrationCard.style'
import { backButton, minWidthButton } from '../styles'

const FormCheckinSettings = ({ isEdit, settingId, regionsOccuped = [] }) => {
  const { regions, fetchRegions, createSetting, fetchSetting, isLoading, setting, updateSetting } = useCheckinSettings()

  const CheckinSettingBaseSchema = useMemo(
    () => ({
      region: isEdit && !isLoading ? setting?.region : '',
      termsLink: isEdit && !isLoading ? setting?.termsLink : '',
      isEnabled: isEdit && !isLoading ? setting?.isEnabled : true
    }),
    [isEdit, isLoading, setting.isEnabled, setting.region, setting.termsLink]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(CheckinSettingBaseSchema, CheckinSettingSchema)

  const { push } = useHistory()

  const { add: addToast } = useToast()

  const backToDashboard = useCallback(() => push('/app/company/checkin-settings'), [push])

  useEffect(() => {
    fetchRegions()
    if (isEdit && settingId) {
      fetchSetting(settingId, backToDashboard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (setting) => {
    if (isEdit) {
      updateSetting(settingId, setting, regionsOccuped, () => backToDashboard())
    } else {
      createSetting(setting, regionsOccuped, () => {
        addToast('Configuração criada.', 'success')
        backToDashboard()
      })
    }
  }
  const unusedRegions = useMemo(() => regions.filter(region => !regionsOccuped.includes(region.value)), [regions, regionsOccuped])

  return (
    <>
      <HeaderPage
        onBack={backToDashboard}
        title={isEdit ? 'Editar configuração' : 'Criar nova configuração'}
        customCss={[headerPageStyle]}
      >
        <div className="toggle-input-container">
          <ToggleInputGroup
            checked={form.isEnabled}
            onChange={() => onInputChange('isEnabled')(!form.isEnabled)}
            disabled={!isEdit}
          >
            <Label css={customLabel}>Habilitar termo</Label>
          </ToggleInputGroup>
        </div>
      </HeaderPage>
      <Card>
        <InputFormGroup errorMessage={errors.region} label="Região" customCss={[inputGroupMargin]}>
          <Select options={unusedRegions} onChange={onInputChange('region')} value={form.region} disabled={isLoading} />
        </InputFormGroup>
        <InputFormGroup errorMessage={errors.termsLink} label="Link dos termos" customCss={[inputGroupMargin]}>
          <Input
            onChange={onInputChange('termsLink')}
            onBlur={onInputBlur('termsLink')}
            value={form.termsLink}
            type="text"
            disabled={isLoading}
          />
        </InputFormGroup>
      </Card>
      <Row css={[flexCenteredSpaceBetweenRow, marginTop20]}>
        <Button css={[backButton, minWidthButton]} onClick={backToDashboard} disabled={isLoading}>
          Voltar
        </Button>
        <Button type="submit" onClick={onSubmit(handleSubmit)} disabled={isLoading} css={[minWidthButton]}>
          {isEdit ? 'Salvar e sair' : 'Criar'}
        </Button>
      </Row>
    </>
  )
}

export { FormCheckinSettings }
