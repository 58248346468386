import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const container = css`
  display: flex;
  flex-wrap: wrap;
  color: ${colors.gray4};
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    justify-content: center;
  }
`

export const notFoundContainer = css`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
  .not-found-label:first-child {
    margin-top: 1.25rem 0px;
  }
  .not-found-label:last-child {
    margin: 0;
    font-weight: lighter;
    color: ${colors.gray5};
    font-size: 1rem;
  }
`
