/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Manager, Reference, Popover } from '@bonitour/components'
import { useClickOutside } from '@bonitour/app-functions'
import { useRef } from 'react'
import { hidden } from 'assets/styles/global'
import { InviteForm } from './InviteForm/InviteForm'
import { useUserInvite } from './hooks/useUserInvite'

const selectorContainer = css`
  width: 150px;
`

const popoverStyle = css`
  margin: 0 20px;
  padding-left: 20px;
  left: 10px !important;

  div:last-child {
    top: 25px;
  }
`

export const InviteUser = ({ roles, children, customStyles }) => {
  const reference = useRef()
  const onInviteOpen = () => setOptionsOpen(!isOptionsOpen)
  const onInviteClose = () => setOptionsOpen(false)
  const [isOptionsOpen, setOptionsOpen] = useClickOutside(reference)
  const { inviteUser, isLoading } = useUserInvite()

  return (
    <div css={selectorContainer} ref={reference}>
      <Manager>
        <>
          <Reference>
            {({ ref }) => (
              <div ref={ref} onClick={onInviteOpen}>{children}</div>
            )}
          </Reference>
          <Popover position='right-start' css={[customStyles, popoverStyle, !isOptionsOpen && hidden]}>
            <InviteForm
              roles={roles}
              isLoading={isLoading}
              onSuccess={inviteUser}
              onClose={onInviteClose}
            />
          </Popover>
        </>
      </Manager>
    </div>
  )
}
