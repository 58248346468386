/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { ColumnsToExport, FileExportIcon, Button, useToast, Dialog } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { clickable } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { string, array } from 'yup'
import {
  baseExportToggle,
  dialogContainer,
  exportContainer,
  exportContainerOpen,
  exportIconContainer,
  exportIconStyle,
  exportOpenIconStyle,
  exportToggleButtonClosed,
  exportToggleButtonOpen
} from './ExportFile.styles'

const retrieveExportSchema = (isEnabledWantedFields) => ({
  wantedFields: array().min(isEnabledWantedFields ? 1 : 0, 'É necessário escolher pelo menos uma coluna.'),
  format: string().required('É necessário escolher o formato para exportação.')
})

export const ExportFile = ({
  onSubmitExport = identity,
  isLoading,
  initialFilters,
  filters = {},
  multiSelectOptions = [],
  formatOptions = [],
  isEnabledColumnSelect = true
}) => {
  const { add: addToast } = useToast()
  const exportSchema = retrieveExportSchema(isEnabledColumnSelect)
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange }
  } = useForm(initialFilters, exportSchema)
  const { wantedFields = [], format = '' } = form
  const [isVisible, setIsVisible] = useState(false)
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false)
  const toggleMultiSelect = useCallback(() => isEnabledColumnSelect && setIsMultiSelectOpen((curr) => !curr), [isEnabledColumnSelect])
  const toggleVisible = useCallback(() => setIsVisible((curr) => !curr), [])

  useEffect(() => {
    onInputChange('wantedFields')(multiSelectOptions.map((option) => option.label))
    // eslint-disable-next-line
  }, [multiSelectOptions])

  useEffect(() => {
    onInputChange('format')(formatOptions?.[0]?.value || '')
    // eslint-disable-next-line
  }, [formatOptions])

  useEffect(() => {
    onInputChange('wantedFields')(wantedFields && [...wantedFields])
    onAllScheduleChange()
    // eslint-disable-next-line
  }, [])

  const isAllSelected = useMemo(
    () => Boolean(multiSelectOptions.length === wantedFields.length),
    [multiSelectOptions.length, wantedFields.length]
  )

  const isChecked = useCallback((selectedOption) => wantedFields.includes(selectedOption), [wantedFields])

  const onOptionChange = (selectedOption) => () => {
    if (isChecked(selectedOption)) {
      onInputChange('wantedFields')(wantedFields && wantedFields.filter((item) => item !== selectedOption))
    } else {
      onInputChange('wantedFields')([...wantedFields, selectedOption])
    }
  }

  const onAllScheduleChange = () => {
    isAllSelected
      ? onInputChange('wantedFields')([])
      : onInputChange('wantedFields')(multiSelectOptions.map((option) => option.label))
  }

  const validateFilters = ({ wantedFields }) => {
    const exportColumns = (wantedFields || []).map((fieldType) =>
      multiSelectOptions.reduce((acc, { value, label }) => {
        if (fieldType === label) return acc.concat(value)
        return acc
      }, '')
    )

    onSubmitExport({ wantedFields: exportColumns, format, filters })
    toggleVisible()
  }

  const validateAllFilters = () => {
    const allColumns = multiSelectOptions.map((option) => option.value)
    onSubmitExport({ wantedFields: allColumns, format, filters })
    toggleVisible()
  }

  const onError = useCallback(() => addToast('Preencha o formulário corretamente'), [addToast])
  const onClick = onSubmit(validateFilters, onError)
  const onExportAll = onSubmit(validateAllFilters)

  return (
    <>
      <div css={exportIconContainer}>
        <div onClick={toggleVisible} css={[clickable, isVisible ? exportContainerOpen : exportContainer]}>
          <Button
            className="exportButton"
            css={[isVisible ? exportToggleButtonOpen : exportToggleButtonClosed, baseExportToggle]}
          >
            <FileExportIcon css={[isVisible ? exportOpenIconStyle : exportIconStyle]} />
            Exportar
          </Button>
        </div>
      </div>

      <Dialog
        title="Configuração da exportação da tabela"
        isVisible={isVisible}
        onClose={toggleVisible}
        customContainercss={dialogContainer}
      >
        <ColumnsToExport
          toggleMultiSelect={toggleMultiSelect}
          wantedFields={wantedFields}
          format={format}
          errors={errors}
          isMultiSelectOpen={isMultiSelectOpen}
          multiSelectOptions={multiSelectOptions}
          formatOptions={formatOptions}
          isChecked={isChecked}
          onOptionChange={onOptionChange}
          onInputChange={onInputChange}
          allScheduleChange={onAllScheduleChange}
          areAllSelected={isAllSelected}
          loading={isLoading}
          onClick={onClick}
          onExportAll={onExportAll}
        />
      </Dialog>
    </>
  )
}
