import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const comissionLabel = css`
  cursor: pointer;

  & > div {
    display: inline-block;
    .tooltip-on-hover {
      display: none !important;
    }
  }
`

export const comissionDetailItem = css`
  color: ${colors.gray2};
  margin-bottom: 15px;
  h4 {
    margin-bottom: 0;
  }
  h5 {
    font-size: 12px;
    color: ${colors.gray2};
    weight: 500;
  }
  &:last-of-type {
    margin-bottom: 3px;
  }
`

export const dialogCss = css`
  ul {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
  }
`
