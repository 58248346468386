/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { ConfirmDialog, Input, useToast, InputFormGroup, flexRowCentered, HelpIcon, colors } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { marginBottom, marginTop, width } from 'assets/styles/global'
import { useForm } from '@bonitour/app-functions'
import { useCallback, useMemo } from 'react'
import { IntegrationSelect } from './IntegrationSelect'
import { validationSchema } from './ConfirmIntegration.schema'

const marginTop10 = marginTop(10)
const width100 = width(100)
const marginBottom15 = marginBottom(15)

const inputsContainer = css`
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
`

const inputLogin = css`
  margin-right: 15px;
`

const helpCard = css`
  font-size: 13px;
  border: 1px solid ${colors.gray7};
  background-color: ${colors.gray14};
  padding: 8px;
  border-radius: 10px;
`

const integrationBase = {}

export const TwilioHelp = ({ customCss = [] }) => (
  <p css={[helpCard, ...customCss]}>
    <HelpIcon />{' '}
    Para verificar o SID e Token de sua conta na Twilio,{' '}
    <a href='https://www.twilio.com/login?g=%2Fconsole%3F&t=2b1c98334b25c1a785ef15b6556396290e3c704a9b57fc40687cbccd79c46a8c' rel='noreferrer noopener' target='blank'>
      clique aqui
    </a>.
  </p>
)

export const IntegrationDialog = ({
  confirmationVisible = identity,
  toggleConfirmIntegrationVisible = identity,
  submit: emitSubmit = identity
}) => {
  const { add } = useToast()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(integrationBase, validationSchema)

  const {
    login = '',
    password = '',
    companyName = '',
    cnpj = '',
    token = '',
    phone = '',
    integration = 'Voucher Unico'
  } = form

  const onValidationSuccess = useCallback((integration) => {
    emitSubmit(integration)
      .then(() => {
        toggleConfirmIntegrationVisible(false)
      })
      .catch(() => add('Erro ao fazer integração'))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationError = useCallback(() => {
    add('Preencha corretamente o formulário')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoginSubmit = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [
    onSubmit,
    onValidationError,
    onValidationSuccess
  ])

  const isVoucherUnico = integration === 'Voucher Unico'
  const isTwilio = integration === 'Twilio SMS'

  return (
    <>
      <ConfirmDialog
        title='Integrar com sistema externo'
        isVisible={confirmationVisible}
        successCallback={handleLoginSubmit}
        cancelCallback={toggleConfirmIntegrationVisible}
        buttonLabelConfirm='Ativar'
        buttonLabelCancel='Cancelar'
      >
        <IntegrationSelect errors={errors.integration} value={integration} onChange={onInputChange} onBlur={onInputBlur} />
        {(isVoucherUnico || isTwilio)
          ? (
            <div css={inputsContainer}>
              <>
                {isTwilio && (
                  <InputFormGroup errorMessage={errors.phone} label='Número de telefone' css={marginTop10}>
                    <Input css={width100} value={phone} onChange={onInputChange('phone')} onBlur={onInputBlur('phone')} />
                  </InputFormGroup>
                )}
                <InputFormGroup errorMessage={errors.companyName} label='Empresa'>
                  <Input css={width100} value={companyName} onChange={onInputChange('companyName')} onBlur={onInputBlur('companyName')} />
                </InputFormGroup>
                <div css={flexRowCentered}>
                  <InputFormGroup errorMessage={errors.login} label={isTwilio ? 'Conta (SID)' : 'Login'} css={[marginBottom15, inputLogin]}>
                    <Input css={width100} value={login} onChange={onInputChange('login')} onBlur={onInputBlur('login')} />
                  </InputFormGroup>
                  <InputFormGroup css={marginBottom15} errorMessage={errors.password} label={isTwilio ? 'Token' : 'Senha'}>
                    <Input type='password' css={width100} value={password} onChange={onInputChange('password')} onBlur={onInputBlur('password')} />
                  </InputFormGroup>
                </div>
                {isTwilio && (
                  <TwilioHelp customCss={[marginBottom15]} />
                )}
              </>
            </div>
          )
          : (
            <>
              <InputFormGroup errorMessage={errors.cnpj} label='CNPJ' css={marginTop10}>
                <Input css={width100} value={cnpj} onChange={onInputChange('cnpj')} onBlur={onInputBlur('cnpj')} />
              </InputFormGroup>
              <InputFormGroup customCss={[marginBottom15]} errorMessage={errors.token} label='Token'>
                <Input css={width100} value={token} onChange={onInputChange('token')} onBlur={onInputBlur('token')} />
              </InputFormGroup>
            </>
          )}
      </ConfirmDialog>
    </>
  )
}
