import { css } from '@emotion/core'

export const inputGroupMargin = css`
  margin: 1rem 0 1rem 0;
`

export const customLabel = css`
  font-weight: 700;
`

export const headerPageStyle = css`
  h1 {
    width: auto;
  }

  .toggle-input-container {
    flex: 4;
    display: flex;
    justify-content: end;
  }

  @media screen and (max-width: 530px) {
    h1 {
      width: 80%;
    }
    .toggle-input-container {
      margin-top: 1rem;
      justify-content: start;
    }
  }
`
