import { BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'

export const formContainer = css`
  min-width: 600px;

  .inputs__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .submit__button {
    margin-top: 50px;
  }

  p.description___label {
    font-size: 21px;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    padding-top: 20px;
    min-width: unset;
  }

  p {
    line-height: 1.1;
  }
`

export const checkBoxLabelStyle = css`
  margin: auto 0;
  padding-left: 5px;
`

export const unsetColor = css`
  color: unset;
`

export const termsConfirmationContainer = css`
  display: flex;
  flex-direction: row;
`
