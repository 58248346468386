/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Content, Footer } from '@bonitour/components'
import {
  container,
  formSubmitGroup,
  overrideBaseLayout
} from './PublicLayout.style'

export const PublicLayout = props => {
  return (
    <>
      <Content css={overrideBaseLayout}>
        <div css={container}>
          <div css={formSubmitGroup} {...props} />
        </div>
      </Content>
      <Footer />
    </>
  )
}
