import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'
import { UserStore } from 'Core/Store/User'

export const UserContext = createContext({})

export const useUser = () => useContext(UserContext)

export const UserProvider = (props) => {
  const [user, setUser] = useState(() => UserStore.user)
  const [profileType, setProfileType] = useState(() => UserStore.profileType)
  const [authKey, setAuthKey] = useState(() => UserStore.authKey)
  const hasAffiliateProfile = useMemo(() => Boolean(user?.affiliates_info?.length), [user])

  const updateUser = useCallback((user) => {
    UserStore.user = user
    setUser(user)
  }, [])

  const updateAuthKey = useCallback((newAuthKey) => {
    UserStore.authKey = newAuthKey
    setAuthKey(newAuthKey)
  }, [])

  const updateProfileType = useCallback((profileType) => {
    UserStore.profileType = profileType
    setProfileType(profileType)
  }, [])

  const providerData = useMemo(() => ({
    user,
    updateUser,
    authKey,
    updateAuthKey,
    profileType,
    updateProfileType,
    hasAffiliateProfile
  }), [user, updateUser, authKey, updateAuthKey, profileType, updateProfileType, hasAffiliateProfile])

  return (
    <UserContext.Provider value={providerData} {...props} />
  )
}
