import { addHotjar } from './hotjar'
import { GoogleTagManagerHeader, GoogleTagManagerBody } from './googleTagManager'
import { Beamer } from './beamer'
import { Freshworks } from './freashworks'

const { REACT_APP_GTM_ID, REACT_APP_BEAMER_ID, REACT_APP_FRESHWORKS_ID } = process.env

export const DataMonitoringIntegrations = () => {
  GoogleTagManagerHeader(REACT_APP_GTM_ID)
  GoogleTagManagerBody(REACT_APP_GTM_ID)
  addHotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

export const ExternalIntegrations = () => {
  Beamer(REACT_APP_BEAMER_ID)
  Freshworks(REACT_APP_FRESHWORKS_ID)
}
