export const EmployeeModel = {
  invitation (payload = {}) {
    const { email = '', companyRolesIds = [] } = payload

    return {
      user_email: email,
      company_roles: companyRolesIds,
      binamik_roles: []
    }
  },
  signup (invite = {}, payload = {}) {
    const { name, password } = payload
    const { token, email } = invite
    const legalAgreementTypes = ['company_terms_of_use', 'privacy_policy']

    return {
      token,
      user: {
        name,
        password,
        email,
        legal_agreement_types: legalAgreementTypes
      }
    }
  }
}
