/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useHistory, useParams } from 'react-router-dom'
import { Accordion, CollapsableSection, HeaderPage } from '@bonitour/components'
import { useCompanyRole } from './hooks/useCompanyRole'
import { useQuery } from 'Shared/hooks/useQuery'
import { EditCompanyRole } from './EditCompanyRole/EditCompanyRole'
import { CompanyRoleUsers } from './CompanyRoleUsers/CompanyRoleUsers'

const defaultCursor = css`
  cursor: default;
`

export const CompanyRole = () => {
  const { push } = useHistory()
  const { roleId } = useParams()
  const backToDashboard = () => push('/app/users?selectedNav=roles')
  const { companyRole, macroPermissions, loading, submit, onRemoveUsers } = useCompanyRole(roleId)
  const { accordion = 1 } = useQuery()
  const setIndicatorActive = accordionValue => push({ search: `?accordion=${accordionValue}` })
  const { name: roleName, users = [] } = companyRole
  const title = roleName || 'Novo papel de permissão'

  return (
    <>
      <HeaderPage onBack={backToDashboard} title={roleName ? title : ''} />

      <Accordion value={Number(accordion)} onClick={setIndicatorActive}>
        <CollapsableSection indicator={1} css={defaultCursor} title='Configuração de papel' topics='Nome do papel / Permissões'>
          <EditCompanyRole macroPermissions={macroPermissions} companyRole={companyRole} loading={loading} submit={submit} />
        </CollapsableSection>

        <CollapsableSection indicator={2} css={defaultCursor} title='Usuários do papel' topics='Usuários'>
          <CompanyRoleUsers users={users} onRemoveUsers={onRemoveUsers} loading={loading} />
        </CollapsableSection>
      </Accordion>
    </>
  )
}
