/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, H3, HeaderPage, PaginationContainer } from '@bonitour/components'
import BinamikInvoiceTable from './BinamikInvoiceTable'
import { useBinamikInvoiceList } from '../hooks/useBinamikInvoiceList'
import { useHistory } from 'react-router'

export const BinamikInvoiceList = () => {
  const { invoices, meta, handlePagination } = useBinamikInvoiceList()
  const { total = 0, totalPages = 1 } = meta ?? {}
  const { push } = useHistory()

  const backToHome = () => push('/app')

  return (
    <>
      <HeaderPage onBack={backToHome} title='Faturas Binamik' />

      <Card>
        <PaginationContainer
          total={total}
          pagesQuantity={totalPages}
          onPagination={handlePagination}
        >
          <H3>Histórico de faturas</H3>
          <BinamikInvoiceTable invoices={invoices} />
        </PaginationContainer>
      </Card>
    </>
  )
}
