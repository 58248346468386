import { head } from '@bonitour/common-functions'
import { MetaParser } from './Meta'

export const IntegrationParser = {
  service: (service) => {
    const {
      id,
      integration_id: integrationId,
      external_service_id: externalId,
      service_id: activityId,
      service_title: activityName,
      active = true
    } = service ?? {}

    return {
      id,
      integrationId,
      externalId,
      activityId,
      activityName,
      active
    }
  },
  single: (integration) => {
    const {
      id,
      company_id: companyId,
      external_id: externalId,
      integration_name: integrationName,
      integration_services: services,
      integration_authentications: authentication
    } = integration?.integration ?? integration ?? {}

    const {
      login,
      password,
      token,
      company_name: companyName
    } = head(authentication) ?? {}

    const isTwilio = integrationName === 'Twilio SMS'

    return {
      id,
      companyId,
      externalId,
      integrationName,
      auth: {
        login: login || '',
        password: password || '',
        token: token || '',
        cnpj: isTwilio ? undefined : (externalId || ''),
        phone: isTwilio ? (externalId || '') : undefined,
        companyName: companyName || ''
      },
      activities: services ? services.map(IntegrationParser.service) : {}
    }
  },
  list: ({ meta, integrations = [] }) => {
    return {
      integrations: integrations.map(IntegrationParser.single),
      meta: MetaParser.meta(meta)
    }
  }

}
