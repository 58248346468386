import React, { Fragment } from 'react'
import { ToastProvider } from '@bonitour/components'
import { css, Global } from '@emotion/core'
import 'reset-css'
import '@bonitour/font/style.css'

// avoding bug https://github.com/yannickcr/eslint-plugin-react/issues/2156
global.React = { Fragment }

const bodyStyle = css`
  @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap");
  body {
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  
    /* Rounded Square appearence: */
    --round-element-radius: 10px;
    --small-round-element-radius: 6px;
  }
`

const toastPosition = css`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
`

export const Base = ({ children }) => (
  <ToastProvider cssStyle={toastPosition}>
    <Global styles={bodyStyle} />
    {children}
  </ToastProvider>
)
