/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { InputFormGroup, Select } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { marginTop } from 'assets/styles/global'
import { integrationsOptions } from 'Shared/constants/integrations'

const marginTop10 = marginTop(10)

export const IntegrationSelect = ({ errors, value, onChange: emitChangeEvent, onBlur }) => {
  return (
    <InputFormGroup errorMessage={errors} label='Sistema externo' css={marginTop10}>
      <Select
        options={integrationsOptions}
        value={value}
        onChange={emitChangeEvent('integration')}
        onBlur={onBlur('integration')}
      />
    </InputFormGroup>
  )
}
