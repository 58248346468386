import { TermParser } from 'Core/Parser/Term'
import { orbCore } from 'Core/Service/Orb/Service'

const { termDomain } = orbCore

export const Termservice = {
  getByType (type) {
    return termDomain.getByType(type).then(TermParser.get)
  },
  acceptTerm (termId) {
    return termDomain.acceptTerm(termId)
  }
}
