/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'
import { IntegrationDialog } from './IntegrationDialog'

export const ConfirmIntegration = ({
  confirmationVisible,
  toggleConfirmIntegrationVisible = identity,
  submit = identity
}) => {
  return (
    <IntegrationDialog
      confirmationVisible={confirmationVisible}
      toggleConfirmIntegrationVisible={toggleConfirmIntegrationVisible}
      submit={submit}
    />
  )
}
