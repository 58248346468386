import { SafeDate } from '@binamik/js-providers'

/* eslint-disable camelcase */
export const AffiliateParser = {
  instance (affiliate = {}) {
    const {
      accept_date,
      affiliate_code,
      affiliate_link,
      company_id,
      email,
      id,
      name,
      percentage,
      status,
      total_available_value,
      total_commission_value,
      total_sold_value,
      user_id
    } = affiliate

    const safeAcceptDate = accept_date ? new SafeDate(accept_date).format('DD/MM/YYYY') : ''

    return {
      acceptDate: safeAcceptDate,
      affiliateCode: affiliate_code,
      affiliateLink: affiliate_link,
      companyId: company_id,
      email,
      id,
      name,
      percentage,
      status,
      totalAvailableValue: total_available_value,
      totalCommissionValue: total_commission_value,
      totalSoldValue: total_sold_value,
      userId: user_id,
      percentageByService: affiliate.percentage_by_service
    }
  },

  meta (meta = {}, perPage = 10) {
    const {
      current_page: page,
      total_entries: totalEntries,
      total_pages: totalPages
    } = meta

    return {
      page,
      totalPages,
      totalEntries,
      perPage: perPage || Math.floor(totalEntries / totalPages)
    }
  },

  saleInstance (sale = {}) {
    const {
      id,
      price,
      ticket_code: ticketCode,
      sale_date: saleDate,
      service_title: serviceTitle,
      affiliate_info: {
        percentage,
        affiliate_code: affiliateCode,
        affiliate_commission: affiliateCommission
      },
      reservation_code: reservationCode
    } = sale

    return {
      id,
      price,
      ticketCode,
      saleDate,
      serviceTitle,
      affiliateInfo: {
        percentage,
        affiliateCode,
        affiliateCommission
      },
      reservationCode
    }
  },

  ticketInstance (sale = {}) {
    // TODO parse sale once API fields are known
    return sale
  },

  companyInstance (company = {}) {
    const {
      id,
      name,
      company_image: companyImage,
      total_sold_value: totalSoldValue,
      total_commission_value: totalCommissionValue,
      affiliate_link: affiliateLink
    } = company

    return {
      id,
      name,
      companyImage,
      totalSoldValue,
      totalCommissionValue,
      affiliateLink
    }
  },

  totalizersInstance (totalizers = {}) {
    const {
      percentage,
      sales_number: salesNumber,
      to_release_value: totalToRelease,
      total_available_value: totalAvailable,
      total_commission_value: totalCommission,
      total_sold_value: totalSold
    } = totalizers

    return {
      percentage,
      salesNumber,
      totalToRelease,
      totalAvailable,
      totalCommission,
      totalSold
    }
  },

  list (affiliates = [], meta = {}, perPage = 10) {
    return {
      affiliates: affiliates.map(affiliate => this.instance(affiliate)),
      meta: this.meta(meta, perPage)
    }
  },

  get (affiliate = {}) {
    const {
      accept_date: acceptDate,
      affiliate_code: affiliateCode,
      affiliate_link: affiliateLink,
      company_id: companyId,
      email,
      id,
      name,
      percentage,
      status,
      total_available_value: totalAvailableValue,
      total_commission_value: totalCommissionValue,
      total_sold_value: totalSoldValue,
      total_tickets_sold_amount: totalTicketsSoldAmount,
      total_tickets_sold_value: totalTicketsSoldValue,
      percentage_by_service: percentageByService,
      services_info: servicesInfo,
      user_id: userId,
      to_release_value: toReleaseValue
    } = affiliate

    return {
      acceptDate,
      affiliateCode,
      affiliateLink,
      companyId,
      email,
      id,
      name,
      percentage,
      status,
      totalAvailableValue,
      totalCommissionValue,
      totalSoldValue,
      totalTicketsSoldAmount,
      totalTicketsSoldValue,
      userId,
      percentageByService,
      servicesInfo,
      toReleaseValue: Number(toReleaseValue || 0)
    }
  },

  sales (affiliate_sales = [], meta = {}, perPage = 10) {
    return {
      affiliateSales: affiliate_sales.map(sale => this.saleInstance(sale)),
      meta: this.meta(meta, perPage)
    }
  },

  companies (affiliate_companies = [], meta = {}, perPage = 10) {
    return {
      affiliateCompanies: affiliate_companies.map(company => this.companyInstance(company)),
      meta: this.meta(meta, perPage)
    }
  },

  tickets (affiliate_tickets = [], meta = {}, perPage = 10) {
    return {
      affiliateTickets: affiliate_tickets.map(ticket => this.ticketInstance(ticket)),
      meta: this.meta(meta, perPage)
    }
  },

  batchTotals ({ total_by_status: { paid, to_be_paid, partially_paid, open } }) {
    return {
      paid,
      toBePaid: to_be_paid,
      partiallyPaid: partially_paid,
      open
    }
  }
}
