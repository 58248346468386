/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { formatMoney, formatDate } from '@bonitour/common-functions'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TruncateCell
} from '@bonitour/components'
import { jsx } from '@emotion/core'
import { salesListStyles } from './SalesListingTable.style'

const { REACT_APP_ORB_URL: ORB_URL } = process.env

export const SalesListingTable = ({
  sales = []
}) => {
  return (
    <div css={salesListStyles}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Data da venda</TableHeaderCell>
            {/* <TableHeaderCell>Região turística</TableHeaderCell> */}
            <TableHeaderCell>Código da reserva</TableHeaderCell>
            <TableHeaderCell>Código do ticket</TableHeaderCell>
            <TableHeaderCell>Nome da experiência</TableHeaderCell>
            <TableHeaderCell>
              <div className='sale__value_box'>Valor do ticket</div>
            </TableHeaderCell>
            <TableHeaderCell>
              <div className='sale__value_box'>Valor da comissão</div>
            </TableHeaderCell>
            {/* <TableHeaderCell>Disponivel para retirar</TableHeaderCell> */}
            {/* <TableHeaderCell>Pago</TableHeaderCell> */}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {sales?.map((sale) => (
            <TableBodyRow key={sale.id}>
              <TableBodyCell>
                {sale.saleDate ? formatDate(sale.saleDate) : '---'}
              </TableBodyCell>
              {/* <TableBodyCell>
                TODO: Região turística não recebida pela API
              </TableBodyCell> */}
              <TableBodyCell>
                <a href={`${ORB_URL}/reservations?code=${sale.reservationCode}`} target='_blank' rel='noopener noreferrer'>
                  {sale.reservationCode}
                </a>
              </TableBodyCell>
              <TableBodyCell>
                {sale.ticketCode}
              </TableBodyCell>
              <TruncateCell text={sale.serviceTitle || '--'} size='200px'/>
              <TableBodyCell>
                {formatMoney(sale.price)}
              </TableBodyCell>
              <TableBodyCell>
                {formatMoney(sale.affiliateInfo.affiliateCommission)}
              </TableBodyCell>
              {/* <TableBodyCell>
                TODO: status da retirada não recebido pela API
              </TableBodyCell> */}
              {/* <TableBodyCell>
                TODO: status do pagamento não recebido pela API
              </TableBodyCell> */}
            </TableBodyRow>
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}
