import { AlertService } from 'Core/Service/Alert'
import { usePermissions } from 'Shared/hooks/usePermissions'
import { useToast } from '@bonitour/components'
import { alertTypeMap } from '../constants/alertTypeMap'
import { useCallback, useEffect, useState } from 'react'
import { useCompany } from 'Shared/contexts/Company'

export const useAlert = () => {
  const { add: addToast } = useToast()
  const { id: companyId } = useCompany()
  const { handlePermission } = usePermissions()
  const [alertsConfig, setAlertsConfig] = useState([])

  const fetchAlerts = useCallback(({ companyId }) => {
    return AlertService.list({ companyId })
      .then(({ alerts }) => setAlertsConfig(alerts))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a configuração de alerta')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAlerts({ companyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  const createAlert = async (alert) => {
    const { label: alertLabel = '' } = alertTypeMap[alert?.alertType] || {}

    return AlertService.createAlert(alert)
      .then(() => addToast(`Alerta de ${alertLabel.toLowerCase()} salvo com sucesso`, 'success'))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão para configurar alertas')
        hasPermission && addToast(`Ocorreu um erro ao salvar a configuração de alerta de ${alertLabel.toLowerCase()}`)
      })
  }

  const updateAlert = async (alert) => {
    const { id, alertType } = alert
    const { label: alertLabel = '' } = alertTypeMap[alertType] || {}

    return AlertService.updateAlert(alert, id)
      .then(() => addToast(`Alerta de ${alertLabel.toLowerCase()} atualizado com sucesso`, 'success'))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão para configurar alertas')
        hasPermission && addToast(`Ocorreu um erro ao atualizar a configuração de alerta de ${alertLabel.toLowerCase()}`)
      })
  }

  return {
    alerts: alertsConfig,
    createAlert,
    updateAlert
  }
}
