import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { AffiliateService } from 'Core/Service/Affiliate/Service'
import { useParams } from 'react-router'
import { AffiliateTicketStatus } from '../constants/AffiliateTicketStatus'
import { useCompany } from 'Shared/contexts/Company'

const DEFAULT_META = {
  page: 1,
  totalPages: 1,
  perPage: 10,
  totalEntries: 0
}

export const useAffiliateSales = () => {
  const [meta, setMeta] = useState(DEFAULT_META)
  const [isLoading, setIsLoading] = useState(false)
  const [sales, setSales] = useState([])
  const [affiliate, setAffiliate] = useState(null)
  const [batchTotals, setBatchTotals] = useState(null)
  const [filters, setFilters] = useState({
    ticketStatus: AffiliateTicketStatus[0].value
  })

  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { affiliateId } = useParams()

  const backToAffiliates = useCallback(() => {
    push('/app/affiliates')
  }, [push])

  const { id: companyId } = useCompany()

  const getAffiliate = useCallback(() => {
    setIsLoading(true)
    AffiliateService.get(affiliateId, companyId)
      .then(setAffiliate)
      .catch(() => {
        addToast('Erro ao buscar afiliado.', 'error')
        backToAffiliates()
      })
      .finally(() => setIsLoading(false))
  }, [addToast, affiliateId, backToAffiliates, companyId])

  const getBatchTotals = useCallback((affiliateId) => {
    setIsLoading(true)
    AffiliateService.getBatchTotalsByAffiliate(affiliateId)
      .then(setBatchTotals)
      .catch(() => addToast('Erro ao buscar os totalizadores de lote'))
      .finally(() => setIsLoading(false))
  }, [addToast])

  const getSales = useCallback(
    ({ page, perPage, filters = {} }) => {
      setIsLoading(true)
      AffiliateService.sales(affiliateId, {
        page,
        perPage,
        ...filters
      })
        .then((resData) => {
          setSales(resData.affiliateSales)
          setMeta(resData.meta)
        })
        .catch(() => addToast('Houve um erro ao buscar as vendas', 'error'))
        .finally(() => setIsLoading(false))
    },
    [addToast, affiliateId]
  )

  const handlePagination = useCallback(
    (page, perPage) => {
      if (!isLoading) {
        setMeta((previous) => ({
          ...previous,
          page,
          perPage
        }))
        getSales({ page, perPage, filters })
      }
    },
    [isLoading, getSales, filters]
  )

  const onPageChange = useCallback((page) => handlePagination(page, meta.perPage), [handlePagination, meta.perPage])

  const onPerPageChange = useCallback((perPage) => handlePagination(DEFAULT_META.page, perPage), [handlePagination])

  const onFilterSubmit = useCallback((newFilters = {}) => {
    setFilters(newFilters)
    setMeta(DEFAULT_META)
    getSales({ page: DEFAULT_META.page, perPage: DEFAULT_META.perPage, filters: newFilters })
  }, [getSales])

  useEffect(() => {
    getAffiliate()
    getSales({ page: meta.page, perPage: meta.perPage })
    getBatchTotals(affiliateId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    backToAffiliates,
    onPageChange,
    onPerPageChange,
    sales,
    meta,
    affiliate,
    isLoadingAffiliateSales: isLoading,
    batchTotals,
    filters,
    setFilters,
    getSales,
    onFilterSubmit
  }
}
