import { orbCore } from '../Orb/Service'
import { UserInviteParser } from './Parser'

const { userInviteDomain } = orbCore

export const UserInviteService = {
  get (token) {
    return userInviteDomain.get(token).then(({ user_invite: userInvite = {} }) => UserInviteParser.instance(userInvite))
  }
}
