import { useCallback, useMemo, useState } from 'react'
import { CompanyService } from 'Core/Service/Company'
import { useToast } from '@bonitour/components'

export const useCheckinSettings = () => {
  const [settings, setSettings] = useState([])
  const [setting, setSetting] = useState({
    region: '',
    termsLink: '',
    isEnabled: false
  })
  const [regions, setRegions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const regionsList = useMemo(() => regions.map((region) => region.value), [regions])

  const { add: addToast } = useToast()

  const paseRegionsOccuped = useCallback((regionsOccuped = [], regionsList = []) => {
    return regionsOccuped.filter((region) => {
      const parsedRegion = String(region).trim()

      return parsedRegion && regionsList.includes(parsedRegion)
    })
  }, [])

  const errorsMessage = useMemo(
    () => ({
      404: 'Configuração não encontrada.',
      422: 'Confira se não há um conflito.',
      createAndUpdate: {
        422: 'Confira se já não há um termo com a mesma região.'
      },
      default: 'Houve um erro inesperado.'
    }),
    []
  )

  const showToastError = useCallback(
    (response, context = null) => {
      const statusCode = response.status

      const defaultMessage = errorsMessage.default

      if (context === 'create' || context === 'update') {
        const messageError = errorsMessage.createAndUpdate[statusCode] || defaultMessage

        addToast(messageError)
      } else {
        const messageError = errorsMessage[statusCode] || defaultMessage

        addToast(messageError)
      }
    },
    [addToast, errorsMessage]
  )

  const fetchRegions = useCallback(() => {
    setIsLoading(true)
    CompanyService.getAllyRegions()
      .then(setRegions)
      .catch(() => addToast('Ocorreu um erro inesperado no carregamento de regiões.'))
      .finally(() => setIsLoading(false))
  }, [addToast, setRegions, setIsLoading])

  const fetchSettings = useCallback(() => {
    setIsLoading(true)
    CompanyService.getAllCheckinSettings()
      .then(setSettings)
      .catch(showToastError)
      .finally(() => setIsLoading(false))
  }, [showToastError])

  const createSetting = useCallback(
    (setting, regionsOccuped, successCallback = () => {}) => {
      const isWithSameRegion = Boolean(
        paseRegionsOccuped(regionsOccuped, regionsList).find((region) => region === setting.region)
      )

      if (isWithSameRegion) {
        return addToast('Já existe um termo para a região selecionada.')
      }

      setIsLoading(true)
      return CompanyService.createCheckinSettings(setting)
        .then(() => successCallback())
        .catch((responseError) => showToastError(responseError, 'create'))
        .finally(() => setIsLoading(false))
    },
    [addToast, regionsList, paseRegionsOccuped, showToastError]
  )

  const deleteSetting = useCallback(
    (settingId) => {
      setIsLoading(true)
      CompanyService.removeCheckinSettings(settingId)
        .then(() => {
          fetchSettings()
          addToast('Configuração deletada.', 'success')
        })
        .catch((errorResponse) => {
          showToastError(errorResponse)
          setIsLoading(false)
        })
    },
    [addToast, fetchSettings, showToastError]
  )

  const fetchSetting = useCallback(
    (settingId, failCallback = () => {}) => {
      setIsLoading(true)
      return CompanyService.getCheckinSettings(settingId)
        .then(setSetting)
        .catch((errorResponse) => {
          showToastError(errorResponse)
          failCallback()
        })
        .finally(() => setIsLoading(false))
    },
    [showToastError]
  )

  const updateSetting = useCallback(
    (settingId, payload, regionsOccuped, successCallback = () => {}) => {
      const isWithSameRegion = Boolean(
        paseRegionsOccuped(regionsOccuped, regionsList).find((region) => region === setting.region)
      )

      if (isWithSameRegion) {
        return addToast('Já existe um termo para a região selecionada.')
      }

      setIsLoading(true)
      return CompanyService.updateCheckinSetting(settingId, payload)
        .then(() => {
          addToast('A configuração foi atualizada.', 'success')
          successCallback()
        })
        .catch((responseError) => showToastError(responseError, 'update'))
        .finally(() => setIsLoading(false))
    },
    [addToast, paseRegionsOccuped, regionsList, setting.region, showToastError]
  )

  return {
    deleteSetting,
    fetchSettings,
    settings,
    isLoading,
    fetchRegions,
    regions,
    createSetting,
    fetchSetting,
    setting,
    updateSetting
  }
}
