/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { userInviteSchema } from './Form/Form.schema'
import { useToast, Button, Loading, H3, P } from '@bonitour/components'
import { UserInviteForm } from './Form/Form'
import { useUserInvite } from './hooks/useUserInvite'

const buttonContainer = css`
  display: flex;
  button {
    margin-left: auto;
  }
`

export const UserInvite = () => {
  const { add: addToast } = useToast()
  const { isLoading, inviteInfo, heading, signupUser } = useUserInvite()
  const [initialState] = useState({})
  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(initialState, userInviteSchema)

  const onValidationError = () => addToast('Preencha o formulário corretamente')

  const { email, company } = inviteInfo
  const onClick = onSubmit(signupUser, onValidationError)
  const preventFormDefault = event => event.preventDefault()

  return (
    <>
      <form onSubmit={preventFormDefault}>
        <H3>Olá, você recebeu um convite para fazer parte da empresa {company}!</H3>
        <P>Preencha suas informações para confirmar seu acesso.</P>
        <P>Caso o email já esteja cadastrado, um novo perfil será vinculado à conta já existente.</P>
        <UserInviteForm
          userInvite={form}
          email={email}
          userInviteErrors={errors}
          onChange={onInputChange}
          onBlur={onInputBlur}
        />
        <div css={buttonContainer}>
          <Button type='submit' onClick={onClick}>Cadastrar</Button>
        </div>
      </form>

      <Loading isLoading={isLoading} headings={heading} />
    </>
  )
}
