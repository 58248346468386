import { orbCore } from 'Core/Service/Orb/Service'
import { AlertModel } from 'Core/Models/Alert'
import { AlertParser } from 'Core/Parser/Alert'

const { alertDomain } = orbCore

export const AlertService = {
  list ({ companyId }) {
    return alertDomain.list(companyId).then(AlertParser.list)
  },
  createAlert (alert) {
    return alertDomain.create(AlertModel.single(alert))
  },
  updateAlert (alert, alertId) {
    return alertDomain.update(AlertModel.single(alert), alertId)
  }
}
