import { useState } from 'react'
import { RolesService } from 'Core/Service/Roles'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'Shared/hooks/usePermissions'

export const useCreateCompanyRole = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [loading, setLoading] = useState(false)
  const createRole = async (roleData) => {
    setLoading(true)
    return RolesService.createCompanyRole({ ...roleData })
      .then(() => addToast('Papel de permissão criado com sucesso', 'success'))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão para criar papel de permissão')
        hasPermission && addToast('Ocorreu um erro ao criar um papel de permissão')
      })
      .finally(() => setLoading(false))
  }
  return {
    onCreateRole: createRole,
    loading
  }
}
