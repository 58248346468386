/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { formatMoney } from '@bonitour/common-functions'
import {
  EyeIcon,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TooltipOnHover,
  colors
} from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { paymentStatus } from 'Shared/constants/paymentStatus'
import { NavLink } from 'react-router-dom'

const iconButton = css`
  cursor: pointer;
  margin-right: 8px;
  font-size: 18px;
`

const smallField = css`
  width: 10%;
  font-size: 14px;
  vertical-align: middle;
  color: #6A6A6A;
  font-weight: bold;
  background-color: #F4F4F4;
  text-align: center;
`

const resetLink = css`
  color: ${colors.gray3};
  text-decoration: none;
`

const CompanyInvoiceTable = ({ invoices = {} }) => {
  return (
    <TableContainer style={{ marginTop: 16 }}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data de vencimento</TableHeaderCell>
          <TableHeaderCell>Valor total</TableHeaderCell>
          <TableHeaderCell>Plano</TableHeaderCell>
          <TableHeaderCell>Status do pagamento</TableHeaderCell>
          <TableHeaderCell css={smallField}>Ação</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {invoices.map(({
          id,
          dueDate,
          value,
          state,
          planName
        }) => (
          <TableBodyRow key={`invoice-${id}`}>
            <TableBodyCell>{dueDate}</TableBodyCell>
            <TableBodyCell>{formatMoney(value)}
            </TableBodyCell>
            <TableBodyCell>{planName}</TableBodyCell>
            <TableBodyCell>{paymentStatus[state] ?? 'não mapeado'}</TableBodyCell>
            <TableBodyCell>
              <TooltipOnHover text='Visualizar fatura'>
                <NavLink to={`/financial/invoices/${id}`} css={resetLink}>
                  <EyeIcon css={iconButton} />
                </NavLink>
              </TooltipOnHover>
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

export default CompanyInvoiceTable
