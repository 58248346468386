import { CompanyCheckinModel, CompanyModel } from 'Core/Models/Company'
import { CompanyParser } from 'Core/Parser/Company'
import { orbCore } from './Orb/Service'

const extractCompany = ({ company }) => company

const { companiesDomain, companyTermsDomain } = orbCore

export const CompanyService = {
  create (apiData) {
    return companiesDomain.create(CompanyModel.create(apiData)).then(extractCompany)
  },
  get (companyId) {
    return companiesDomain.get(companyId).then(CompanyParser.company)
  },
  update (companyId, apiData) {
    return companiesDomain.update(companyId, CompanyModel.company(apiData))
  },
  listUserCompanies (active) {
    return companiesDomain.listUserCompanies(active).then(CompanyParser.listUserCompanies)
  },
  getAllCheckinSettings () {
    return companyTermsDomain.getAllCheckinSettings().then(CompanyParser.allCheckinSettings)
  },
  removeCheckinSettings (settingId) {
    return companyTermsDomain.removeCheckinSettings(settingId)
  },
  createCheckinSettings (apiData) {
    return companyTermsDomain.createCheckinSettings(CompanyCheckinModel.create(apiData))
  },
  getAllyRegions () {
    return companyTermsDomain.getAllyRegions().then(CompanyParser.allyRegions)
  },
  getCheckinSettings (settingId) {
    return companyTermsDomain.getCheckinSettings(settingId).then(CompanyParser.checkinSettings)
  },
  updateCheckinSetting (settingId, apiData) {
    return companyTermsDomain.updateCheckinSetting(settingId, CompanyCheckinModel.update(apiData))
  }
}
