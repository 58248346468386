/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Email, InputFormGroup } from '@bonitour/components'

const inputMargin = css`
  margin: 20px 0;
`

export const ForgotPasswordForm = ({
  forgotPassword = {},
  forgotPasswordErrors = {},
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity
}) => {
  const { email = '' } = forgotPassword

  return (
    <InputFormGroup css={inputMargin} errorMessage={forgotPasswordErrors.email} label='E-mail'>
      <Email
        name='email'
        value={email}
        onChange={emitChangeEvent('email')}
        onBlur={emitBlurEvent('email')}
        required
      />
    </InputFormGroup>
  )
}
