/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { affiliatesPageContainer } from './Affiliates.style'
import { SalesTotalizers } from './SalesListingTable/SalesTotalizers'
import { AffiliateSalesHeader } from './AffiliateSalesHeader'
import { AffiliateSalesBody } from './AffiliateSalesBody'

import { useAffiliateSales } from './hooks/useAffiliateSales'

export const AffiliateSales = () => {
  const {
    affiliate,
    meta,
    sales,
    backToAffiliates,
    isLoadingAffiliateSales,
    batchTotals,
    filters,
    onFilterSubmit,
    onPageChange,
    onPerPageChange
  } = useAffiliateSales()

  return (
    <div css={affiliatesPageContainer}>
      <AffiliateSalesHeader onBack={backToAffiliates} affiliate={affiliate} />
      <SalesTotalizers affiliate={affiliate} batchTotals={batchTotals}/>

      <AffiliateSalesBody
        sales={sales}
        meta={meta}
        isLoading={isLoadingAffiliateSales}
        onFilterSubmit={onFilterSubmit}
        filters={filters}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
    </div>
  )
}
