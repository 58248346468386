import { colors } from '@bonitour/components'

export const invoiceStatusMap = {
  open: ['Em aberto', colors.green2],
  draft: ['Aguardando envio', colors.gray6],
  pending: ['Pendente', colors.red5],
  partially_paid: ['Parcialmente pago', colors.orange3],
  paid: ['Pago', colors.green3],
  overdue: ['Atrasado', colors.red2]
}
