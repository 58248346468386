import { useState, useCallback, useEffect } from 'react'
import { RolesService } from 'Core/Service/Roles'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'Shared/hooks/usePermissions'

export const useUnpaginatedRoles = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [allRoles, setAllRoles] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchAllRoles = useCallback(() => {
    setLoading(true)
    RolesService.getAllCompanyRoles()
      .then(({ roles }) => setAllRoles(roles))
      .finally(() => setLoading(false))
  }, [])

  const onRoleDelete = roleId => {
    RolesService.remove(roleId).then(() => {
      addToast('Papel de permissão excluído com sucesso', 'success')
      fetchAllRoles()
    })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão para excluir papéis de permissão')
        hasPermission && addToast('Ocorreu um erro ao excluir um papel de permissão')
      })
  }

  useEffect(() => {
    fetchAllRoles()
  }, [fetchAllRoles])

  return {
    allRoles,
    fetchAllRoles,
    onRoleDelete,
    loading
  }
}
