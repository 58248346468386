export function AffiliateUpdateModel (data) {
  const { email, percentage } = data
  return { email, percentage }
}

export function AffiliateUpdateCustomCommissionModel (data) {
  const { percentageByService = [] } = data
  const percentage_by_service = percentageByService.reduce(
    (acc, { serviceId, percentage }) => ({ ...acc, [serviceId]: percentage }),
    {}
  )
  return { percentage_by_service }
}

export function AffiliateCreateModel (data) {
  return {
    ...AffiliateUpdateModel(data),
    ...AffiliateUpdateCustomCommissionModel(data)
  }
}

export function AffiliateSignupModel (data) {
  const { name, email, password, token } = data
  return {
    user: { name, email, password },
    token
  }
}

export function AffiliateListModel (data) {
  const { page, perPage, name, status } = data
  return {
    page,
    per_page: perPage,
    ...(name && { name }),
    ...(status && { status })
  }
}

export function AffiliateSalesModel (data) {
  const { page, perPage, ticketStatus } = data
  // TODO add filter params once API accepts them
  return {
    page,
    per_page: perPage,
    status: ticketStatus || undefined
  }
}

export function AffiliateCompaniesModel (data) {
  const { page, perPage } = data
  return {
    page,
    per_page: perPage
  }
}

export function AffiliateCompanySalesModel (data) {
  const { page, perPage, companyId, ticketStatus } = data
  return {
    page,
    per_page: perPage,
    company_id: companyId,
    status: ticketStatus || undefined
  }
}

export function AffiliateTicketsModel (data) {
  const { page, perPage, startDate, endDate } = data
  return {
    page,
    per_page: perPage,
    ...(startDate && { start_date: new Date(startDate).toISOString().split('T')[0] }),
    ...(endDate && { end_date: new Date(endDate).toISOString().split('T')[0] })
  }
}

export function AffiliateSalesItemsExport ({ wantedFields, ticketStatus }) {
  return {
    wanted_fields: wantedFields,
    status: ticketStatus || undefined
  }
}

export function AffiliateSalesItemsExportByCompany ({ wantedFields, companyId, ticketStatus }) {
  return {
    wanted_fields: wantedFields,
    company_id: companyId,
    status: ticketStatus || undefined
  }
}
