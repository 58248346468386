import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const affiliatePageHeader = css`
  h1 {
    margin: 2px 0;
    overflow: visible;
  }
  h4,
  p {
    margin: 2px 0;
    min-width: 100%;
    display: block;
    line-height: 1.25;
  }
  p {
    color: ${colors.gray4};
    font-size: 14px;
  }
`

export const padding15 = css`
  padding: 15px !important;
`

export const submitButton = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`

export const paginationBarStyle = css`
  width: 100%;
  margin-top: 1rem;
`

export const loadingTableStyle = css`
  padding: 0;
`
