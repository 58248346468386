import { useToast } from '@bonitour/components'
import { UserService } from 'Core/Service/User'
import { useState } from 'react'

export const useUserToggleActive = () => {
  const { add: addToast } = useToast()
  const [loadingToggleActiveId, setLoadingToggleActiveId] = useState('')

  const onToggleActive = (id, getUserList) => {
    setLoadingToggleActiveId(id)
    UserService.toggleActive(id).then(() => {
      addToast('Atualização realizada com sucesso', 'success')
      getUserList()
    })
      .catch(() => addToast('Ocorreu um erro ao atualização do usuário'))
      .finally(() => setLoadingToggleActiveId(''))
  }

  return {
    loadingToggleActiveId,
    onToggleActive
  }
}
