import { AffiliateInviteDialog } from 'Domains/Affiliates/AffiliatesInvite/AffiliateInviteDialog'
import { Layout } from 'Domains/Layout/Layout'

export const testComponentRoutes = {
  path: '/test-component',
  component: Layout,
  routes: [
    {
      path: '/affiliate-invite-dialog',
      component: AffiliateInviteDialog
    }
  ]
}
