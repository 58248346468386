import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const pageContainer = css`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .list__card {
    .list__card__row {
      background-color: ${colors.gray12};
      padding: 1rem;
      border-radius: 12px;
    }
  }
`

export const headerContainer = css`
  display: flex;
  flex-direction: column;

  h4 {
    margin: 10px 0;
  }

  p {
    color: ${colors.gray5};
  }
`
