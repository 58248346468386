/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H3, Label, Input, InputWithSuffix, InputCnpjMask, flexColumn, Upload, InputFormGroup, Row, Column, UploadFootnote, InfoSwatch, BREAK_POINTS } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { marginBottom, marginTop, required } from 'assets/styles/global'
import { useState } from 'react'
import { infoIcon } from 'Shared/containers/ContactForm/ContactForm.style'

const marginBottom20 = marginBottom(20)
const marginTop7 = css`
  @media (max-width: ${BREAK_POINTS.tablet}) {
    ${marginTop(7)}
  }
`

const websiteInputContainerStyle = css`
  display: flex;
  align-items: baseline;
`

export const CompanyRegistry = ({
  companyRegistry = {},
  companyRegistryErrors = {},
  onInputBlur: emitInputBlur = identity,
  onChange: emitChangeEvent = identity,
  onError: emitErrorEvent = identity
}) => {
  const {
    name = '',
    tradingName = '',
    document = '',
    stateRegistrationCode = '',
    municipalRegistrationCode = '',
    cadastur = '',
    website = '',
    files = []
  } = companyRegistry

  const [isVisible, setVisibility] = useState(true)
  const toggleVisibility = () => setVisibility(!isVisible)
  return (
    <>
      <H3 css={marginBottom20}>Registro</H3>
      <Row>
        <Column phone={12} desktop={4}>
          <div css={flexColumn}>
            <Label htmlFor='name'>
              Nome fantasia
              <span css={required}>(Obrigatório)</span>
            </Label>
            <Input
              id='name'
              value={name}
              error={companyRegistryErrors.name}
              onChange={emitChangeEvent('name')}
              onBlur={emitInputBlur('name')}
            />
          </div>
        </Column>
        <Column phone={12} desktop={4} css={marginTop7}>
          <div css={flexColumn}>
            <Label htmlFor='companyName'>
              Razão social
              <span css={required}>(Obrigatório)</span>
            </Label>
            <Input
              id='tradingName'
              value={tradingName}
              error={companyRegistryErrors.tradingName}
              onChange={emitChangeEvent('tradingName')}
              onBlur={emitInputBlur('tradingName')}
            />
          </div>
        </Column>
        <Column phone={12} desktop={4} css={marginTop7}>
          <InputFormGroup
            id='document'
            label='CNPJ'
            errorMessage={companyRegistryErrors.document}
            required
          >
            <InputCnpjMask
              value={document}
              onChange={emitChangeEvent('document')}
              onBlur={emitInputBlur('document')}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} desktop={4}>
          <div css={flexColumn}>
            <Label htmlFor='stateRegistrationCode'>Inscrição Estadual</Label>
            <InputWithSuffix
              id='stateRegistrationCode'
              value={stateRegistrationCode}
              onChange={emitChangeEvent('stateRegistrationCode')}
            >
              Opcional
            </InputWithSuffix>
          </div>
        </Column>
        <Column phone={12} desktop={4} css={marginTop7}>
          <div css={flexColumn}>
            <Label htmlFor='municipalRegistrationCode'>
              Inscrição Municipal
              <span css={required}>(Obrigatório)</span>
            </Label>
            <Input
              id='municipalRegistrationCode'
              value={municipalRegistrationCode}
              error={companyRegistryErrors.municipalRegistrationCode}
              onChange={emitChangeEvent('municipalRegistrationCode')}
              onBlur={emitInputBlur('municipalRegistrationCode')}
            />
          </div>
        </Column>
        <Column phone={12} desktop={4} css={marginTop7}>
          <div css={flexColumn}>
            <Label htmlFor='cadastur'>Cadastur</Label>
            <InputWithSuffix
              id='cadastur'
              value={cadastur}
              onChange={emitChangeEvent('cadastur')}
            >
              Opcional
            </InputWithSuffix>
          </div>
        </Column>
      </Row>
      <Row>
        <Column phone={12} desktop={4} css={flexColumn}>
          <div css={flexColumn} onClick={toggleVisibility}>
            <span css={websiteInputContainerStyle}>
              <Label htmlFor='website'>Website</Label>
              <InfoSwatch tooltip='O website será exibido para os viajantes no rodapé dos emails e no aplicativo Binamik Ally' size={250} css={infoIcon} />
            </span>
            <InputWithSuffix
              id='website'
              value={website}
              onChange={emitChangeEvent('website')}
            >
              Opcional
            </InputWithSuffix>
          </div>
        </Column>
      </Row>
      <Row>
        <Label>Imagem de perfil</Label>
        <Upload
          files={files}
          onChange={emitChangeEvent('files')}
          onError={emitErrorEvent}
          limitMbSize={1}
          emptyMessage='Adicione uma foto com no máximo 1Mb'
          accept='image/png, image/jpeg'
        />
      </Row>
      <UploadFootnote
        typeList={['.jpg', '.png']}
        extraMessage='Proporção sugerida: 200x200 pixels'
      />
    </>
  )
}
