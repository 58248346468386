import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const card = css`
  background-color: ${colors.white};
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 1px 5px #00000014;
  padding: 20px 20px;
  width: 185px;
  align-items: center;
  margin: 0 20px 20px 0;
  text-align: center;
`
export const imageContainer = css`
  box-sizing: border-box;
  margin: 0 0 30px 0;
  background-color: ${colors.gray7};
  font-weight: bold;
  color: ${colors.white};
  text-transform: uppercase;
  font-size: 60px;
`
export const title = css`
  font-weight: bold;
  color: ${colors.gray2};
  font-size: 20px;
`

export const containerIconButton = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  font-weight: bold;
  color: ${colors.gray2};
`

export const iconStatusConfirmed = css`
  height: 8px;
  width: 8px;
  background-color: ${colors.green2};
  border-radius: 4px;
  margin-right: 8px;
`

export const resetLink = css`
  color: ${colors.gray3};
  text-decoration: none;
  background-color: transparent;
`
export const marginTop20 = css`
  margin-top: 20px;
`
