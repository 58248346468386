/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeaderPage } from '@bonitour/components'
import { AlertConfigForm } from './AlertConfigForm'
import { useAlert } from './hooks/useAlert'
import { useHistory } from 'react-router-dom'
import { alertsType } from './constants/alertTypeMap'
import { useUserList } from 'Domains/User/ListUser/hooks/useUserList'

export const AlertsConfig = () => {
  const { push } = useHistory()
  const { users } = useUserList()
  const { alerts = [], createAlert, updateAlert } = useAlert()

  const onBack = () => push('/app')

  const findAlertConfigurated = alertType => alerts.find(alert => alert?.alertType === alertType)

  const alertConfigurated = alertType => {
    const alertConfig = findAlertConfigurated(alertType)

    if (alertConfig) return alertConfig

    return {
      alertType,
      active: false,
      externalContacts: [],
      employeesIds: []
    }
  }

  const onSave = alert => {
    const hasAlertConfigurated = findAlertConfigurated(alert?.alertType)
    hasAlertConfigurated ? updateAlert(alert) : createAlert(alert)
  }

  return (
    <>
      <HeaderPage onBack={onBack} title='Avisos' />
      {alertsType.map(alertType => (
        <AlertConfigForm
          key={alertType}
          employees={users}
          alert={alertConfigurated(alertType)}
          onSave={onSave}
        />
      ))}
    </>
  )
}
