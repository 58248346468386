/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, colors, H3, Input, InputFormGroup, LoadingAnimation, P, ToggleInputGroup, useToast } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { marginBottom, marginLeft, marginTop, hidden, loadingContainer } from 'assets/styles/global'
import { companyRoleSchema } from '../companyRole.schema'

const marginBottom20 = marginBottom(20)
const marginBottom10 = marginBottom(10)
const marginBottom0 = marginBottom(0)
const marginTop20 = marginTop(20)
const marginLeft5 = marginLeft(5)

const width180 = css`
  width: 180px;
`

export const cardStyle = css`
  margin: 10px 0;
  padding: 30px 20px;

  & span:nth-child(n+2) {
    margin-top: 10px;
  }
`

export const flex = css`
  display: flex;
  align-items: center;
`

export const roleIcon = css`
  color: ${colors.primary};
`

export const grayText = css`
  color: ${colors.gray3};
`

export const boldText = css`
  font-weight: bold;
`

export const descriptionText = css`
  line-height: 1.5;
  text-indent: 10px;
`

export const EditCompanyRole = ({ loading = false, macroPermissions = {}, companyRole = {}, submit = identity }) => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { permissions } = macroPermissions
  const { id, name: roleName, macroPermissions: rolePermissions } = companyRole
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(companyRole, companyRoleSchema)
  const { name } = form

  const isPermissionActive = (permission) => selectedPermissions?.includes(permission)

  const onToggleClick = name => {
    selectedPermissions?.includes(name)
      ? setSelectedPermissions(selectedPermissions.filter(permission => permission !== name))
      : setSelectedPermissions([...selectedPermissions, name])
  }

  const onClick = () => {
    if (selectedPermissions.length < 1) return addToast('Selecione ao menos uma permissão')
    submit({ id, name, selectedPermissions })
    addToast('Papel editado com sucesso', 'success')
    push('/app/users?selectedNav=roles')
  }

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])

  const handleSubmit = onSubmit(onClick, onValidationError)

  useEffect(() => { setSelectedPermissions(rolePermissions) }, [rolePermissions])

  if (loading) return <LoadingAnimation size={30} css={loadingContainer} />
  return (
    <>
      <H3 css={[grayText, boldText]}>Nome do papel</H3>
      <InputFormGroup
        css={marginBottom20}
        label=''
        id='name'
        required
        errorMessage={errors.name}
      >
        <Input
          css={width180}
          id=''
          maxLength='50'
          defaultValue={roleName}
          onChange={onInputChange('name')}
          onBlur={onInputBlur('name')}
        />
      </InputFormGroup>
      <H3 css={boldText}>Permissões</H3>
      {permissions && permissions.map(permission => permission?.translatedName &&
        <>
          <span css={[flex, marginBottom10]} key={permission.name}>
            <ToggleInputGroup id={permission.name} checked={isPermissionActive(permission.name)} onChange={null} onClick={() => onToggleClick(permission.name)}>
              {permission.translatedName}
            </ToggleInputGroup>
          </span>
          <Card css={[cardStyle, !isPermissionActive(permission.name) && hidden]}>
            <H3>Recursos disponíveis</H3>
            <span css={flex}>
              <P css={[marginBottom0, marginLeft5, descriptionText]}>{permission.description}</P>
            </span>
          </Card>
        </>
      )}
      <Button css={marginTop20} onClick={handleSubmit}>Salvar</Button>
    </>
  )
}
