import { orbCore } from 'Core/Service/Orb/Service'

const { authDomain } = orbCore

export const AuthApi = {
  login (payload = {}) {
    const { email, password, authorization } = payload
    if ((!email || !password) && authorization) {
      return authDomain.login({ authorization })
    }
    return authDomain.login({
      user: { email, password }
    })
  },
  oauthCallback (payload) {
    return authDomain.oauthCallback(payload)
  },
  signup (payload) {
    const { name, email, password } = payload
    const legalAgreementTypes = ['company_terms_of_use', 'privacy_policy']
    return authDomain.signup({
      user: { name, email, password, legal_agreement_types: legalAgreementTypes }
    })
  },
  forgotPassword ({ email }) {
    return authDomain.forgotPassword({ email })
  },
  switchCompany (companyId) {
    return authDomain.switchCompany(companyId)
  }
}
