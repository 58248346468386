import { object, string, array } from 'yup'
import { validateCnpj, validatePhoneWithDDI } from '@bonitour/common-functions'

import { contactPhoneSchema } from 'Shared/containers/ContactForm/ContactPhone/ContactPhone.rules'
import { contactEmailSchema } from 'Shared/containers/ContactForm/ContactEmail/ContactEmail.rules'
import { addressSchema } from 'Shared/containers/Address/Address.rules'

export const companyInfoSchema = (states = [], cities = []) => ({
  name: string().required('O nome fantasia é obrigatório'),
  tradingName: string().required('A razão social é obrigatória'),
  municipalRegistrationCode: string().required('A inscrição municipal é obrigatória'),
  document: string()
    .test('test-validateCnpj', 'O CNPJ deve ser válido', validateCnpj)
    .required('O CNPJ é obrigatório'),
  phones: array().of(object().shape(contactPhoneSchema)),
  emails: array().of(object().shape(contactEmailSchema)),
  businessEmail: string()
    .nullable()
    .email('O email deve ser válido'),
  whatsapp: string().test('validate-phone', 'O número do Whatsapp deve ser válido', (whatsapp) => {
    if (whatsapp.length <= 4) {
      return true
    }
    return whatsapp && validatePhoneWithDDI(whatsapp)
  }),
  addressInfo: object().shape(addressSchema(states, cities))
})
