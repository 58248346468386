export const AddressModel = data => {
  const {
    city,
    state,
    country,
    timezone,
    zipCode,
    address: street,
    district: region,
    addressDetail: supplement,
    latLong = ''
  } = data

  const [latitude = 0, longitude = 0] = latLong
    .replace(/\s/gi, '')
    .split(',')
    .map(parseFloat)

  return {
    city_id: city,
    state_id: state,
    country_id: country,
    timezone,
    post_code: zipCode,
    street,
    region,
    supplement,
    latitude,
    longitude
  }
}
