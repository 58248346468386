/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

import { ContactForm } from 'Shared/containers/ContactForm/ContactForm'
import { Address } from 'Shared/containers/Address/Address'

import { CompanyRegistry } from './BasicInfo'
import { marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)

export const CompanyInfoForm = ({
  form = {},
  errors = {},
  countries = [],
  states = [],
  cities = [],
  timeZoneOptions = [],
  onError: emitErrorEvent = identity,
  onZipCodeBlur = identity,
  addItemOnArray = identity,
  removeItemArray = identity,
  onLatLongBlur = identity,
  onStateChange = identity,
  onCountryChange = identity,
  onInputBlur = identity,
  onInputChange = identity,
  onSubmit = identity
}) => {
  return (
    <>
      <CompanyRegistry
        companyRegistry={form}
        companyRegistryErrors={errors}
        onChange={onInputChange}
        onError={emitErrorEvent}
        onInputBlur={onInputBlur}
      />
      <ContactForm
        phones={form.phones}
        whatsapp={form.whatsapp}
        emails={form.emails}
        businessEmail={form.businessEmail}
        phonesErrors={errors.phones}
        whatsappErrors={errors.whatsapp}
        emailsErrors={errors.emails}
        businessEmailErrors={errors.businessEmail}
        onBlur={onInputBlur}
        onChange={onInputChange}
        onPhoneAdd={addItemOnArray('phones')}
        onPhoneRemove={removeItemArray('phones')}
        onWhatsappAdd={addItemOnArray('whatsapp')}
        onWhatsappRemove={removeItemArray('whatsapp')}
        onEmailAdd={addItemOnArray('emails')}
        onEmailRemove={removeItemArray('emails')}
        onbusinessEmailAdd={addItemOnArray('businessEmail')}
        onbusinessEmailRemove={removeItemArray('businessEmail')}
      />
      <Address
        addressInfo={form.addressInfo}
        addressInfoErrors={errors.addressInfo}
        onInputBlur={onInputBlur}
        onChange={onInputChange}
        onCountryChange={onCountryChange}
        onStateChange={onStateChange}
        onZipCodeBlur={onZipCodeBlur}
        onLatLongBlur={onLatLongBlur}
        countries={countries}
        states={states}
        cities={cities}
        timeZoneOptions={timeZoneOptions}
      />
      <Button css={marginTop50} onClick={onSubmit}>
        Salvar e sair
      </Button>
    </>
  )
}
