import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const backButton = css`
  background-color: transparent;
  color: ${colors.gray4};
  border: 1.4px solid ${colors.gray4};
`
export const minWidthButton = css`
  min-width: 8.5rem;
`
