import { orbCore } from './Orb/Service'
import { RolesParser } from 'Core/Parser/Role'
import { RolesModel } from 'Core/Models/Role'

const { rolesDomain, rolesDomainV2 } = orbCore

export const RolesService = {
  getAllCompanyRoles () {
    return rolesDomainV2.getAllCompanyRoles().then(RolesParser.list)
  },
  getCompanyRoles (page, perPage) {
    if (!(perPage && page)) return Promise.resolve({})
    return rolesDomain.getCompanyRoles(perPage, page).then(RolesParser.card)
  },
  getCompanyRole (roleId) {
    return rolesDomain.getCompanyRole(roleId).then(RolesParser.companyRole)
  },
  getMacroPermissions () {
    return rolesDomain.getMacroPermissions().then(RolesParser.macroPermissions)
  },
  createCompanyRole (roleData) {
    return rolesDomain.createCompanyRole(RolesModel.create(roleData))
  },
  updateCompanyRole (roleData, id) {
    return rolesDomain.updateCompanyRole(RolesModel.update(roleData, id), id)
  },
  remove (roleId) {
    return rolesDomain.remove(roleId)
  },
  removeEmployees (roleId, employeeIds) {
    return rolesDomain.removeEmployees(roleId, RolesModel.removeEmployees(employeeIds))
  }
}
