import React from 'react'
import { FormCheckinSettings } from '../Form'
import { useQuery } from 'Shared/hooks/useQuery'

const EditCheckinSettingsPage = ({ match }) => {
  const { regionsOccuped = '' } = useQuery()

  return (
    <FormCheckinSettings
      isEdit={true}
      settingId={match.params.settingId ?? null}
      regionsOccuped={regionsOccuped.split(',')}
    />
  )
}

export { EditCheckinSettingsPage }
