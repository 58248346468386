export const activityComplementaryInfoParser = (activity = {}) => {
  let {
    description,
    observation,
    payment_types: paymentsTypes,
    what_to_take: whatToTake
  } = activity

  description = JSON.parse(description) || {}

  return {
    description: description['pt-br'] || '',
    observation: observation || '',
    paymentsTypes: paymentsTypes || [],
    whatToTake: whatToTake || []
  }
}
