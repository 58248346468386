/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { Button, Card, H3, Input, InputFormGroup, LoadingAnimation, P, ToggleInputGroup, useToast } from '@bonitour/components'
import { hidden, loadingContainer } from 'assets/styles/global'
import { companyRoleSchema } from '../companyRole.schema'
import { boldText, cardStyle, descriptionText, flex, grayText, marginBottom0, marginBottom10, marginBottom20, marginLeft5, marginTop20, width180 } from './CreateCompanyRole.style'

export const CreateCompanyRole = ({
  loading = false,
  macroPermissions = {},
  role = {},
  onSubmit: emitSubmit = identity
}) => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { permissions } = macroPermissions
  const [selectedPermissions, setSelectedPermissions] = useState([])

  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(role, companyRoleSchema)
  const { name } = form

  const isPermissionActive = (permission) => selectedPermissions.includes(permission)
  const onToggleClick = name => {
    selectedPermissions.includes(name)
      ? setSelectedPermissions(selectedPermissions.filter(permission => permission !== name))
      : setSelectedPermissions([...selectedPermissions, name])
  }

  const onSubmitCompanyRole = () => {
    if (selectedPermissions.length < 1) return addToast('Selecione ao menos uma permissão')
    emitSubmit({ name, selectedPermissions })
    push('/app/users?selectedNav=roles')
  }

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])

  const handleSubmit = onSubmit(onSubmitCompanyRole, onValidationError)

  if (loading) return <LoadingAnimation size={30} css={loadingContainer} />

  return (
    <>
      <H3 css={[grayText, boldText]}>Nome do papel</H3>
      <InputFormGroup css={marginBottom20} id='name' errorMessage={errors.name} required>
        <Input
          css={width180}
          maxLength='50'
          value={name}
          onChange={onInputChange('name')}
          onBlur={onInputBlur('name')}
        />
      </InputFormGroup>
      <H3 css={boldText}>Permissões</H3>
      {permissions && permissions.map(permission => permission?.translatedName &&
        <>
          <span css={[flex, marginBottom10]} key={permission.name}>
            <ToggleInputGroup id={permission.name} checked={isPermissionActive(permission.name)} onChange={null} onClick={() => onToggleClick(permission.name)}>
              {permission.translatedName}
            </ToggleInputGroup>
          </span>
          <Card css={[cardStyle, !isPermissionActive(permission.name) && hidden]}>
            <H3>Recursos disponíveis</H3>
            <span css={flex}>
              <P css={[marginBottom0, marginLeft5, descriptionText]}>{permission.description}</P>
            </span>
          </Card>
        </>
      )}
      <Button css={marginTop20} onClick={handleSubmit}>Salvar</Button>
    </>
  )
}
