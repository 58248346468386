/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H4, Card, flexColumn, Input, Column, Row, GhostButton, colors, Button, useToast, InputFormGroup, Password } from '@bonitour/components'
import { marginRight, marginTop, marginLeft } from 'assets/styles/global'
import { useState } from 'react'
import { useForm } from '@bonitour/app-functions'
import { integrationAuthSchema } from './IntegrationAuth.schema'
import { identity } from '@bonitour/common-functions'
import { TwilioHelp } from '../Modal/IntegrationDialog'

const marginTop30 = marginTop(30)
const marginRight10 = marginRight(10)
const marginLeft10 = marginLeft(10)

const card = css`
  padding: 20px;
`

const ghostButtonPrimary = css`
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
`

export const ExpandConfig = ({
  isVoucherUnico,
  isTwilio,
  integrationId,
  auth,
  fetchIntegration = identity,
  onEdit: emitEdit = identity
}) => {
  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(auth, integrationAuthSchema(isVoucherUnico, isTwilio))
  const { cnpj, login, password, token, companyName, phone } = form
  const [canEdit, setEditable] = useState(false)
  const { add: addToast } = useToast()

  const onEditClick = () => setEditable(true)

  const onCancelClick = () => {
    setEditable(false)
    fetchIntegration()
  }

  const onValidationError = () => addToast('Preencha o formulário corretamente')

  const onEdit = integration => {
    emitEdit(integrationId, integration)
    setEditable(false)
  }

  const onSaveClick = onSubmit(onEdit, onValidationError)

  return (
    <>
      <H4>Autenticação</H4>
      <Card css={card}>
        <Row>
          {!isVoucherUnico && !isTwilio &&
            <>
              <Column phone={12} desktop={6} css={flexColumn}>
                <InputFormGroup errorMessage={errors.cnpj} label='CNPJ' required>
                  <Input value={cnpj} onChange={onInputChange('cnpj')} onBlur={onInputBlur('cnpj')} disabled={!canEdit} />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={6} css={flexColumn}>
                <InputFormGroup errorMessage={errors.token} label='Token' required>
                  <Input value={token} onChange={onInputChange('token')} onBlur={onInputBlur('token')} disabled={!canEdit} />
                </InputFormGroup>
              </Column>
            </>}
          {(isVoucherUnico || isTwilio) &&
            <>
              <Column phone={12} desktop={4} css={flexColumn}>
                <InputFormGroup errorMessage={errors.companyName} label='Empresa' required>
                  <Input value={companyName} onChange={onInputChange('companyName')} onBlur={onInputBlur('companyName')} disabled={!canEdit} />
                </InputFormGroup>
              </Column>
              {isTwilio && (
                <Column phone={12} desktop={4} css={flexColumn}>
                  <InputFormGroup errorMessage={errors.phone} label='Número de telefone' required>
                    <Input value={phone} onChange={onInputChange('phone')} onBlur={onInputBlur('phone')} disabled={!canEdit} />
                  </InputFormGroup>
                </Column>
              )}
              <Column phone={12} desktop={4} css={flexColumn}>
                <InputFormGroup errorMessage={errors.login} label={isTwilio ? 'Conta (SID)' : 'Login'} required>
                  <Input value={login} onChange={onInputChange('login')} onBlur={onInputBlur('login')} disabled={!canEdit} />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={4} css={flexColumn}>
                <InputFormGroup errorMessage={errors.password} label={isTwilio ? 'Token' : 'Senha'} required>
                  <Password value={password} onChange={onInputChange('password')} onBlur={onInputBlur('password')} disabled={!canEdit} />
                </InputFormGroup>
              </Column>
            </>}
        </Row>
        {isTwilio && (
          <Row css={[marginTop30, marginLeft10]}>
            <TwilioHelp />
          </Row>
        )}
        <Row css={marginTop30}>
          <Column phone={12} desktop={12}>
            {!canEdit && <GhostButton css={ghostButtonPrimary} onClick={onEditClick}>Editar</GhostButton>}
            {canEdit && <GhostButton css={marginRight10} onClick={onCancelClick}>Cancelar</GhostButton>}
            {canEdit && <Button onClick={onSaveClick}>Salvar</Button>}
          </Column>
        </Row>
      </Card>
    </>
  )
}
