import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const container = css`
  width: 100%;
  box-sizing: border-box;
  margin: 30px auto;
  padding: 1.25rem;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 16px;
    }
  }
  @media (min-width: ${BREAK_POINTS.phone}) {
    padding: 1;
    margin-left: -25px;
    margin-right: -25px;
    width: auto;
  }

  @media (min-width: ${BREAK_POINTS.smallTablet}) {
    margin-left: -125px;
    margin-right: -125px;
  }

  @media (min-width: ${BREAK_POINTS.tablet}) {
    margin-left: -225px;
    margin-right: -225px;
  }
`

export const profileRow = css`
  gap: 1rem;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  justify-items: center;
  justify-content: center;
`

export const logoutRow = css`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-right: 0.25rem;
  gap: 1rem;
`

export const profileCard = css`
  padding: 15px;
  min-width: 180px;
  cursor: pointer;

  &:hover {
    border-color: ${colors.primary};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .AvatarCard__title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .AvatarCard__description {
    font-size: 14px;
    margin: 0 0 5px 0;
  }
`

export const loadingCss = css`
  margin: 0 auto;
`
