import { css } from '@emotion/core'

export const statusContainer = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

export const affiliatesListStyles = css`
  padding: 0;
  width: 100%;

  .affiliate__name {
    width: 100%;
    max-width: 20ch;
    position: relative;
  }

  .affiliate__value_box {
    width: 100%;
    max-width: 12ch;
    white-space: normal;
  }

  tr:not(.status__pending) {
    cursor: pointer;
    .affiliate__actions_cell {
      cursor: default;
    }
  }
`
