/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useRef } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { DotsMenuIcon as ProductsMenuIcon, Manager, Reference, Popover, colors } from '@bonitour/components'
import { Products } from './Products'

const iconContainer = css`
  cursor: pointer;
  margin-right: 24px;
`

const icon = css`
  font-size: 18px;
  color: ${colors.gray2};
  cursor: pointer;
`

export const ProductsMenu = () => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)

  const toggleVisibility = () => setVisibility(visibility => !visibility)
  return (
    <div ref={reference}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div css={iconContainer} ref={ref} onClick={toggleVisibility}>
              <ProductsMenuIcon css={[icon]} />
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position='bottom'>
            <Products />
          </Popover>
        )}
      </Manager>
    </div>
  )
}
