/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect } from 'react'
import { H1, H2, Card, AvatarCard, Row, LoadingAnimation, LogoutIcon, PlusIcon } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { AuthorizationProvider, useAuthorization } from 'Shared/contexts/Authorization'
import { useCompany } from 'Shared/contexts/Company'
import { useUser } from 'Shared/contexts/User'
import { useQuery } from 'Shared/hooks/useQuery'
import { container, loadingCss, logoutRow, profileCard, profileRow } from './ProfileSelector.styles'
import { ShortcutItem } from 'Domains/Layout/Shortcuts'

const SelectorWithAuthProvider = () => {
  const { logout } = useAuthorization()
  const { updateProfileType, user, hasAffiliateProfile } = useUser()
  const { userCompanies, switchCompany, isLoadingUserCompanies } = useCompany()
  const { push } = useHistory()
  const { redirectUrl, noAutoSelect } = useQuery()

  const onProfileSelection = useCallback(profile => () => {
    updateProfileType(profile)
    push(`/auth/login${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`)
  }, [push, updateProfileType, redirectUrl])

  const onCompanySelection = useCallback(companyId => () => {
    switchCompany(companyId).then(() => onProfileSelection('agent')())
  }, [onProfileSelection, switchCompany])

  const onAffiliateSelection = useCallback(() => {
    switchCompany(null).then(() => onProfileSelection('affiliate')())
  }, [onProfileSelection, switchCompany])

  const newCompany = useCallback(() => {
    switchCompany(null).then(() => onProfileSelection('agent')())
  }, [onProfileSelection, switchCompany])

  useEffect(() => {
    const isLoadingCompanies = userCompanies === null
    const companiesCount = userCompanies?.length || 0
    const skipAutoSelect = noAutoSelect && companiesCount
    if (isLoadingCompanies || !user || hasAffiliateProfile || companiesCount > 1 || skipAutoSelect) {
      return
    }
    if (userCompanies.length) {
      switchCompany(userCompanies[0].id).then(() => onProfileSelection('agent')())
    } else {
      onProfileSelection('agent')()
    }
  }, [user, onProfileSelection, userCompanies, hasAffiliateProfile, noAutoSelect, switchCompany])

  if (((userCompanies?.length || 0) <= (!noAutoSelect ? 1 : 0) && !hasAffiliateProfile) || isLoadingUserCompanies) {
    return <LoadingAnimation customCss={[loadingCss]} />
  }

  return (
    <Card css={container}>
      <H1>Bom dia, {user.name}</H1>
      <H2>Selecione um perfil para continuar:</H2>
      <Row css={profileRow}>
        {userCompanies?.map(company => (
          <AvatarCard
            key={company.id}
            image={company.image}
            title={company.name}
            description='Perfil de reservas'
            onClick={onCompanySelection(company.id)}
            initialsFallback
            css={profileCard}
          />
        ))}
        {hasAffiliateProfile && (
          <AvatarCard
            image={user.avatar}
            title={user.name}
            description='Perfil de afiliado'
            onClick={onAffiliateSelection}
            initialsFallback
            css={profileCard}
          />
        )}
      </Row>
      <Row css={logoutRow}>
        <ShortcutItem onClick={newCompany} icon={PlusIcon} label='Cadastrar nova empresa' />
        <ShortcutItem onClick={logout} icon={LogoutIcon} label='Sair' />
      </Row>
    </Card>
  )
}

export const ProfileSelector = () => (
  <AuthorizationProvider>
    <SelectorWithAuthProvider />
  </AuthorizationProvider>
)
