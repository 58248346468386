import { css } from '@emotion/core'
import { colors, scrollBar } from '@bonitour/components'

export const container = css`
  width: 80%;
  height: 280px;
  overflow: auto;
  ${scrollBar(5)};
  padding: 10px;
  color: ${colors.gray3};
  border: none;
`

export const reduceHeaderPadding = css`
  padding: 15px;
`
