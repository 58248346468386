import { string, number, object, array } from 'yup'

const percentageSchema = number('Numero').required('O percentual do afiliado é obrigatório').min(0, 'O percentual deve ser positivo').typeError('O percentual é obrigatório')

export const affiliateSchema = {
  email: string().required('O e-mail do afiliado é obrigatório').email('E-mail deve ser válido'),
  percentage: percentageSchema,
  percentageByService: array().of(
    object().shape({
      serviceId: string()
        .required('Selecione uma experiência')
        .test('unique-service-id', 'Experiência já selecionada', (_value, ctx) => !ctx.parent.duplicate),
      percentage: percentageSchema
    })
  )
}

export const affiliateBaseSchema = affiliate => ({
  percentage: affiliate?.percentage ?? 1,
  percentageByService: affiliate?.percentageByService
    ? Object.entries(affiliate?.percentageByService).map(([serviceId, percentage]) => ({ serviceId, percentage }))
    : [],
  email: affiliate?.email ?? ''
})
