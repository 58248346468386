import { string } from 'yup'
import { isValidSmsNumber } from '../Modal/ConfirmIntegration.schema'

export const integrationAuthSchema = (isVoucherUnico, isTwilio) => ({
  cnpj: string().test('cnpj-required', 'Informe o CNPJ', cnpj => (isVoucherUnico || isTwilio) ? true : Boolean(cnpj)),
  token: string().test('token-required', 'Informe o token', token => (isVoucherUnico || isTwilio) ? true : Boolean(token)),
  companyName: string().test('token-required', 'Informe a empresa', companyName => isVoucherUnico ? Boolean(companyName) : true),
  login: string().test('token-required', 'Informe o login', login => (isVoucherUnico || isTwilio) ? Boolean(login) : true),
  password: string().test('token-required', 'Informe a senha', password => (isVoucherUnico || isTwilio) ? Boolean(password) : true),
  phone: string().test('phone-required', 'Informe um número válido', phone => isTwilio ? isValidSmsNumber(phone) : true)
})
