import { bool, string } from 'yup'

export const userInviteSchema = {
  name: string().required('O nome completo é obrigatório'),
  password: string()
    .required('Preencha o campo de senha')
    .min(6, 'A senha é muito curta, mínimo obrigatório de 6 caracteres'),
  serviceTerms: bool()
    .oneOf([true], 'A confirmação é obrigatória')
    .required('A confirmação é obrigatória')
}
