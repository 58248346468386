/* eslint-disable camelcase */
export const AlertModel = {
  single: (alert = {}) => {
    const {
      alertType: alert_type,
      active,
      employeesIds: employees_ids,
      externalContacts: external_contacts
    } = alert

    return {
      alert_type,
      active,
      employees_ids,
      external_contacts: {
        emails: external_contacts
      }
    }
  }
}
