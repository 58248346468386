/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

import { useCompany } from 'Shared/contexts/Company'
import { CompanyService } from 'Core/Service/Company'

import { CompanyInfoForm } from '../CompanyInfoForm/CompanyInfoForm'
import { useCompanyForm } from '../CompanyInfoForm/useCompanyForm'

const companyBase = {
  phones: [{ name: '', number: '' }],
  whatsapp: '',
  emails: [{ name: '', email: '' }],
  businessEmail: ''
}

export const CreateCompanyInfo = () => {
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { updateCompany, switchCompany } = useCompany()

  const onSuccess = async data => {
    try {
      const company = await CompanyService.create(data)
      if (company) {
        updateCompany(company)
        switchCompany(company.id).then(() => push('/'))
        addToast('Empresa criada com sucesso', 'success')
      }
    } catch (error) {
      addToast('Houve um erro na criação do seu atrativo')
    }
  }

  const companyFormProps = useCompanyForm({
    companyBase,
    onSuccess,
    addToast
  })

  return (
    <CompanyInfoForm
      {...companyFormProps}
    />
  )
}
