/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { CompanyService } from 'Core/Service/Company'
import { useCompany } from 'Shared/contexts/Company'
import { CompanyInfoForm } from '../CompanyInfoForm/CompanyInfoForm'
import { useCompanyForm } from '../CompanyInfoForm/useCompanyForm'
import { usePermissions } from 'Shared/hooks/usePermissions'

export const EditCompanyInfo = ({ onNextStep = identity }) => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [companyBase, setCompanyBase] = useState({})
  const { id, updateCompany } = useCompany()

  const fetchCompany = useCallback(() => CompanyService.get(id), [id])

  useEffect(() => {
    fetchCompany()
      .then(setCompanyBase)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a empresa')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
    return () => setCompanyBase({})
    // eslint-disable-next-line
  }, [fetchCompany])

  const onSuccess = async data => {
    try {
      await CompanyService.update(id, data)
      const company = await fetchCompany()
      updateCompany(company)
      onNextStep()
      addToast('Empresa editada com sucesso', 'success')
    } catch (error) {
      const hasPermission = handlePermission(error, 'Você não possui permissão de edição da empresa')
      hasPermission && addToast('Houve um erro na edição da empresa')
    }
  }

  const companyFormProps = useCompanyForm({ companyBase, onSuccess, addToast })

  return (
    <CompanyInfoForm {...companyFormProps} />
  )
}
