/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { LoadingAnimation, PaginationContainer, BREAK_POINTS } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { IntegrationCard } from '../Card/IntegrationCard'
import { loadingContainer } from 'assets/styles/global'

export const cardContainer = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    justify-content: center;
  }
`

export const IntegrationsList = ({
  loading,
  integrations = [],
  total = 0,
  pagesQuantity = 1,
  onPagination: emitPaginationEvent = identity
}) => {
  return (
    <PaginationContainer total={total} pagesQuantity={pagesQuantity} onPagination={emitPaginationEvent} childrenCustomCss={[cardContainer]}>
      {loading
        ? <LoadingAnimation css={loadingContainer} />
        : integrations.map((integration, index) => <IntegrationCard key={index} integration={integration} />)}
    </PaginationContainer>
  )
}
