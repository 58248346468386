import { css } from '@emotion/core'
import { colors, flexHorizontallyCentered, scrollBar } from '@bonitour/components'

export const bold = css`
  font-weight: bold;
`

export const addRecipient = css`
  width: 100%;
  margin: 10px 0;
`

export const employeesFilteredContainer = css`
  max-height: 150px;
  margin-bottom: 10px;
  overflow-y: auto;
  ${scrollBar(5)};
`

export const employeeFilteredContainer = css`
  ${flexHorizontallyCentered};
  padding: 5px 0;
  color: ${colors.gray3};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray12};
  }
`

export const addEmployeeIcon = css`
  font-size: 20px;
  margin: 0 5px;
`

export const addRecipientLabel = css`
  margin: 0 0 20px;
`

export const recipientsContainer = css`
  max-height: 150px;
  margin-bottom: 10px;
  overflow-y: auto;
  ${scrollBar(5)};
`

export const recipientItemContainer = css`
  ${flexHorizontallyCentered};
  padding: 5px 0; 
`

export const recipientItem = css`
  color: ${colors.gray3};
`

export const removeEmployeeIcon = css`
  margin-right: 5px;
  font-size: 20px;
  color: ${colors.gray3};
  cursor: pointer;
`
