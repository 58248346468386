/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  H1,
  CompanyIcon,
  Label,
  Row,
  Column,
  RedeemCodeIcon,
  UsersIcon,
  KeyIcon,
  AngleDownIcon,
  flexHorizontallyCentered,
  Manager,
  Reference,
  Popover,
  CashIcon,
  CogIcon,
  AlertsIcon,
  HandshakeIcon,
  CheckinIcon
} from '@bonitour/components'
import { useRef, useCallback } from 'react'
import { useUser } from 'Shared/contexts/User'
import { useCompany } from 'Shared/contexts/Company'
import { NavLink } from 'react-router-dom'
import { CardButton } from 'Shared/components/CardButton'
import { DashboardProducts } from './Products'
import {
  cardIcon,
  labelCardButton,
  redeemCardIcon,
  integrationsCardIcon,
  angleDownStyle,
  linkColor,
  cardItemIcon,
  cardItems,
  cardButton,
  percentageIconStyle,
  commingSoonStyle,
  loadingCard,
  smallIcons
} from './Dashboard.style'
import { useClickOutside } from '@bonitour/app-functions'
import { useFeatureFlag } from 'Shared/contexts/Feature'
import legalPaperIcon from 'Shared/svgs/legal-paper-icon.svg'
import { clickable, mobileCardContainer, resetLink } from 'assets/styles/global'

const {
  REACT_APP_ORB_URL,
  REACT_APP_XPERT_URL,
  REACT_APP_CASHBACK_URL,
  REACT_APP_COUPONS_URL,
  REACT_APP_ACCOUNTS_URL
} = process.env

const redirectOnClick = (link) => () => {
  window.location = link
}

const PercentageIcon = () => <span css={percentageIconStyle}>%</span>

const CardItemRow = ({ to = '', children, enabled = true }) => (
  <Row onClick={enabled ? to : undefined} css={[linkColor, clickable]}>
    <div css={flexHorizontallyCentered}>{children}</div>
  </Row>
)

const CardItem = ({ title, icon: Icon = null, children, customCss = [], beforeToggle = null, disabled, ...other }) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)

  const toggleVisibility = useCallback(() => {
    if (disabled) return
    if (!beforeToggle) {
      setVisibility((visibility) => !visibility)
    } else {
      const shouldToggle = beforeToggle()
      if (shouldToggle) {
        setVisibility((visibility) => !visibility)
      }
    }
  }, [beforeToggle, disabled, setVisibility])

  return (
    <div ref={reference} {...other}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={toggleVisibility}>
              <CardButton css={[...customCss, disabled ? loadingCard : null]}>
                <div css={[flexHorizontallyCentered, cardItemIcon]}>
                  {Icon && <Icon css={cardIcon} />}
                  <AngleDownIcon css={angleDownStyle} />
                </div>
                <Label css={labelCardButton}>{title}</Label>
              </CardButton>
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position="bottom" css={cardItems}>
            {children}
          </Popover>
        )}
      </Manager>
    </div>
  )
}

export const Dashboard = () => {
  const { company } = useCompany()
  const { user = {} } = useUser()
  const { name = '', company: userCompany } = user
  const { roles = [] } = userCompany || {}
  const hasCompany = Boolean(company.id)
  const isBinamikAdmin = roles.includes('binamik_admin')

  const [isCashbackEnabled] = useFeatureFlag('cashback')
  const [isFinancialEnabled] = useFeatureFlag('financial')
  const [isAffiliatesButtonEnabled] = useFeatureFlag('accounts-affiliates-button')

  return (
    <>
      <Row>
        <Column phone={12}>
          <H1>{name ? `Bem Vindo(a), ${name}` : 'Bem Vindo(a)'}</H1>
        </Column>
      </Row>

      <Row>
        <Column phone={12} css={mobileCardContainer}>
          {isBinamikAdmin && (
            <CardItem title="Backoffice" icon={CogIcon} customCss={[cardButton]}>
              <CardItemRow css={cardIcon} to={redirectOnClick('/app/binamik/users')}>
                Todos usuários
              </CardItemRow>
              <CardItemRow css={cardIcon} to={redirectOnClick('/app/binamik/invoices')}>
                Faturas Binamik
              </CardItemRow>
            </CardItem>
          )}

          {hasCompany && (
            <CardButton
              onClick={redirectOnClick(`${REACT_APP_ORB_URL}/tickets/check-in`)}
              css={[clickable, cardButton]}
            >
              <RedeemCodeIcon css={redeemCardIcon} />
              <Label css={labelCardButton}>Validar</Label>
            </CardButton>
          )}

          {(hasCompany && isCashbackEnabled)
            ? (
              <CardItem title="Descontos" icon={PercentageIcon} customCss={[cardButton]}>
                <CardItemRow css={cardIcon} to={redirectOnClick(`${REACT_APP_CASHBACK_URL}`)} enabled={false}>
                      Cashback&nbsp;<span css={commingSoonStyle}>Em breve</span>
                </CardItemRow>
                <CardItemRow css={cardIcon} to={redirectOnClick(`${REACT_APP_COUPONS_URL}`)}>
                      Cupons
                </CardItemRow>
              </CardItem>
            )
            : null}

          {(hasCompany && isFinancialEnabled)
            ? (
              <CardItem title="Financeiro" icon={CashIcon} customCss={[cardButton]}>
                <CardItemRow css={cardIcon} to={redirectOnClick('/app/financial')}>
                      Planos e taxas
                </CardItemRow>
              </CardItem>
            )
            : null
          }

          <NavLink to={hasCompany ? '/app/company/edit' : '/app/company/create'} css={[resetLink, cardButton]}>
            <CardButton css={cardButton}>
              <CompanyIcon css={cardIcon} />
              <Label css={labelCardButton}>Empresa</Label>
            </CardButton>
          </NavLink>

          <CardButton onClick={redirectOnClick('/app/users')} css={[clickable, cardButton]}>
            <UsersIcon css={[cardIcon, smallIcons]} />
            <Label css={labelCardButton}>Usuários</Label>
          </CardButton>

          <CardButton onClick={redirectOnClick('/app/alerts')} css={[clickable, cardButton]}>
            <AlertsIcon css={cardIcon} />
            <Label css={labelCardButton}>Alertas</Label>
          </CardButton>

          {hasCompany && (
            <NavLink to="/app/integrations" css={resetLink}>
              <CardButton css={cardButton}>
                <KeyIcon css={integrationsCardIcon} />
                <Label css={labelCardButton}>Integrações</Label>
              </CardButton>
            </NavLink>
          )}

          <CardButton
            onClick={redirectOnClick(`${REACT_APP_XPERT_URL}/app/documents/purchasing-policy`)}
            css={[clickable, cardButton]}
          >
            <img src={legalPaperIcon} css={cardIcon} alt="icon for documents" />
            <Label css={labelCardButton}>Documentação</Label>
          </CardButton>

          {hasCompany && (
            <NavLink to="/app/company/checkin-settings" css={resetLink}>
              <CardButton css={cardButton}>
                <CheckinIcon css={[integrationsCardIcon, smallIcons]} />
                <Label css={labelCardButton}>Check-in</Label>
              </CardButton>
            </NavLink>
          )}

          {
            isAffiliatesButtonEnabled
              ? (
                <CardButton
                  onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/affiliates`)}
                  css={[clickable, cardButton]}
                >
                  <HandshakeIcon css={cardIcon} alt="icon for affiliates" />
                  <Label css={labelCardButton}>Afiliados</Label>
                </CardButton>
              )
              : null
          }
        </Column>

        <DashboardProducts hasCompany={hasCompany} />
      </Row>
    </>
  )
}
