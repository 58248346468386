import { string } from 'yup'

export const contactEmailRules = {
  title: [
    {
      method: 'isEmpty',
      message: 'O nome do contato é obrigatório',
      expects: false
    }
  ],
  address: [
    {
      method: 'isEmpty',
      message: 'O email do contato é obrigatório',
      expects: false
    },
    {
      method: 'isEmail',
      message: 'O email deve ser válido'
    }
  ]
}

export const contactEmailSchema = {
  title: string().required('O nome do contato é obrigatório'),
  address: string()
    .required('O email do contato é obrigatório')
    .email('O email deve ser válido')
}
