import { string, boolean } from 'yup'

const validateTermsLink = (value) => {
  if (!value) return true

  // eslint-disable-next-line prefer-regex-literals
  const rePattern = new RegExp(/^(http|https):\/\/([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/.*)?$|^(www\.)([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/.*)?$/)

  const isValid = rePattern.test(value)

  return isValid
}

export const CheckinSettingSchema = {
  isEnabled: boolean().typeError('A habilitação só pode ser feita com verdadeiro ou falso.'),
  termsLink: string().typeError('O link só pode ser um texto.').test('isValidLink', 'Coloque um link válido.', validateTermsLink),
  region: string().typeError('A região só pode ser um texto.').required('Preencha com alguma região.')
}
