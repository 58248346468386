/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { NavContainer, NavItem, TabContainer, TabPane, HeaderPage } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'Shared/hooks/useQuery'
import { ExpandRegistrations } from './ExpandRegistrations'
import { ExpandConfig } from './ExpandConfig'
import { useExpandIntegration } from '../hooks/useExpandIntegration'
import { useCallback, useEffect, useMemo } from 'react'

const marginBottom20 = marginBottom(20)

export const Expand = () => {
  const { integration, loading, fetchIntegration, deleteActivity, onEditIntegration } = useExpandIntegration()
  const history = useHistory()
  const { selectedNav = 'registrations', changeQueryParam } = useQuery()
  const setSelectedNav = useCallback((value) => changeQueryParam('selectedNav', value), [changeQueryParam])
  const isVoucherUnico = useMemo(() => integration?.integrationName === 'Voucher Unico', [integration])
  const isTwilio = useMemo(() => integration?.integrationName === 'Twilio SMS', [integration])
  const redirectToList = () => history.push('/app/integrations')

  useEffect(() => {
    if (isTwilio && selectedNav === 'registrations') {
      setSelectedNav('config')
    }
  }, [isTwilio, selectedNav, setSelectedNav])

  return (
    <div css={marginBottom20}>
      <HeaderPage onBack={redirectToList} title={integration?.integrationName} />

      <NavContainer selectedNav={selectedNav} onChange={setSelectedNav}>
        {isTwilio ? [] : [<NavItem navId='registrations' key='registrations'>Vínculos</NavItem>]}
        <NavItem navId='config'>Configuração</NavItem>
      </NavContainer>
      <TabContainer activeTab={selectedNav}>
        <TabPane tabId='registrations'>
          <ExpandRegistrations integration={integration} loading={loading} deleteActivity={deleteActivity} />
        </TabPane>
        <TabPane tabId='config'>
          <ExpandConfig
            isVoucherUnico={isVoucherUnico}
            isTwilio={isTwilio}
            integrationId={integration?.id}
            auth={integration?.auth}
            fetchIntegration={fetchIntegration}
            onEdit={onEditIntegration}
            loading={loading}
          />
        </TabPane>
      </TabContainer>
    </div>
  )
}
