/* eslint-disable camelcase */
import { metaModel } from './meta'
import { formatISOTime } from 'Utils/time'

export const BinamikInvoiceModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      companyId: company_id,
      state,
      startDate: start_date,
      endDate: end_date,
      dueDate: due_date
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      company_id,
      state,
      start_date: start_date ? formatISOTime(start_date.toISOString(), 'YYYY-MM-DD HH:mm:ss') : undefined,
      end_date: end_date ? formatISOTime(end_date.toISOString(), 'YYYY-MM-DD HH:mm:ss') : undefined,
      due_date: due_date ? formatISOTime(due_date.toISOString(), 'YYYY-MM-DD HH:mm:ss') : undefined
    }

    return { searchParams, pagination: meta }
  }
}
