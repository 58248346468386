import { useState, useCallback } from 'react'
import { GeocodeApi } from 'Core/Api/Geocode'

const invalidAddressMessage = addToast => addToast('Não foi possível buscar a latitude e longitude, preencha corretamente o endereço')

export const useGeoCode = addToast => {
  const [latLng, setLatLng] = useState({})
  const onLatLngUpdate = useCallback(
    addressParam => {
      if (addressParam.replace(/\s/g, '')) {
        GeocodeApi.get(addressParam)
          .then(setLatLng)
          .catch(() => {
            invalidAddressMessage(addToast)
          })
      } else {
        invalidAddressMessage(addToast)
      }
    },
    [addToast]
  )
  return [latLng, onLatLngUpdate]
}
