import { css } from '@emotion/core'
import { colors, flexRowCentered, BREAK_POINTS } from '@bonitour/components'

export const clickable = css`
  cursor: pointer;
`

export const marginTop = margin => css`
  margin-top: ${margin}px;
`

export const marginBottom = margin => css`
  margin-bottom: ${margin}px;
`

export const marginLeft = margin => css`
  margin-left: ${margin}px;
`

export const marginRight = margin => css`
  margin-right: ${margin}px;
`

export const width = percentage => css`
  width: ${percentage}%;
`
export const resetLink = css`
  text-decoration: none;
`
export const hidden = css`
  display: none;
`
export const mobileCardContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8.5rem, 1fr));
  gap: 15px;
`

export const required = css`
  margin-left: 10px;
  color: ${colors.gray4};
  font-weight: 400;
  font-size: 14px;
`

export const loadingContainer = css`
  ${flexRowCentered};
  width: 100%;
  margin: 50px 0;
`
export const alignTrashIconAtEnd = css`
  justify-content: flex-end;


  @media (max-width: ${BREAK_POINTS.tableUp}) {
    position: absolute;
    top: 10px;
    right: 5px;
  }
`

export const loadingOverlay = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
`
