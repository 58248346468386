import { useCallback, useState } from 'react'
import { AffiliateService } from 'Core/Service/Affiliate/Service'
import { exportXlsx, exportCsv } from '@bonitour/common-functions'
import { XLSX_FORMAT } from '../constants/AffiliateSalesExport'
import { useToast } from '@bonitour/components'

const { getItemsToExport, getItemsToExportByCompany } = AffiliateService

export const useAffiliateSalesExport = ({ entityId, isCommonFetch }) => {
  const { add: addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const onExport = useCallback(
    ({ format, filters }) => {
      setIsLoading(true)

      const methodToFetchByType = isCommonFetch ? getItemsToExport : getItemsToExportByCompany
      const payloadByType = {
        ...(!isCommonFetch && { companyId: entityId }),
        ...filters
      }

      methodToFetchByType(entityId, format, payloadByType)
        .then((data) => {
          const FILENAME = 'itens-afiliado'
          if (format === XLSX_FORMAT) {
            exportXlsx(FILENAME, data)
          } else {
            exportCsv(FILENAME, data)
          }
        })
        .catch(() => addToast('Houve um erro inesperado'))
        .finally(() => setIsLoading(false))
    },
    [addToast, entityId, isCommonFetch]
  )

  return {
    isLoading,
    onExport
  }
}
