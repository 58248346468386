/* eslint-disable camelcase */
import { metaModel } from './meta'

export const UserModel = {
  list: (filters = {}, pagination = {}) => {
    const meta = metaModel(pagination)
    return { filters, pagination: meta }
  },
  roles: (roles = []) => {
    return {
      roles_ids: roles
    }
  }
}
