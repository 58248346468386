import { useState, useEffect, useCallback } from 'react'
import { PlanService } from 'Core/Service/Plan'
import { useCompany } from 'Shared/contexts/Company'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'Shared/hooks/usePermissions'

export const useFinancialPlans = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [selectedPlan, setSelectedPlan] = useState({})
  const [loading, setLoading] = useState(false)
  const { id: companyId } = useCompany()

  const fetchCompanyPlan = useCallback((companyId) => {
    setLoading(true)

    return PlanService.getById(companyId).then((data) => setSelectedPlan(data))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos planos e taxas')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchCompanyPlan(companyId)
  }, [companyId, fetchCompanyPlan])

  return {
    selectedPlan,
    setSelectedPlan,
    loading
  }
}
