/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Password, InputFormGroup, Input, ErrorTooltip, Checkbox } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { inputMargin, hideError, errorTooltip, errorTooltipHorizontalForm, checkboxContainer, checkbox } from 'Domains/Signup/Form/Form.style'
import { useCallback } from 'react'

export const UserInviteForm = ({
  userInvite = {},
  userInviteErrors = {},
  email,
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity
}) => {
  const { name = '', password = '', serviceTerms } = userInvite

  const handleChangeTermsConfirmation = useCallback((event) => {
    emitChangeEvent('serviceTerms')(event.target.checked)
  }, [emitChangeEvent])

  return (
    <>
      <InputFormGroup css={inputMargin} errorMessage={userInviteErrors.name} label='Nome Completo'>
        <Input name='name' value={name} onChange={emitChangeEvent('name')} onBlur={emitBlurEvent('name')} required />
      </InputFormGroup>

      <InputFormGroup css={inputMargin} label='Email'>
        <Input name='email' value={email} disabled />
      </InputFormGroup>

      <InputFormGroup css={inputMargin} errorMessage={userInviteErrors.password} label='Senha'>
        <Password
          name='password'
          value={password}
          onChange={emitChangeEvent('password')}
          onBlur={emitBlurEvent('password')}
          required
        />
      </InputFormGroup>

      <div css={checkboxContainer}>
        <ErrorTooltip css={[errorTooltip, errorTooltipHorizontalForm, hideError(serviceTerms || !userInviteErrors?.serviceTerms)]}>
          {userInviteErrors?.serviceTerms || ''}
        </ErrorTooltip>

        <Checkbox
          id='privacy_terms_checkbox'
          css={checkbox}
          name='service-terms'
          value={serviceTerms}
          onChange={handleChangeTermsConfirmation}
        />
        <label htmlFor='privacy_terms_checkbox'>
          <p>
            Declaro que li e concordo com os&nbsp;<wbr />
            <a href={`${process.env.REACT_APP_ACCOUNTS_URL}/termo-de-uso`} target='_blank' rel='noopener noreferrer'>Termos</a>
            <wbr />&nbsp;e&nbsp;<wbr />
            <a href={`${process.env.REACT_APP_ACCOUNTS_URL}/politica-de-privacidade`} target='_blank' rel='noopener noreferrer'>Políticas&nbsp;de&nbsp;Privacidade</a>
          </p>
        </label>
      </div>
    </>
  )
}
