import { useRef, useCallback, useState } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { EmployeesService } from 'Core/Service/Employee'
import { useToast } from '@bonitour/components'

export const useUserInvite = () => {
  const { add: addToast } = useToast()
  const userInviteReference = useRef()

  // eslint-disable-next-line no-unused-vars
  const [isInviteUserOpen, _setInviteUserContainer, openInviteUser, closeInviteUser] = useClickOutside(userInviteReference)

  const [isLoading, setLoadingStatus] = useState(false)

  const inviteUser = useCallback(async (payload) => {
    setLoadingStatus(true)
    try {
      await EmployeesService.inviteEmployee(payload)
      addToast('Convite enviado', 'success')
      closeInviteUser()
    } catch (error) {
      addToast('Ocorreu um erro ao enviar o convite')
      if (error.parsedErrors) {
        error.parsedErrors.map(errorMessage => addToast(errorMessage, 'error', 10000))
      }
      closeInviteUser()
    }
    setLoadingStatus(false)
  }, [closeInviteUser, addToast])

  return {
    userInviteReference,
    isInviteUserOpen,
    openInviteUser,
    closeInviteUser,
    inviteUser,
    isLoading
  }
}
