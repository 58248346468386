/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Dialog, Label } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { totalizerFieldModalStyle } from './InfoTotalizerModal.style'

export const InfoTotalizerModal = ({
  isVisible = false,
  title = '',
  content = '',
  onClose = identity
}) => {
  return <Dialog isVisible={isVisible} title={title} onClose={onClose} customContainercss={totalizerFieldModalStyle}>
    <div className='box'>
      <Label>{content}</Label>
    </div>
  </Dialog>
}
