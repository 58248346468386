/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { InfoSwatch } from '@bonitour/components'
import { totalizerFieldStyle } from './InfoTotalizerField.style'

export const InfoTotalizerField = ({ title, content, children, toggleModal }) => {
  const onClick = toggleModal({ title, content })
  return <p css={totalizerFieldStyle}>
    {children}
    <InfoSwatch onClick={onClick}/>
  </p>
}
