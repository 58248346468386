/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Terms } from '../Terms'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const CookiePolicy = () => {
  const { push } = useHistory()
  useEffect(() => {
    push('/politica-de-privacidade')
  }, [push])

  return (
    <Terms type='cookies_policy' />
  )
}
