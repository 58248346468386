import { head } from '@bonitour/common-functions'
import { AddressModel } from './Address'
import { ContactsModel } from './Contact'

const basicInfoModel = ({
  name,
  document,
  tradingName,
  stateRegistrationCode,
  municipalRegistrationCode,
  cadastur
}) => {
  return {
    document,
    name,
    trading_name: tradingName,
    state_registration: stateRegistrationCode,
    municipal_inscription: municipalRegistrationCode,
    cadastur
  }
}

export const CompanyModel = {
  create (data) {
    const { addressInfo = {}, phones = [], whatsapp = '', emails = [], businessEmail = '', website = '' } = data

    const basicInfo = basicInfoModel(data)
    const address = AddressModel(addressInfo)
    const whatsappNumber = whatsapp.length > 4 ? whatsapp : ''
    const contacts = ContactsModel({ emails, phones, whatsapp: whatsappNumber, businessEmail, website })

    const image = data?.files?.length ? head(data.files).file : null

    return {
      ...basicInfo,
      contacts,
      image,
      timezone: address.timezone,
      address_attributes: address
    }
  },
  company (data) {
    const { addressInfo = {}, phones = [], whatsapp = '', emails = [], businessEmail = '', website = '' } = data

    const basicInfo = basicInfoModel(data)
    const address = AddressModel(addressInfo)
    const whatsappNumber = whatsapp?.length > 4 ? whatsapp : ''
    const contacts = ContactsModel({ emails, phones, whatsapp: whatsappNumber, businessEmail, website })

    const image = data?.files?.length ? head(data.files).file : null

    return {
      ...basicInfo,
      contacts,
      image,
      timezone: address.timezone,
      address_attributes: address
    }
  }
}

export const CompanyCheckinModel = {
  create ({ region, termsLink, isEnabled }) {
    return {
      ally_region: region,
      checkin_enabled: isEnabled,
      ...(termsLink && { terms_link: termsLink })
    }
  },
  update ({ region = null, termsLink = null, isEnabled }) {
    return {
      ...(region && { ally_region: region }),
      checkin_enabled: isEnabled,
      terms_link: termsLink
    }
  }
}
