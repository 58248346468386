import { AffiliateService } from 'Core/Service/Affiliate/Service'
import { useToast } from '@bonitour/components'
import { useAuthorization } from 'Shared/contexts/Authorization'
import { useHistory, useParams } from 'react-router-dom'
import { useMemo, useState, useCallback, useEffect } from 'react'
import { useUser } from 'Shared/contexts/User'
import { AffiliateTicketStatus } from 'Domains/Affiliates/constants/AffiliateTicketStatus'

const DEFAULT_META = {
  page: 1,
  perPage: 10,
  totalPages: 1
}

export const useAffiliate = () => {
  const [meta, setMeta] = useState(DEFAULT_META)
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({ ticketStatus: AffiliateTicketStatus[0].value })
  const [salesData, setSalesData] = useState([])
  const [affiliate, setAffiliate] = useState(null)
  const [totalizerData, setTotalizerData] = useState({})
  const [batchTotals, setBatchTotals] = useState(null)

  const { add: addToast } = useToast()
  const { logout } = useAuthorization()
  const { push } = useHistory()
  const { companyId } = useParams()
  const { user } = useUser()

  const affiliateId = useMemo(
    () => user?.affiliates_info?.find(({ company_id: id }) => companyId === id)?.affiliate_id,
    [companyId, user]
  )

  const goToAffiliateHome = useCallback(() => {
    push('/affiliate-app')
  }, [push])

  const getAffiliate = useCallback(() => {
    if (!affiliateId) {
      addToast('É necessário efetuar login novamente', 'error')
      logout()
      return
    }
    AffiliateService.get(affiliateId)
      .then(setAffiliate)
      .catch(() => {
        addToast('Erro ao buscar o afiliado', 'error')
        goToAffiliateHome()
      })
  }, [addToast, affiliateId, goToAffiliateHome, logout])

  const getTotalizers = useCallback(() => {
    AffiliateService.getTotalizersByCompany(affiliateId, {
      companyId
    })
      .then(setTotalizerData)
      .catch(() => {
        addToast('Ocorreu um erro ao buscar os totais', 'error')
      })
  }, [addToast, affiliateId, companyId])

  const getSalesByCompany = useCallback(
    ({ page, perPage, filters }) => {
      if (affiliateId) {
        setIsLoading(true)
        AffiliateService.getSalesByCompany(affiliateId, {
          companyId,
          page,
          perPage,
          ...filters
        })
          .then(({ affiliateSales, meta }) => {
            setSalesData(affiliateSales)
            setMeta(meta)
          })
          .catch(() => {
            addToast('Ocorreu um erro ao buscar as vendas', 'error')
          })
          .finally(() => setIsLoading(false))
      }
    },
    [addToast, affiliateId, companyId]
  )

  const getBatchTotals = useCallback((affiliateId) => {
    setIsLoading(true)
    AffiliateService.getBatchTotalsByAffiliate(affiliateId)
      .then(setBatchTotals)
      .catch(() => addToast('Erro ao buscar os totalizadores de lote'))
      .finally(() => setIsLoading(false))
  }, [addToast])

  const handlePagination = useCallback(
    (page, perPage) => {
      if (!isLoading) {
        setMeta((previous) => {
          return {
            ...previous,
            page,
            perPage
          }
        })
        getSalesByCompany({
          page,
          perPage,
          filters
        })
      }
    },
    [filters, getSalesByCompany, isLoading]
  )

  const onPageChange = useCallback((page) => handlePagination(page, meta.perPage), [handlePagination, meta.perPage])

  const onPerPageChange = useCallback((perPage) => handlePagination(DEFAULT_META.page, perPage), [handlePagination])

  const onFilterSubmit = useCallback((newFilters = {}) => {
    setFilters(newFilters)
    setMeta(DEFAULT_META)
    getSalesByCompany({
      page: DEFAULT_META.page,
      perPage: DEFAULT_META.perPage,
      filters: newFilters
    })
  }, [getSalesByCompany])

  useEffect(() => {
    if (affiliateId) {
      getAffiliate()
      getTotalizers()
      getBatchTotals(affiliateId)
    }

    getSalesByCompany({ page: meta?.page, perPage: meta?.perPage, companyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    getSalesByCompany,
    meta,
    salesData,
    getAffiliate,
    affiliate,
    goToAffiliateHome,
    totalizerData,
    getTotalizers,
    isLoading,
    filters,
    onFilterSubmit,
    onPageChange,
    onPerPageChange,
    batchTotals
  }
}
