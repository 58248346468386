export const AlertParser = {
  single: (alert) => {
    const {
      id,
      alert_type: alertType,
      active,
      external_contacts: externalContacts,
      employees_ids: employeesIds
    } = alert || {}

    const { emails = [] } = externalContacts || {}

    return {
      id,
      alertType,
      active,
      externalContacts: emails,
      employeesIds
    }
  },
  list: ({ alert_configurations: alerts = [] }) => {
    return {
      alerts: alerts.map(AlertParser.single)
    }
  }
}
