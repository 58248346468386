/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer, HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { usePaginatedAllUsers } from './hooks/usePaginatedAllUsers'
import { marginBottom } from 'assets/styles/global'
import { AllUsersTable } from './AllUsersTable'

const marginBottom30 = marginBottom(30)

export const AllUsersList = () => {
  const { usersInfo, paginateUsers } = usePaginatedAllUsers()
  const { push } = useHistory()

  const backToDashboard = () => push('/app')

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Usuários cadastrados' />

      <Card css={marginBottom30}>
        <PaginationContainer total={usersInfo.total} pagesQuantity={usersInfo.totalPages} onPagination={paginateUsers}>
          <AllUsersTable users={usersInfo.users} />
        </PaginationContainer>
      </Card>
    </>
  )
}
