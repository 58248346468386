import { useState, useCallback, useEffect } from 'react'
import { UserService } from 'Core/Service/User'

export const usePaginatedAllUsers = () => {
  const [usersInfo, setUsers] = useState({})

  const paginateUsers = useCallback((currentPage = 1, pageLimit = 10) => {
    UserService.getPaginatedAllList(currentPage, pageLimit).then(setUsers)
  }, [])

  useEffect(() => {
    paginateUsers()
  }, [paginateUsers])

  return {
    usersInfo,
    paginateUsers
  }
}
