/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { LogoutIcon, UsersIcon, CompanyIcon, flexColumn, flexRowCentered, colors, AccountIcon } from '@bonitour/components'
import { NavLink } from 'react-router-dom'
import { useAuthorization } from 'Shared/contexts/Authorization'

const overflowLink = css`
  text-decoration: none;
`

const flexStart = css`
  align-items: flex-start;
`

const itemStyle = css`
  margin-top: 10px;
  font-weight: 800;
  color: ${colors.gray5};
  cursor: pointer;
`

const iconStyle = css`
  margin-right: 10px;
  font-size: 24px;
  min-width: 26px;
  text-align: center;

  .b-font-logout {
    transform: scale(1.15) translateX(1px);
    -webkit-text-stroke: 0.05px;
  }

  .b-font-users {
    transform: scale(1.25) translateY(1px);
    -webkit-text-stroke: 0.15px;
  }

  .b-font-account {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border: 1px solid ${colors.gray5};
      border-radius: 1px;
      position: absolute;
      top: 2px;
      right: 1px;
      transform: rotate(10deg);
      border-top: none;
      border-left: none;
      box-shadow: 3px 3px 0px 0px ${colors.white};
    }
  }
`

export const ShortcutItem = ({ icon: Icon, label, ...other }) => (
  <div css={[flexRowCentered, itemStyle]} {...other}>
    <Icon css={iconStyle} />
    <span>{label}</span>
  </div>
)

const UserLinkRow = ({ to = '', children, icon: Icon = null }) => (
  <NavLink to={to} css={overflowLink}>
    <ShortcutItem icon={Icon} label={children} />
  </NavLink>
)

export const Shortcuts = ({ affiliate = false }) => {
  const { logout } = useAuthorization()

  return (
    <div css={[flexColumn, flexStart]}>
      {!affiliate && (
        <>
          <UserLinkRow icon={CompanyIcon} to='/app/company/edit'>Empresa</UserLinkRow>
          <UserLinkRow icon={UsersIcon} to='/app/users'>Usuários</UserLinkRow>
        </>
      )}
      <UserLinkRow icon={AccountIcon} to='/auth/profiles?noAutoSelect=true'>
        Alterar perfil
      </UserLinkRow>
      <ShortcutItem onClick={logout} icon={LogoutIcon} label='Sair' />
    </div>
  )
}
