import { useRef, useEffect, useCallback } from 'react'

export function InfiniteScroll ({ loading, nextPage, scrollElement = window }) {
  const ref = useRef(null)
  const handleScroll = useCallback(() => {
    if (
      !loading &&
      (scrollElement.innerHeight ?? scrollElement.clientHeight) + (scrollElement.pageYOffset ?? scrollElement.scrollTop) + 300 >=
      (scrollElement.scrollHeight ?? document.body.offsetHeight)
    ) {
      clearTimeout(ref.current)
      ref.current = setTimeout(nextPage, 300)
    }
  }, [loading, scrollElement.innerHeight, scrollElement.clientHeight, scrollElement.pageYOffset, scrollElement.scrollTop, scrollElement.scrollHeight, nextPage])

  useEffect(() => {
    handleScroll()
    scrollElement.addEventListener('resize', handleScroll)
    scrollElement.addEventListener('scroll', handleScroll)
    return () => {
      clearTimeout(ref.current)
      scrollElement.removeEventListener('scroll', handleScroll)
      scrollElement.removeEventListener('resize', handleScroll)
    }
  }, [handleScroll, scrollElement])

  return null
}
