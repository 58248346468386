/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  flexCenteredSpaceBetweenRow
} from '@bonitour/components'
import {
  container,
  bold,
  amountDueContainer,
  amountDueLabel,
  amountDueStyle
} from './PaymentsValues.style'
import { marginBottom } from 'assets/styles/global'
import { formatMoney } from '@bonitour/common-functions'

const marginBottom20 = marginBottom(20)

export const PaymentsValues = ({
  totalAmount = 0,
  receivedAmount = 0,
  pendingAmount = 0,
  firstLabel = '',
  secondLabel = ''
}) => {
  return (
    <div css={container}>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>{firstLabel}</span>
        <span css={bold}>{formatMoney(totalAmount)}
        </span>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>{secondLabel}</span>
        <span css={bold}>{formatMoney(receivedAmount)}
        </span>
      </div>

      <Card css={[amountDueContainer, marginBottom20]}>
        <p css={amountDueLabel}>Total pendente</p>
        <span css={[amountDueStyle, bold]}>{formatMoney(pendingAmount)}
        </span>
      </Card>
    </div>
  )
}
