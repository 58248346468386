import { useToast } from '@bonitour/components'

export const usePermissions = () => {
  const { add: addToast } = useToast()

  const handlePermission = (error, permissionDeniedMessage = 'Você não possui permissão de acesso') => {
    if (error && error.status === 403) {
      permissionDeniedMessage && addToast(permissionDeniedMessage)
      return false
    }
    return true
  }

  return {
    handlePermission
  }
}
