import { AddressParser } from './Address'
import { buildImageUrl } from './Orb'
import { unmaskPhone } from '@bonitour/common-functions'

const companyBasicInfoParser = (company = {}) => {
  return {
    name: company.name || '',
    document: company.document || '',
    tradingName: company.trading_name || '',
    stateRegistrationCode: company.state_registration || '',
    municipalRegistrationCode: company.municipal_inscription || '',
    cadastur: company.cadastur || ''
  }
}

export const CompanyParser = {
  listUserCompanies ({ companies = [] }) {
    return companies.map((company) => ({
      id: company.id,
      name: company.name,
      image: buildImageUrl(company.image)
    }))
  },
  phone (phone = {}) {
    const { title, number } = phone
    const telephone = unmaskPhone(number || '')
    const phoneNumberWithDDI = telephone?.length <= 11 ? `55${telephone}` : telephone

    return {
      title,
      number: phoneNumberWithDDI
    }
  },
  company (company = {}) {
    const companyRegistry = companyBasicInfoParser(company)
    const addressInfo = AddressParser(company)
    const {
      contacts: { emails, main_email: businessEmail, phones, website, whatsapp },
      image,
      id
    } = company
    const imageUrl = buildImageUrl(image)
    const telephones = phones.map((phone) => CompanyParser.phone(phone))
    const whatsappNumber = unmaskPhone(whatsapp || '')
    const whatsappNumberWithDDI = whatsappNumber?.length <= 10 ? `55${whatsappNumber}` : whatsappNumber
    const files = image ? [{ id: image, src: imageUrl }] : []

    return {
      id,
      image: imageUrl,
      files,
      addressInfo,
      emails,
      businessEmail,
      phones: telephones,
      website,
      whatsapp: whatsappNumberWithDDI,
      ...companyRegistry
    }
  },
  allCheckinSettings ({ checkin_settings }) {
    return checkin_settings.map((term) => {
      return {
        id: term.id,
        companyId: term.company_id,
        region: term.ally_region,
        termsLink: term.terms_link,
        isEnabled: term.checkin_enabled
      }
    })
  },
  allyRegions ({ ally_regions }) {
    return ally_regions.map((region) => {
      return {
        value: region.id,
        label: String(region.value).charAt(0).toUpperCase() + String(region.value).slice(1)
      }
    })
  },
  checkinSettings ({ checkin_settings }) {
    return {
      id: checkin_settings.id,
      companyId: checkin_settings.company_id,
      region: checkin_settings.ally_region,
      isEnabled: checkin_settings.checkin_enabled,
      termsLink: checkin_settings.terms_link || ''
    }
  }
}
