/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Dialog, H4, InfoSwatch } from '@bonitour/components'

import { comissionLabel, comissionDetailItem, dialogCss } from './AffiliateComissionDetails.style'
import { useCallback, useMemo, useState } from 'react'
import { useActivity } from 'Shared/contexts/Activity'

export const ComissionDetails = ({ percentageByService = {}, percentage = 0, servicesInfo = {} }) => {
  const comissions = useMemo(
    () => [percentage || 0, ...Object.values(percentageByService || {})].map(parseFloat),
    [percentage, percentageByService]
  )

  const comissionRangeValue = useMemo(() => {
    const min = Math.min(...comissions).toLocaleString('pt-BR')
    const max = Math.max(...comissions).toLocaleString('pt-BR')
    return min === max ? `${min}%` : `${min}% - ${max}%`
  }, [comissions])

  const [isComissionDetailsVisible, setComissionDetailsVisible] = useState(false)
  const toggleComissionDetails = useCallback(
    (e) => {
      e.stopPropagation()
      setComissionDetailsVisible(v => !v)
    },
    []
  )

  const { companyActivities = [], partnerActivities = [] } = useActivity()
  const services = useMemo(
    () => companyActivities.concat(partnerActivities),
    [companyActivities, partnerActivities]
  )

  const getServiceName = useCallback(
    serviceId => {
      const service = services.find(({ id }) => id === serviceId)
      return <>
        <H4>{service?.name || servicesInfo[serviceId] || `Experiência ${serviceId?.split('-')[0]}`}</H4>
        <h5>{service?.companyName || ''}</h5>
      </>
    },
    [services, servicesInfo]
  )

  return (
    <>
      <p css={comissionLabel} onClick={toggleComissionDetails}>
        {comissionRangeValue}{' '}
        <InfoSwatch/>
      </p>
      <Dialog
        title='Detalhes da comissão'
        isVisible={isComissionDetailsVisible}
        onClose={toggleComissionDetails}
        customContainercss={dialogCss}
      >
        <ul>
          <li css={comissionDetailItem}>
            <H4>Comissão padrão</H4>
            <p>{parseFloat(percentage).toLocaleString('pt-BR')}%</p>
          </li>
          {Object.entries(percentageByService || {}).map(([service, percentage]) => (
            <li key={service} css={comissionDetailItem}>
              {getServiceName(service)}
              <p>{parseFloat(percentage).toLocaleString('pt-BR')}%</p>
            </li>
          ))}
        </ul>
      </Dialog>
    </>
  )
}
