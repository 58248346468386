import React, { useCallback, useEffect, useMemo } from 'react'
import { useCheckinSettings } from '../hooks/useCheckinSettings'
import CheckinTable from './CheckinTable'
import { CardButton } from 'Shared/components/CardButton'
import { Label, PlusIcon, HeaderPage, H2 } from '@bonitour/components'
import { cardButton, cardIcon, labelCardButton } from './style'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const ListCheckinSettingsPage = () => {
  const { fetchSettings, isLoading, settings, regions, fetchRegions, deleteSetting } = useCheckinSettings()

  const regionList = useMemo(() => settings.map((setting) => setting.region), [settings])

  const regionsOccuped = useMemo(() => settings.map((checkinSetting) => checkinSetting.region), [settings])

  const { push } = useHistory()

  const backToDashboard = useCallback(() => push('/app'), [push])

  const goToCreate = useCallback(
    () => !isLoading && push(`/app/company/checkin-settings/create?regionsOccuped=${regionsOccuped.join(',')}`),
    [isLoading, push, regionsOccuped]
  )

  useEffect(() => {
    fetchSettings()
    fetchRegions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title={'Configurações de Check-in'} />
      <div>
        <CardButton css={cardButton} onClick={goToCreate}>
          <PlusIcon css={cardIcon} />
          <Label css={labelCardButton}>Nova configuração</Label>
        </CardButton>

        <H2>Configurações cadastradas</H2>
        <CheckinTable
          isLoading={isLoading}
          settings={settings}
          regions={regions}
          deleteSetting={deleteSetting}
          regionList={regionList}
        />
      </div>
    </>
  )
}
