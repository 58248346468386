import { css } from '@emotion/core'
import { marginBottom, marginTop } from 'assets/styles/global'

export const marginTop50 = marginTop(50)
export const marginBottom20 = marginBottom(20)
export const marginBottom30 = marginBottom(30)

export const infoIcon = css`
  margin-left: 7px;
  width: 20px;
  height: 20px;
`

export const inputContainer = css`
  display: flex;
`

export const inputStyle = css`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

export const phoneInput = css`
  width: 100%;
`
