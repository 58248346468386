import { metaModel } from './meta'
import { removeEmptyAttribute } from '@bonitour/common-functions'

export const IntegrationModel = {
  /* eslint-disable camelcase */
  single: (integration = {}) => {
    const {
      integration: integration_name,
      companyId: company_id,
      companyName: company_name,
      login,
      password,
      token,
      cnpj,
      phone
    } = integration

    return {
      integration_name,
      company_id,
      external_id: cnpj || phone,
      integration_authentication: {
        token,
        company_name,
        login,
        password
      }
    }
  },
  list: ({ pagination }) => {
    const params = [
      metaModel(pagination)
    ]

    return params
  },
  link: (integrationLink = {}) => {
    const {
      id: service_id,
      code: external_service_id,
      active
    } = integrationLink

    return {
      service_id,
      external_service_id,
      active
    }
  },
  update: (integration = {}) => {
    const { login, password, companyName, cnpj, token, phone } = integration

    const auth = {
      login,
      password,
      company_name: companyName,
      token
    }

    const params = {
      external_id: cnpj || phone,
      integration_authentication: {
        ...removeEmptyAttribute(auth)
      }
    }

    return params
  }
}
