import { useCallback, useEffect, useMemo, useState } from 'react'
import { BinamikInvoiceService } from 'Core/Service/BinamikInvoice'
import { useToast } from '@bonitour/components'

export const useBinamikInvoiceList = ({ companyId } = {}) => {
  const { add: addToast } = useToast()
  const filters = useMemo(() => ({ companyId }), [companyId])
  const [invoicesData, setInvoicesData] = useState({ binamikInvoices: undefined, meta: undefined })
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })

  const fetchInvoices = useCallback(({ filters, pagination }) => {
    setLoading(true)

    BinamikInvoiceService.list({
      filters,
      pagination
    })
      .then(setInvoicesData)
      .catch(() => {
        addToast('Erro inesperado')
      })
      .finally(() => {
        setLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchInvoices({
      filters,
      pagination
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination])

  const handlePagination = useCallback((currentPage = 1, perPage = 10) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [pagination.currentPage, pagination.perPage])

  return {
    pagination,
    handlePagination,
    invoices: invoicesData?.binamikInvoices,
    meta: invoicesData?.meta,
    loading
  }
}
