const hjid = 2773979
const hjsv = 6

export function addHotjar (h, o, t, j, a, r) {
  h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
  h._hjSettings = { hjid, hjsv }
  a = o.getElementsByTagName('head')[0]
  r = o.createElement('script'); r.async = 1
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  a.appendChild(r)
}
