import { GeocodeService, GeocodeApiKey } from 'Core/Service/Geocode'
import { GeocodeParser } from 'Core/Parser/Geocode'

function Api () {
  function get (address = '') {
    const geocodeResource = `json?address=${address}&key=${GeocodeApiKey}`
    return GeocodeService.get(geocodeResource).then(({ data }) => {
      if (data.results.length > 1) {
        throw new Error('Invalid address')
      }
      return GeocodeParser(data)
    })
  }

  return { get }
}

export const GeocodeApi = Api()
