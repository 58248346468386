/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CircleThumbnail } from '@bonitour/components'
import { card, imageContainer, title, resetLink } from './IntegrationCard.style'
import { getFirstLetter } from 'Shared/utils/getFirstLetter'
import { NavLink } from 'react-router-dom'

export const IntegrationCard = ({ integration }) => {
  const { id, integrationName } = integration
  const integrationExpandUrl = `/app/integrations/${id}`
  const integrationInitialName = getFirstLetter(integrationName)

  return (
    <NavLink to={integrationExpandUrl} css={[resetLink, card]}>
      <CircleThumbnail size={100} css={imageContainer}>
        {integrationInitialName}
      </CircleThumbnail>
      <div css={title}>{integrationName}</div>
    </NavLink>
  )
}
