import React, { useCallback, useState } from 'react'
import {
  PencilIcon,
  TableBodyCell,
  TooltipOnHover,
  TrashIcon,
  Modal,
  Button,
  Card,
  flex,
  flexCenteredSpaceBetweenRow
} from '@bonitour/components'
import { Row, closeModalIcon, deleteModalTitle, sizeIcon, sizeTooltip } from './style'
import { clickable, marginRight } from 'assets/styles/global'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { backButton, minWidthButton } from '../styles'

const DeleteModal = ({ isVisible = false, onClose = () => {}, onSuccess = () => {} }) => {
  return (
    <Modal
      title="Deletar configuração"
      isVisible={isVisible}
      onCloseClick={onClose}
      customTitleCss={[deleteModalTitle]}
      customCloseIconCss={[closeModalIcon]}
    >
      <Card css={[flex, flexCenteredSpaceBetweenRow]}>
        <Button css={[backButton, minWidthButton]} onClick={onClose}>
          Voltar
        </Button>
        <Button onClick={onSuccess} css={minWidthButton}>
          Deletar
        </Button>
      </Card>
    </Modal>
  )
}

export const TableBodyCellAction = ({ setting, deleteSetting = () => {}, regionList = [] }) => {
  const { push } = useHistory()

  const [isEnabledDeleteModal, setIsEnabledDeleteModal] = useState(false)
  const toggleDeleteModal = useCallback(() => setIsEnabledDeleteModal((current) => !current), [setIsEnabledDeleteModal])

  const onSuccessDelete = useCallback(() => {
    toggleDeleteModal(false)
    deleteSetting(setting.id)
  }, [deleteSetting, setting.id, toggleDeleteModal])

  const goToEdit = useCallback(
    () => push(`/app/company/checkin-settings/edit/${setting.id}?regionsOccuped=${regionList}`),
    [push, regionList, setting.id]
  )

  return (
    <TableBodyCell>
      <Row>
        <TooltipOnHover text={'Editar configuração'} css={[sizeTooltip, marginRight(10)]}>
          <PencilIcon css={[clickable, sizeIcon]} onClick={goToEdit} />
        </TooltipOnHover>
        <TooltipOnHover text={'Deletar configuração'} css={sizeTooltip}>
          <TrashIcon css={[clickable, sizeIcon]} onClick={toggleDeleteModal} />
          <DeleteModal isVisible={isEnabledDeleteModal} onClose={toggleDeleteModal} onSuccess={onSuccessDelete} />
        </TooltipOnHover>
      </Row>
    </TableBodyCell>
  )
}
