import { useCallback, useEffect, useState, useMemo } from 'react'
import { BinamikInvoiceService } from 'Core/Service/BinamikInvoice'
import { useToast } from '@bonitour/components'

export const useBinamikInvoiceDetail = ({ invoiceId: id }) => {
  const { add: addToast } = useToast()
  const [invoice, setInvoice] = useState({})
  const [loading, setLoading] = useState(false)

  const payInvoice = useCallback(() => {
    setLoading(true)
    BinamikInvoiceService.approvePayment(id)
      .catch(() => {
        addToast('Erro inesperado')
      })
      .finally(() => {
        setLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchInvoice = useCallback((invoiceId) => {
    setLoading(true)
    BinamikInvoiceService.getInvoiceById(invoiceId)
      .then(setInvoice)
      .catch(() => {
        addToast('Erro inesperado')
      })
      .finally(() => {
        setLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchInvoice(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payInvoice, id])

  const receivedAmount = useMemo(() => {
    return invoice.binamikItems?.reduce((sum, val) => sum + val.amount_received, 0) || 0
  }, [invoice])

  const pendingAmount = useMemo(() => {
    return invoice.monthlyValue - receivedAmount
  }, [invoice, receivedAmount])

  return {
    invoice,
    loading,
    onPaymentConfirmation: payInvoice,
    pendingAmount,
    receivedAmount,
    totalAmount: invoice.monthlyValue
  }
}
