import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const affiliateInviteContainer = css`
  display: flex;
  grid-template-columns: '1fr 1fr';
  justify-content: space-around;
  gap: 110px;
  margin: auto 0;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    grid-template-columns: unset;
    flex-direction: column;
    padding: 20px;
    gap: unset;
  }
`

export const closeDialogCss = css`
  display: none;
`

export const dialogCss = css` 
  max-width: 500px;
  color: ${colors.gray1};
  p {
    margin: 0.25rem 0 0.5rem;
  }
  button {
    margin: 0.75rem 0.75rem 0 0;
    &.primary * {
      color: ${colors.white};
    }
    > div {
      margin-top: 0;
    }
  }

`
