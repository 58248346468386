import { colors, BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'

export const totalizerValue = css`
  height: 100%;
  width: 100%;

  h5 {
    font-size: 12px;
    color: ${colors.gray4};
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    color: ${colors.gray2};
    font-weight: 700;
  }
  &.primary p {
    color: ${colors.primary};
  }

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  @media (min-width: ${BREAK_POINTS.tablet}) {
    width: 200px;
    &:not(:first-of-type) {
      border-left: 1px solid ${colors.gray7};
      padding-left: 20px;
    }
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`

export const totalizerSection = css`
  display: flex;
  box-sizing: border-box;
  align-items: stretch;
  flex-direction: column;
  gap: 15px;

  > div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &:first-of-type {
      width: auto;
    }
    &:last-of-type {
      width: max-content;
      justify-content: flex-start;
    }
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    > div {
      &:last-of-type {
        width: auto;
      }
    }
  }

  @media (min-width: ${BREAK_POINTS.tablet}) {
    > div {
      flex-direction: row;
    }
  }
  @media (min-width: ${BREAK_POINTS.desktop}) {
    flex-direction: column;
    > div {
      width: 250px;
      &:first-of-type {
        width: auto;
        justify-content: space-between;
      }
      &:last-of-type {
        justify-content: flex-start;
      }
    }
  }
`
