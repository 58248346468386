/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Loading, useToast } from '@bonitour/components'
import { ResetPasswordPage } from './Page'
import { useHistory, useParams } from 'react-router-dom'
import { AuthService } from 'Core/Service/Auth'
import { useMemo, useState } from 'react'

export const ResetPassword = () => {
  const { add: addToast } = useToast()
  const history = useHistory()
  const { token } = useParams()
  const resetPasswordForm = useMemo(() => ({ token }), [token])

  const [isLoading, setLoadingStatus] = useState(false)

  const onSuccess = async data => {
    setLoadingStatus(true)
    try {
      await AuthService.resetPassword(data)
      addToast('Senha alterada com sucesso', 'success')
      history.push('/auth/login')
    } catch (error) {
      addToast('Ocorreu um erro na alteração da senha')
      if (error.parsedErrors) {
        error.parsedErrors.map(errorMessage => addToast(errorMessage, 'error', 10000))
      }
    }
    setLoadingStatus(false)
  }

  const onValidationError = () => addToast('As senhas estão divergentes')

  return (
    <>
      <ResetPasswordPage
        resetPasswordForm={resetPasswordForm}
        onSuccess={onSuccess}
        onValidationError={onValidationError}
      />
      <Loading isLoading={isLoading} />
    </>
  )
}
