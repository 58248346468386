import { TimeZoneService } from 'Core/Service/TimeZone'
import { useCallback, useEffect, useState } from 'react'

const { list: listTimezones } = TimeZoneService

export const useTimeZoneOptions = ({ selectedCountry, countries }) => {
  const [timeZones, setTimeZones] = useState([])

  const refreshTimezones = useCallback(async () => {
    const countryInitials = countries.find(({ value }) => value === selectedCountry)?.initials

    const timezones = await listTimezones({ country_abbreviation: countryInitials })
    const timezonesOptions = timezones?.map(timezone => ({
      label: timezone.identifier,
      value: timezone.identifier
    }))

    setTimeZones(timezonesOptions)
  }, [countries, selectedCountry])

  useEffect(() => {
    if (!selectedCountry) {
      return setTimeZones([])
    }

    refreshTimezones()
  }, [refreshTimezones, selectedCountry])

  return {
    timeZones
  }
}
