import { colors, BREAK_POINTS } from '@bonitour/components'

import { css } from '@emotion/core'

export const actionsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  align-items: flex-start;

  .action__row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 5px 10px;
    font-size: 15px;
    color: ${colors.gray5};
    transition-duration: 400ms;
    cursor: pointer;

    i {
      font-size: 18px;
    }

    .action__label {
      margin: auto 0;
    }

    &:hover {
      color: ${colors.gray3};
    }
  }

  .action__disabled {
    user-select: none;
    transition-duration: 0ms;
    opacity: 0.8;
    cursor: default !important;
    &:hover {
      color: ${colors.gray5};
    }
  }
`

export const actionsRefContainer = (isOpen = false) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: ${isOpen ? colors.primary : colors.gray2};
  background-color: ${isOpen ? 'rgba(77, 77, 255, 0.2)' : 'transparent'};
  border-radius: var(--round-element-radius, 50%);
  cursor: pointer;

  &:hover {
    color: ${colors.primary};
    background-color: rgba(77, 77, 255, 0.2);
    border-radius: var(--round-element-radius, 50%);
  }

  .ellipsis__icon {
    font-size: 22px;
  }
`

export const affiliateActionsPopover = css`
  @media (max-width: ${BREAK_POINTS.tablet}) {
    display: none;
  }
`

export const popoverCloseButton = css`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  border: none;
  z-index: 1;
`
