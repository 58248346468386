/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingAnimation, useToast } from '@bonitour/components'

import { LoginPage } from './Page'
import { useAuthentication } from 'Shared/contexts/Authentication'
import { loadingCss } from 'Domains/AuthLayout/AuthLayout.style'
import { useState, useCallback } from 'react'

export const Login = () => {
  const { add: addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const {
    login,
    authKey
  } = useAuthentication()

  const onSuccess = useCallback(async data => {
    try {
      setIsLoading(true)
      await login(data)
    } catch (error) {
      addToast('Senha ou e-mail inválidos')
    } finally {
      setIsLoading(false)
    }
  }, [addToast, login])

  const onValidationError = () => addToast('Digite seu e-mail e senha')

  if (authKey) {
    return <LoadingAnimation customCss={[loadingCss]} />
  }

  return (
    <>
      <LoginPage onSuccess={onSuccess} onValidationError={onValidationError} isLoading={isLoading}/>
    </>
  )
}
