import { orbCore } from './Orb/Service'
import { EmployeeModel } from 'Core/Models/Employee'
import { EmployeeParser } from 'Core/Parser/Employee'
import { AuthParser } from 'Core/Parser/Auth'

const { employeesDomain, authDomain } = orbCore

export const EmployeesService = {
  getInvite (inviteId) {
    return employeesDomain.getInvite(inviteId).then(EmployeeParser.invite)
  },
  userInviteSignup (inviteInfo, payload) {
    return authDomain.userInviteSignup(EmployeeModel.signup(inviteInfo, payload))
      .then(AuthParser.payload)
  },
  inviteEmployee (payload = {}) {
    return employeesDomain.inviteEmployee(EmployeeModel.invitation(payload))
  }
}
