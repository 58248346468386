/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Card, H2, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { container, reduceHeaderPadding } from './ItemsTable.style'

export const ItemsTable = ({
  items,
  paidValueLabel = ''
}) => {
  return (
    <Card css={container}>
      <H2>
        Detalhamento
      </H2>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell css={reduceHeaderPadding}>ID do ticket</TableHeaderCell>
            <TableHeaderCell css={reduceHeaderPadding}>Valor total</TableHeaderCell>
            <TableHeaderCell css={reduceHeaderPadding}>{paidValueLabel}</TableHeaderCell>
            <TableHeaderCell css={reduceHeaderPadding}>Valor pendente</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody fallbackMessage='Sem pagamentos registrados'>
          {
            items?.map((item) => (
              <TableBodyRow key={item.id}>
                <TableBodyCell>
                  {item.ticket_id}
                </TableBodyCell>
                <TableBodyCell>{formatMoney(item.total_amount)}</TableBodyCell>
                <TableBodyCell>{formatMoney(item.amount_received)}</TableBodyCell>
                <TableBodyCell>{formatMoney(item.pending_amount)}</TableBodyCell>
              </TableBodyRow>
            ))
          }
        </TableBody>
      </TableContainer>
    </Card>
  )
}
