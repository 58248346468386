/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { identity } from '@bonitour/common-functions'
import { H1, Accordion, CollapsableSection } from '@bonitour/components'

import { CreateCompanyInfo } from './CreateCompanyInfo'
import { useCompany } from 'Shared/contexts/Company'
import { useEffect } from 'react'
import { marginBottom } from 'assets/styles/global'

const marginBottom5 = marginBottom(5)

const defaultCursor = css`
  cursor: default;
`

export const CreateCompany = () => {
  const { id } = useCompany()
  const history = useHistory()

  useEffect(() => {
    if (id) {
      history.push('/app/company/edit')
    }
  }, [id, history])

  return (
    <>
      <H1 css={marginBottom5}>Empresa</H1>
      <Accordion value={1} onClick={identity}>
        <CollapsableSection
          indicator={1}
          css={defaultCursor}
          title='Informações básicas'
          topics='Registro / Contato / Endereço'
        >
          <CreateCompanyInfo />
        </CollapsableSection>
      </Accordion>
    </>
  )
}
