/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingAnimation, useToast } from '@bonitour/components'

import { useAuthentication } from 'Shared/contexts/Authentication'
import { useFeatureFlag } from 'Shared/contexts/Feature'
import { loadingCss } from 'Domains/ProfileSelector/ProfileSelector.styles'
import { useEffect, useState } from 'react'
import { useQuery } from 'Shared/hooks/useQuery'
import { useHistory } from 'react-router-dom'
import { UserStore } from 'Core/Store/User'
import { useUser } from 'Shared/contexts/User'

const mapSocialLoginError = (error) => {
  switch (error.toLowerCase()) {
  case 'email+can\'t+be+blank':
    return 'Email é obrigatório'
  case 'access_denied':
    return 'Permissão negada'
  default:
    return error.replace(/[+_]/g, ' ')
  }
}

export const SocialLoginCallback = () => {
  const { oauthLogin } = useAuthentication()
  const {
    temp_token: tempToken,
    extra_data: extraData,
    error_msg: errorMsg,
    redirectUrl: updatedRedirectQuery,
    changeQueryParam
  } = useQuery()
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { user } = useUser()
  const [loginRequested, setLoginRequested] = useState(false)
  const [enableGoogleOAuth] = useFeatureFlag('google-oauth-login')

  useEffect(() => {
    if (extraData && !updatedRedirectQuery) {
      const { redirectUrl } = JSON.parse(extraData)
      if (redirectUrl) {
        changeQueryParam('redirectUrl', redirectUrl)
        return
      }
    }
    if (user?.id && !loginRequested) {
      setLoginRequested(true)
      UserStore.clear()
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
    const timeout = setTimeout(() => {
      addToast('Erro ao realizar login social: Tempo esgotado', 'error')
      push('/auth/login')
    }, 20_000)
    const cancelTimeout = () => clearTimeout(timeout)
    if (errorMsg) {
      addToast(`Erro ao realizar login social: ${mapSocialLoginError(errorMsg)}`, 'error')
      push('/auth/login')
    }
    if (!tempToken) {
      return cancelTimeout
    }
    if (!loginRequested && enableGoogleOAuth) {
      setLoginRequested(true)
      oauthLogin(tempToken)
        .catch(() => {
          addToast('Erro ao realizar login social: Token não recuperado', 'error')
          push('/auth/login')
        })
    }

    return cancelTimeout
  }, [addToast, changeQueryParam, enableGoogleOAuth, errorMsg, extraData, loginRequested, oauthLogin, push, tempToken, updatedRedirectQuery, user])

  return (
    <>
      <LoadingAnimation customCss={[loadingCss]} />
    </>
  )
}
