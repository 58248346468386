export const AffiliateTicketStatus = [
  {
    label: 'Todos',
    value: ''
  },
  {
    label: 'Pago',
    value: 'paid'
  },
  {
    label: 'Não pago',
    value: 'not_paid'
  }
]
