/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'

import { totalizerValue, totalizerSection } from './AffiliateCompanySalesTotalizer.style'
import { ComissionDetails } from 'Domains/Affiliates/AffiliateComissionDetails'
import { InfoTotalizerModal, InfoTotalizerField } from 'Shared/components/InfoTotalizer'
import { useCallback, useState } from 'react'

export const AffiliateCompanySalesTotalizer = ({ totalizerData, affiliate, children, batchTotals }) => {
  const [modalData, setModalData] = useState({ isVisible: false, title: '', content: '' })

  const toggleModal = useCallback((extraContent) => () => setModalData((curr) => ({
    isVisible: !curr.isVisible,
    ...(extraContent || {})
  })), [])

  return totalizerData && (
    <div css={totalizerSection} className='totalizer-section'>
      <Card padding={0}>
        <div css={totalizerValue} className={'primary'}>
          <h5>Total vendido</h5>
          <InfoTotalizerField content={'Total vendido em valor de ticket.'} title={'Total vendido'} toggleModal={toggleModal}>
            {formatMoney(totalizerData?.totalSold || 0)}
          </InfoTotalizerField>
        </div>
        <div css={totalizerValue}>
          <h5>Minha comissão</h5>
          <InfoTotalizerField content={'Total de comissão sobre todos os tickets vendidos.'} title={'Total de comissão geral'} toggleModal={toggleModal}>
            {formatMoney(totalizerData?.totalCommission || 0)}
          </InfoTotalizerField>
        </div>
        <div css={totalizerValue}>
          <h5>Disponível para receber</h5>
          <InfoTotalizerField title='Disponível para receber' content='Valor disponível para receber, referente a serviços realizados.' toggleModal={toggleModal}>
            {formatMoney(totalizerData?.totalAvailable || 0)}
          </InfoTotalizerField>
        </div>
        <div css={totalizerValue}>
          <h5>A liberar</h5>
          <InfoTotalizerField content={'Valor de comissão referente a serviços ainda não realizados.'} title={'A liberar'} toggleModal={toggleModal}>
            {formatMoney(totalizerData?.totalToRelease || 0)}
          </InfoTotalizerField>
        </div>
        <div css={totalizerValue}>
          <h5>Valor pago</h5>
          <InfoTotalizerField content={'Valor já recebido da empresa.'} title={'Valor pago'} toggleModal={toggleModal}>
            {formatMoney(batchTotals?.paid || 0)}
          </InfoTotalizerField>
        </div>
      </Card>
      <Card padding={0}>
        <div css={totalizerValue}>
          <h5>Número de vendas</h5>
          <InfoTotalizerField title={'Número de vendas'} content={'Quantidade total de tickets vendidos.'} toggleModal={toggleModal}>
            {totalizerData?.salesNumber || 0}
          </InfoTotalizerField>
        </div>
        <div css={totalizerValue}>
          <h5>Porcentagem de prêmio</h5>
          <ComissionDetails
            percentage={affiliate?.percentage}
            percentageByService={affiliate?.percentageByService}
            servicesInfo={affiliate?.servicesInfo}
          />
        </div>
      </Card>
      {children}
      <InfoTotalizerModal
        isVisible={modalData.isVisible}
        title={modalData.title}
        content={modalData.content}
        onClose={toggleModal(null)}
      />
    </div>
  )
}
