import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const affiliatesSearchIcon = css`
  font-size: 15px;
`

export const affiliatesPageContainer = css`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .list__card {
    width: 100%;
  }

  .list__card__row {
    align-items: end;
  }
`

export const filtersContainer = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  [class*='datePickerContainer'] {
    min-width: 250px;
  }

  .search__input {
    min-width: 314px;
  }

  .component-popover-arrow {
    right: 16px;
  }
  .component-popover {
    margin-right: -4px;
  }
`

export const cardButton = css`
  width: 100px;
  padding: 10px 20px;
`

export const cardIcon = css`
  width: 22px;
  height: 22px;
  padding: 5px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: var(--round-element-radius, 100%);
`

export const labelCardButton = css`
  width: 100%;
  cursor: pointer;
`

export const filterDrawerContainer = css`
  padding: 5px;

  h3,
  label {
    text-align: left;
  }
`

export const iconStyle = ({ hasFilters, isOpen }) => css`
  font-size: 25px;
  color: ${colors.gray6};
  padding: 10px;
  border-radius: var(--round-element-radius, 100vw);
  width: 40px;
  height: 40px;
  transition: background-color 0.25s, color 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin: 0;
  box-sizing: border-box;
  .b-font-filter {
    transform: translateY(2px);
  }

  ${isOpen &&
  `
  background-color: ${colors.primary};
  color: ${colors.white};
  `}

  .b-font-filter {
    ${hasFilters && 'transform: translateY(2px);'}
    ${hasFilters &&
  `
      &::after {
        width: 8px;
        height: 8px;
        border-radius: 25px;
        transform: translate(-5px, -2px);
        content: '';
        display: inline-block;
        position: absolute;
        background-color: ${colors.yellow2};
      }
    `}
  }

  &:hover {
    ${!isOpen && `background-color: ${colors.white};`}
    cursor: pointer;
  }
`

export const filterButton = css`
  background-color: transparent;
  border: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

export const buttonRow = css`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`
