/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Content, Header, Footer, Logo } from '@bonitour/components'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'
import { UserProvider } from 'Shared/contexts/User'
import { CompanyProvider } from 'Shared/contexts/Company'
import { AuthenticationProvider } from 'Shared/contexts/Authentication'
import { Feature } from 'Shared/contexts/Feature'
import {
  logo,
  container,
  formSubmitGroup,
  overrideBaseLayout,
  headerLogo
} from './AuthLayout.style'

export const AuthLayout = props => {
  return (
    <UserProvider>
      <CompanyProvider>
        <AuthenticationProvider>
          <Feature>
            <Header css={headerLogo}>
              <Logo css={logo} src={BinamikLogo} />
            </Header>
            <Content css={overrideBaseLayout}>
              <div css={container}>
                <div css={formSubmitGroup} {...props} />
              </div>
            </Content>
            <Footer />
          </Feature>
        </AuthenticationProvider>
      </CompanyProvider>
    </UserProvider>
  )
}
