/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, flexRowCentered, flexHorizontallyCentered, Input, TrashIcon, PhoneSelector } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { alignTrashIconAtEnd, clickable } from 'assets/styles/global'
import {
  width5,
  width95,
  iconSize,
  containedInput,
  sameLineInput,
  customCardPadding,
  stylishCard,
  fieldContainer,
  fieldSection
} from './ContactPhone.style'

export const ContactPhone = ({
  errors = [],
  onBlur: emitBlurEvent = identity,
  onDelete: emitDeleteEvent = identity,
  onChange: emitChangeEvent = identity,
  contact,
  index
}) => {
  const { title = '', number = '' } = contact
  return (
    <Card css={[customCardPadding, stylishCard]}>
      <div css={flexRowCentered}>
        <div css={[flexHorizontallyCentered, width95, fieldContainer]}>
          <div css={fieldSection}>
            <p>Contato</p>
            <div css={sameLineInput}>
              <Input
                value={title}
                css={containedInput}
                error={Boolean(errors.title)}
                onBlur={emitBlurEvent(`phones.${index}.title`)}
                onChange={emitChangeEvent(`phones.${index}.title`)}
                placeholder='Ex.Flávia Reservas'
              />
            </div>
          </div>
          <div css={fieldSection}>
            <p css={sameLineInput}>e o número é</p>
            <div css={sameLineInput}>
              <PhoneSelector
                value={number}
                error={Boolean(errors.number)}
                onBlur={emitBlurEvent(`phones.${index}.number`)}
                onChange={emitChangeEvent(`phones.${index}.number`)}
              />
            </div>
          </div>
        </div>
        <div css={[width5, alignTrashIconAtEnd, flexHorizontallyCentered]}>
          {Boolean(index) && <TrashIcon css={[iconSize, clickable]} onClick={emitDeleteEvent} />}
        </div>
      </div>
    </Card>
  )
}
