/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, HeaderPage, PaginationContainer } from '@bonitour/components'
import { jsx } from '@emotion/core'
import CompanyInvoiceTable from './CompanyInvoiceTable'
import { useBinamikInvoiceList } from 'Domains/Financial/BinamikInvoice/hooks/useBinamikInvoiceList'
import { useCompany } from 'Shared/contexts/Company'
import { useHistory } from 'react-router'

export const CompanyInvoiceList = () => {
  const { id: companyId } = useCompany()
  const { invoices, meta, handlePagination } = useBinamikInvoiceList({ companyId })
  const { total = 0, totalPages = 1 } = meta ?? {}
  const { push } = useHistory()

  const backToHome = () => push('/app')

  return (
    <>
      <HeaderPage onBack={backToHome} title='Histórico de Faturas' />

      <Card>
        <PaginationContainer
          total={total}
          pagesQuantity={totalPages}
          onPagination={handlePagination}
        >
          <CompanyInvoiceTable invoices={invoices} />
        </PaginationContainer>
      </Card>
    </>
  )
}
