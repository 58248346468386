/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { H2, Label, LoadingAnimation, PaginationBar } from '@bonitour/components'
import { UserCard } from './UserCard'
import { loadingContainer } from 'assets/styles/global'
import { useCallback, useMemo } from 'react'
import { container, notFoundContainer } from './PaginationUsers.style'

export const PaginationUsers = ({
  users = [],
  isLoading = false,
  loadingToggleActiveId,
  onPagination: emitPaginationEvent = identity,
  onClickUser: emitClickUser = identity,
  onClickToggleActive: emitClickToggleActive = identity,
  paginationData: { currentPage, perPage, pagesQuantity = 10, totalEntries = 1 }
}) => {
  const onPageChange = useCallback((newPage) => emitPaginationEvent(newPage), [emitPaginationEvent])
  const onPerPageChange = useCallback((newPerPage) => emitPaginationEvent(1, newPerPage), [emitPaginationEvent])
  const hasUsersData = useMemo(() => users.length > 0, [users])
  const isNotFoundMode = useMemo(() => !isLoading && !hasUsersData, [isLoading, hasUsersData])

  return (
    <>
      <div css={[container, isNotFoundMode && notFoundContainer]}>
        {isNotFoundMode && (
          <>
            <H2 className='not-found-label'>Não há informações</H2>
            <Label className='not-found-label'>Você não tem informações</Label>
          </>
        )}
        {isLoading && <LoadingAnimation css={loadingContainer} />}
        {!isLoading && users.map(({ id, name, image, roles, isActive }) => (
          <UserCard
            key={id}
            id={id}
            name={name}
            image={image}
            roles={roles}
            isActive={isActive}
            loadingToggleActiveId={loadingToggleActiveId}
            onClickToggleActive={emitClickToggleActive}
            onClickUser={emitClickUser}
            onPagination={emitPaginationEvent}
          />))}
      </div>
      <PaginationBar
        currentPage={currentPage}
        perPage={perPage}
        totalPages={pagesQuantity}
        totalElements={totalEntries}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
    </>
  )
}
