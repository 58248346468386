/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'

export const AllUsersTable = ({ users }) => {
  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Nome da empresa</TableHeaderCell>
          <TableHeaderCell>Usuário</TableHeaderCell>
          <TableHeaderCell>E-mail</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {users.map(({ id, companyName, userName, userEmail }) => (
          <TableBodyRow key={id}>
            <TableBodyCell>{companyName}</TableBodyCell>
            <TableBodyCell>{userName}</TableBodyCell>
            <TableBodyCell>{userEmail}</TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}
