/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { Button, Card, Checkbox, Input, InputFormGroup, P, PasswordWithToggle, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthentication } from 'Shared/contexts/Authentication'
import { affiliateInviteSchema } from './AffiliateWelcomeForm.schema'
import { checkBoxLabelStyle, formContainer, termsConfirmationContainer, unsetColor } from './AffiliateWelcomeForm.style'

const TermsConfirmationRow = ({ onChange = identity }) => {
  return (
    <div css={termsConfirmationContainer}>
      <Checkbox
        id='privacy_terms_checkbox'
        name='service-terms'
        onChange={onChange}
      />
      <P css={checkBoxLabelStyle}>Declaro que li e concordo com os <a css={unsetColor} target='_blank' rel='noopener noreferrer' href='https://binamik.com.br/termos-de-uso-viajante'>termos</a> e <a css={unsetColor} target='_blank' rel='noopener noreferrer' href='https://binamik.com.br/politica-de-privacidade'>política de privacidade</a></P>
    </div>
  )
}

export const AffiliateWelcomeForm = ({ affiliate = {}, token = '' }) => {
  const [termsConfirmation, setTermsConfirmation] = useState(false)
  const { userEmail } = affiliate
  const { add: addToast } = useToast()
  const history = useHistory()
  const { signupAffiliate } = useAuthentication()

  const affiliateInviteBaseSchema = useMemo(() => ({
    token,
    email: userEmail
  }), [token, userEmail])

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(affiliateInviteBaseSchema, affiliateInviteSchema)

  const handleChangeTermsConfirmation = useCallback(() => {
    setTermsConfirmation(v => !v)
  }, [])

  const handleSubmit = useCallback((formData) => {
    if (!termsConfirmation) {
      return addToast('Você precisa aceitar os termos e condições para continuar', 'error')
    }
    signupAffiliate({
      email: formData.email,
      name: formData.name,
      password: formData.password,
      token: formData.token
    }).then(() => {
      addToast('Convite aceito com sucesso', 'success')
      history.push('/auth/login')
    }).catch((err) => {
      if (typeof err.data === 'string' && err.data.toLowerCase().includes('password')) {
        return addToast('Senha incorreta. Utilize a senha já cadastrada!', 'error')
      }
      if (err.data?.errors_msg?.includes('ticket_seller::personal_info::email::taken')) {
        return addToast('O e-mail informado já está cadastrado', 'error')
      }
      addToast('Houve um erro ao tentar aceitar o convite', 'error')
    })
  }, [addToast, history, signupAffiliate, termsConfirmation])

  return (
    <div css={formContainer}>
      <P className='description___label'>Para aceitar o convite, preencha primeiro o formulário:</P>
      <P>Caso o email já esteja cadastrado na plataforma, um perfil de afiliado será vinculado à conta já existente.</P>
      <Card className='inputs__container'>
        <InputFormGroup errorMessage={errors.name} label='Nome completo'>
          <Input onChange={onInputChange('name')} onBlur={onInputBlur('name')} value={form.name} />
        </InputFormGroup>
        <InputFormGroup label='E-mail'>
          <Input value={form.email} disabled />
        </InputFormGroup>
        <InputFormGroup errorMessage={errors.password} label='Crie uma senha'>
          <PasswordWithToggle value={form.password} onChange={onInputChange('password')} onBlur={onInputBlur('password')} />
        </InputFormGroup>
        <InputFormGroup errorMessage={errors.passwordConfirmation} label='Confirme sua senha'>
          <PasswordWithToggle value={form.passwordConfirmation} onChange={onInputChange('passwordConfirmation')} onBlur={onInputBlur('passwordConfirmation')} />
        </InputFormGroup>
        <TermsConfirmationRow onChange={handleChangeTermsConfirmation} />
      </Card>
      <Button onClick={onSubmit(handleSubmit)} className='submit__button'>Aceitar convite</Button>
    </div>
  )
}
