import axios from 'axios'
import { AppStore } from 'Core/Store/App'
import { UserStore } from 'Core/Store/User'
import { initialize } from '@bonitour/orb-core'
import { identity } from '@bonitour/common-functions'
const { REACT_APP_API_HOST } = process.env

const NO_REDIRECT_AFTER_401 = [
  '/login',
  '/signup',
  '/affiliates_user_invite_signup',
  '/user_invite_signup'
]

export const parseAttribute = parser => ({ attributes }, index) => parser(attributes, index)

export const getImageUrl = image => image ? `${REACT_APP_API_HOST}${image}` : ''

export const OrbService = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json'
})

OrbService.interceptors.request.use(
  config => {
    if (UserStore.authKey && !config.headers.Authorization) {
      config.headers.Authorization = UserStore.authKey
    }

    return config
  },
  error => Promise.reject(error)
)

OrbService.interceptors.response.use(identity, error => {
  if (error.response && error.response.status === 401 && !NO_REDIRECT_AFTER_401.includes(error?.config?.url)) {
    AppStore.clean()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
  return Promise.reject(error)
})

export const extractData = ({ data }) => data

const interceptorFunction = (interceptor) => {
  interceptor.request.use(
    config => {
      if (UserStore.authKey && !config.headers.Authorization) {
        config.headers.Authorization = UserStore.authKey
      }
      return config
    },
    error => Promise.reject(error)
  )
  interceptor.response.use(identity, error => {
    if (error && error.status === 401 && !NO_REDIRECT_AFTER_401.includes(error?.config?.url)) {
      AppStore.clean()
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
    return Promise.reject(error)
  })
}

export const orbCore = initialize(
  process.env.REACT_APP_API_HOST,
  interceptorFunction
)
