/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  Card,
  colors,
  InputFormGroup,
  Input,
  InputWithPrefix,
  CircleThumbnail,
  Column,
  Row
} from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { invoiceStatusMap } from 'Shared/constants/invoiceStatus'

const width240 = css`
  width: 240px;
`

const fullWidthInput = css`
  width: 100%;
`

const container = css`
  color: ${colors.gray3};
  background-color: ${colors.gray13};
  border: none;
  margin-bottom: 40px;
`

export const BinamikInvoiceInfo = ({
  invoiceInfo = {},
  invoiceInfoErrors = {},
  onChange: emitChangeEvent = identity,
  isReadOnly = false
}) => {
  const {
    dueDate = '',
    state = '',
    value = 0,
    id = '',
    code = '',
    documentNumber = '',
    dischargeDate = '',
    endCompetence = '',
    startCompetence = ''
  } = invoiceInfo

  const [codeName, codeColor] = invoiceStatusMap[state] || invoiceStatusMap.open

  return (
    <Card css={container}>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='id' label='Id da empresa'>
            <Input value={id} readOnly />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='startCompetence' label='Início'>
            <Input value={startCompetence} readOnly />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='endCompetence' label='Final'>
            <Input value={endCompetence} readOnly />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='state' label='Status'>
            <InputWithPrefix readOnly value={codeName}>
              <CircleThumbnail size={10} style={{ backgroundColor: codeColor }} />
            </InputWithPrefix>
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={2.4}>
          <InputFormGroup css={width240} id='value' errorMessage={invoiceInfoErrors.value} label='Valor mensal'>
            <InputWithPrefix
              readOnly={isReadOnly}
              value={value}
              onChange={emitChangeEvent('info.value')}
              css={fullWidthInput}
            >
              R$
            </InputWithPrefix>
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2.4}>
          <InputFormGroup id='dueDate' errorMessage={invoiceInfoErrors.dueDate} label='Vencimento'>
            {/* TODO: fix datepicker readOnly input font color */}
            <Input value={dueDate} readOnly />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2.4}>
          <InputFormGroup id='dischargeDate' errorMessage={invoiceInfoErrors.dischargeDate} label='Data de quitação'>
            <Input
              readOnly={isReadOnly}
              value={dischargeDate}
              onChange={emitChangeEvent('info.dischargeDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2.4}>
          <InputFormGroup
            id='documentNumber'
            errorMessage={invoiceInfoErrors.documentNumber}
            label='Número da nota fiscal'
          >
            <Input readOnly={isReadOnly} value={documentNumber} onChange={emitChangeEvent('info.documentNumber')} />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2.4}>
          <InputFormGroup
            id='code'
            errorMessage={invoiceInfoErrors.code}
            label='Código'
          >
            <Input readOnly={isReadOnly} value={code} onChange={emitChangeEvent('info.code')} />
          </InputFormGroup>
        </Column>
      </Row>
    </Card>
  )
}
