const { REACT_APP_API_HOST } = process.env

export const activityIdParser = (activity = {}) => {
  const { title: name = '', image } = activity
  const files = image
    ? [
      {
        id: image,
        src: `${REACT_APP_API_HOST}${image}`
      }
    ]
    : []
  return { name, files }
}
