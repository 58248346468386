import { AppStore } from './App'

export const UserStore = {
  clear () {
    return AppStore.clean()
  },
  get authKey () {
    return AppStore.get('auth-key')
  },
  set authKey (authKey) {
    AppStore.set('auth-key', authKey)
  },
  get user () {
    try {
      return {
        ...(JSON.parse(AppStore.get('user')) || {}),
        affiliates_info: JSON.parse(AppStore.get('user.affiliates_info')) || {}
      }
    } catch (error) {
      return {}
    }
  },
  set user (info) {
    // store affiliates_info into separated cookie:
    const affiliatesString = JSON.stringify(info?.affiliates_info)
    AppStore.set('user.affiliates_info', affiliatesString)

    const infoString = JSON.stringify({
      ...info,
      // keep length accessible for other binamik apps:
      affiliates_info: new Array(info?.affiliates_info?.length)
    })
    AppStore.set('user', infoString)
  },
  get profileType () {
    return AppStore.get('profile-type')
  },
  set profileType (mode) {
    AppStore.set('profile-type', mode)
  }
}
