import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { AffiliateService } from 'Core/Service/Affiliate/Service'

export const useAffiliates = () => {
  const [meta, setMeta] = useState({
    page: 1,
    totalPages: 1,
    perPage: 10,
    totalEntries: 0
  })
  const [filters, setFilters] = useState({})
  const [affiliates, setAffiliates] = useState([])
  const [isInviteOpened, setIsInviteOpened] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [affiliateResendLoadingIds, setIsLoadingAffiliateReinvites] = useState([])

  const { add: addToast } = useToast()

  const toggleInvite = useCallback(() => setIsInviteOpened((current) => !current), [setIsInviteOpened])

  const browseAffiliates = useCallback(
    ({ page, perPage, name, status }) => {
      setIsLoading(true)
      AffiliateService.list({
        page,
        perPage,
        name,
        status
      })
        .then((resData) => {
          setAffiliates(resData.affiliates)
          setMeta(resData.meta)
        })
        .catch((err) => {
          const isForbidden = err?.status === 403

          const errorMessage = isForbidden ? 'Você não tem permissão para listar os afiliados' : 'Ocorreu um erro inesperado'

          addToast(errorMessage, 'error')
        })
        .finally(() => setIsLoading(false))
    },
    [addToast, setAffiliates, setMeta, setIsLoading]
  )

  const handlePageChange = useCallback(
    (page, perPage) => {
      if (isLoading || (meta.page === page && meta.perPage === perPage)) return

      setMeta((previous) => ({
        ...previous,
        page,
        perPage
      }))
      browseAffiliates({ page, perPage, ...filters })
    },
    [browseAffiliates, filters, isLoading, meta.page, meta.perPage]
  )

  const reloadAffiliates = useCallback(() => {
    browseAffiliates({ ...meta, ...filters })
  }, [browseAffiliates, filters, meta])

  const handleFilterChange = useCallback(
    (newFilters) => {
      setFilters(newFilters)
      browseAffiliates({ page: meta.page, perPage: meta.perPage, ...newFilters })
    },
    [browseAffiliates, meta.page, meta.perPage]
  )

  const reinviteAffiliate = useCallback(
    (affiliateId) => {
      const isLoading = affiliateResendLoadingIds.includes(affiliateId)

      if (isLoading) {
        return addToast('O convite já está sendo reenviado')
      }

      setIsLoadingAffiliateReinvites((prev) => [...prev, affiliateId])
      AffiliateService.reinviteAffiliate(affiliateId)
        .then(() => {
          addToast('Convite reenviado com sucesso', 'success')
        })
        .catch(() => {
          addToast('Ocorreu um erro ao reenviar o convite')
        })
        .finally(() => {
          setIsLoadingAffiliateReinvites((prev) => prev.filter((id) => id !== affiliateId))
        })
    },
    [addToast, affiliateResendLoadingIds]
  )

  useEffect(() => {
    browseAffiliates({ page: meta.page, perPage: meta.perPage, ...filters })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isInviteOpened,
    toggleInvite,
    affiliates,
    meta,
    browseAffiliates,
    handlePageChange,
    reloadAffiliates,
    isLoadingAffiliates: isLoading,
    handleFilterChange,
    reinviteAffiliate,
    affiliateResendLoadingIds
  }
}
