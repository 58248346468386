import { AffiliateLayout, Layout } from 'Domains/Layout/Layout'
import { ListUsers } from 'Domains/User/ListUser/ListUsers'
import { EditUser } from 'Domains/User/EditUser/EditUser'
import { Dashboard } from 'Domains/Dashboard/Dashboard'
import { CreateCompany } from 'Domains/Company/CreateCompany/CreateCompany'
import { EditCompany } from 'Domains/Company/EditCompany/EditCompany'
import { Integrations, Expand, RegisterActivity } from 'Domains/Integrations'
import {
  Plans,
  CompanyInvoiceDetails,
  CompanyInvoiceList,
  BinamikInvoiceDetails,
  BinamikInvoiceList
} from 'Domains/Financial'
import { AllUsersList } from 'Domains/User/Binamik/AllUsersList'
import { CompanyRole } from 'Domains/User/CompanyRole/CompanyRole'
import { CreateRole } from 'Domains/User/CompanyRole/CreateRole/CreateRole'
import { AlertsConfig } from 'Domains/Alert/AlertsConfig'
import { Affiliates } from 'Domains/Affiliates/Affiliates'
import { AffiliateSales } from 'Domains/Affiliates/AffiliateSales'
import { AffiliateDashboard } from 'Domains/Affiliate/AffiliateApp/Dashboard/Dashboard'
import { AffiliateCompany } from 'Domains/Affiliate/AffiliateApp/Company/AffiliateCompany'
import { CreateCheckinSettingsPage, EditCheckinSettingsPage } from 'Domains/Company/CheckinSetttings'
import { ListCheckinSettingsPage } from 'Domains/Company/CheckinSetttings/List/CheckinSettings'

export const privateRoutes = [
  {
    path: '/app',
    component: Layout,
    routes: [
      {
        path: '/',
        exact: true,
        component: Dashboard
      },
      {
        path: '/company/create',
        component: CreateCompany
      },
      {
        path: '/company/edit',
        component: EditCompany
      },
      {
        path: '/company/checkin-settings',
        exact: true,
        component: ListCheckinSettingsPage
      },
      {
        path: '/company/checkin-settings/create',
        component: CreateCheckinSettingsPage
      },
      {
        path: '/company/checkin-settings/edit/:settingId',
        component: EditCheckinSettingsPage
      },
      {
        path: '/binamik/invoices/:binamikInvoiceId',
        component: BinamikInvoiceDetails
      },
      {
        path: '/binamik/invoices',
        component: BinamikInvoiceList
      },
      {
        path: '/binamik/users',
        component: AllUsersList
      },
      {
        path: '/users',
        exact: true,
        component: ListUsers
      },
      {
        path: '/users/:userId/edit',
        component: EditUser
      },
      {
        path: '/financial/invoices/:companyInvoiceId',
        component: CompanyInvoiceDetails
      },
      {
        path: '/financial/invoices',
        component: CompanyInvoiceList
      },
      {
        path: '/financial',
        component: Plans
      },
      {
        path: '/integrations/:integrationId/register',
        component: RegisterActivity
      },
      {
        path: '/integrations/:integrationId',
        component: Expand
      },
      {
        path: '/integrations',
        component: Integrations
      },
      {
        path: '/role/create',
        component: CreateRole
      },
      {
        path: '/role/:roleId',
        component: CompanyRole
      },
      {
        path: '/alerts',
        component: AlertsConfig
      },
      {
        path: '/affiliates/:affiliateId',
        component: AffiliateSales
      },
      {
        path: '/affiliates',
        component: Affiliates
      },
      {
        redirect: true,
        path: '/'
      }
    ]
  },
  {
    path: '/affiliate-app',
    component: AffiliateLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: AffiliateDashboard
      },
      {
        path: '/company/:companyId',
        exact: true,
        component: AffiliateCompany
      },
      {
        redirect: true,
        path: '/'
      }
    ]
  }
]
