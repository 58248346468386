/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Password, InputFormGroup } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'

const inputMargin = css`
  margin: 20px 0;
`

export const ResetPasswordForm = ({
  resetPasswordForm = {},
  resetPasswordFormErrors = {},
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity
}) => {
  const { password = '', passwordConfirmation = '' } = resetPasswordForm

  return (
    <>
      <InputFormGroup css={inputMargin} errorMessage={resetPasswordFormErrors.password} label='Nova senha'>
        <Password
          name='password'
          value={password}
          onChange={emitChangeEvent('password')}
          onBlur={emitBlurEvent('password')}
          required
        />
      </InputFormGroup>

      <InputFormGroup css={inputMargin} errorMessage={resetPasswordFormErrors.passwordConfirmation} label='Confirme a nova senha'>
        <Password
          name='passwordConfirmation'
          value={passwordConfirmation}
          onChange={emitChangeEvent('passwordConfirmation')}
          onBlur={emitBlurEvent('passwordConfirmation')}
          required
        />
      </InputFormGroup>
    </>
  )
}
