/* eslint-disable camelcase */

export const RolesModel = {
  create: (roleData = {}) => {
    const { name, selectedPermissions } = roleData

    return {
      name,
      macro_permissions: selectedPermissions
    }
  },
  update: (roleData = {}, id = '') => {
    const { name, selectedPermissions } = roleData

    return {
      id,
      name,
      macro_permissions: selectedPermissions
    }
  },
  removeEmployees: (employeeIds = []) => {
    return {
      employee_ids: employeeIds
    }
  }
}
