import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react'
import { CompanyStore } from 'Core/Store/Company'
import { CompanyService } from 'Core/Service/Company'
import { AuthService } from 'Core/Service/Auth'
import { useUser } from './User'
import { ActivityStore } from 'Core/Store/Activity'

export const CompanyContext = createContext({})

export const useCompany = () => useContext(CompanyContext)

export const CompanyProvider = (props) => {
  const { user, updateUser, updateAuthKey } = useUser()
  const [company, setCompany] = useState(() => CompanyStore.company || {})
  const [userCompanies, setUserCompanies] = useState(null)
  const [isLoadingUserCompanies, setIsLoadingUserCompanies] = useState(false)

  const fetchCompanies = useCallback(() => {
    if (user?.id) {
      setIsLoadingUserCompanies(true)
      CompanyService.listUserCompanies(true)
        .then(setUserCompanies)
        .catch(() => {
          setTimeout(fetchCompanies, 500)
        })
        .finally(() => setIsLoadingUserCompanies(false))
    }
  }, [user])

  useEffect(fetchCompanies, [fetchCompanies])

  const updateCompany = useCallback((newCompany) => {
    CompanyStore.company = newCompany
    setCompany(newCompany)
  }, [])

  const switchCompany = useCallback(
    (companyId) => {
      ActivityStore.clear()
      return AuthService.switchCompany(companyId || null)
        .then(({ company, userCompany, authorization }) => {
          updateCompany(company)
          updateUser({
            ...user,
            company: userCompany
          })
          updateAuthKey(authorization)
        })
    },
    [user, updateCompany, updateUser, updateAuthKey]
  )

  const providerData = useMemo(() => ({
    get id () {
      return company.id
    },
    company,
    updateCompany,
    userCompanies,
    switchCompany,
    isLoadingUserCompanies
  }), [company, updateCompany, userCompanies, switchCompany, isLoadingUserCompanies])

  return (
    <CompanyContext.Provider value={providerData} {...props} />
  )
}
