import { string, ref, bool } from 'yup'

export const signupSchema = {
  name: string().required('O nome completo é obrigatório'),
  email: string().email('E-mail digitado é inválido').required('O e-mail é obrigatório'),
  emailConfirmation: string()
    .email('E-mail digitado é inválido')
    .test('same-email', 'Os e-mails devem ser iguais', function (emailConfirmation) {
      return emailConfirmation === this.resolve(ref('email'))
    })
    .required('A confirmação do e-mail é obrigatória'),
  password: string()
    .required('Preencha o campo de senha')
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .max(128, 'A senha deve conter no máximo 128 caracteres'),
  serviceTerms: bool()
    .oneOf([true], 'A confirmação é obrigatória')
    .required('A confirmação é obrigatória')
}
