import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const iconSize = css`
  font-size: 120px;
`

export const circleBackground = css`
  background-color: ${colors.gray12};
  color: ${colors.gray8};
`

export const newIntegration = css`
  height: 74px;
  width: 130px;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 6px #0000001A;
  border-radius: 10px;
`

export const newIntegrationCircle = css`
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: var(--small-round-element-radius, 12px);
`

export const newIntegrationText = css`
  font-size: 14px;
  color: ${colors.gray4};
  margin-top: 5px;
  margin-bottom: 0;
`

export const subtitle = css`
  margin-top: 50px;
  font-size: 20px;
`

export const emptyResult = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    padding: 0;
  }
`
