import { useState } from 'react'
import { useCompany } from 'Shared/contexts/Company'
import { IntegrationService } from 'Core/Service/Integration'
import { useHistory } from 'react-router'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'Shared/hooks/usePermissions'

export const useCreateIntegration = () => {
  const [loading, setLoading] = useState(false)
  const { id: companyId } = useCompany()
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()

  const createIntegration = async (integration) => {
    const integrationData = { companyId, ...integration }
    setLoading(true)
    try {
      const { integration } = await IntegrationService.create(integrationData).finally(() => setLoading(false))
      addToast('Vinculação realizada com sucesso', 'success')
      push(`/app/integrations/${integration.id}/edit`)
    } catch (error) {
      const hasPermission = handlePermission(error, 'Você não possui permissão de vinculação')
      hasPermission && addToast('Ocorreu um erro na vinculação')
    }
  }

  return {
    submit: createIntegration,
    loading
  }
}
