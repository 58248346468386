import { useState } from 'react'
import { IntegrationService } from 'Core/Service/Integration'
import { useParams } from 'react-router-dom'

export const useRegisterActivity = () => {
  const [loading, setLoading] = useState(false)
  const { integrationId } = useParams()

  const registerActivity = async (data) => {
    setLoading(true)
    return IntegrationService.link(integrationId, data).finally(() => setLoading(false))
  }

  return {
    submit: registerActivity,
    loading
  }
}
