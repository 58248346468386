/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'

import { totalizerValue, totalizerSection } from './SalesTotalizers.style'
import { ComissionDetails } from '../AffiliateComissionDetails'
import { useCallback, useState } from 'react'
import { InfoTotalizerModal, InfoTotalizerField } from 'Shared/components/InfoTotalizer'

export const SalesTotalizers = ({ affiliate, batchTotals, children }) => {
  const [modalData, setModalData] = useState({ isVisible: false, title: '', content: '' })

  const toggleModal = useCallback((extraContent) => () => setModalData((curr) => ({
    isVisible: !curr.isVisible,
    ...(extraContent || {})
  })), [])

  if (affiliate) {
    return (
      <>
        <div css={totalizerSection}>
          <Card>
            <div css={totalizerValue} className={'primary'}>
              <h5>Valor total das vendas</h5>
              <InfoTotalizerField title={'Total vendido'} content={'Total vendido em valor de ticket.'} toggleModal={toggleModal}>
                {formatMoney(affiliate?.totalSoldValue || 0)}
              </InfoTotalizerField>
            </div>
            <div css={totalizerValue}>
              <h5>Valor total da comissão</h5>
              <InfoTotalizerField title={'Total de comissão geral'} content={'Total de comissão sobre todos os tickets vendidos.'} toggleModal={toggleModal}>
                {formatMoney(affiliate?.totalCommissionValue || 0)}
              </InfoTotalizerField>
            </div>
            <div css={totalizerValue}>
              <h5>Saldo disponível</h5>
              <InfoTotalizerField title={'Disponível para receber'} content={'Valor disponível para receber, referente a serviços realizados.'} toggleModal={toggleModal}>
                {formatMoney(affiliate?.totalAvailableValue || 0)}
              </InfoTotalizerField>
            </div>
            <div css={totalizerValue}>
              <h5>Saldo para liberar</h5>
              <InfoTotalizerField title={'A liberar'} content={'Valor de comissão referente a serviços ainda não realizados.'} toggleModal={toggleModal}>
                {formatMoney(affiliate?.toReleaseValue || 0)}
              </InfoTotalizerField>
            </div>
            <div css={totalizerValue}>
              <h5>Ingressos vendidos</h5>
              <InfoTotalizerField title={'Número de vendas'} content={'Quantidade total de tickets vendidos.'} toggleModal={toggleModal}>
                {(affiliate?.totalTicketsSoldAmount || 0)}
              </InfoTotalizerField>
            </div>
          </Card>
          <Card>
            <div css={totalizerValue}>
              <h5>Porcentagem da comissão</h5>
              <ComissionDetails
                percentage={affiliate?.percentage}
                percentageByService={affiliate?.percentageByService}
              />
            </div>
            <div css={totalizerValue}>
              <h5>Valor total pago</h5>
              <InfoTotalizerField title={'Valor pago'} content={'Valor referente aos lotes pagos para o afiliado.'} toggleModal={toggleModal}>
                {formatMoney((batchTotals?.paid || 0))}
              </InfoTotalizerField>
            </div>
          </Card>
          {children}
        </div>
        <InfoTotalizerModal content={modalData.content} isVisible={modalData.isVisible} onClose={toggleModal(null)} title={modalData.title}/>
      </>
    )
  }
}
