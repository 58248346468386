export const XLSX_FORMAT = 'xlsx'
export const CSV_FORMAT = 'csv'

export const FORMAT_OPTIONS = [
  {
    label: 'XLSX',
    value: XLSX_FORMAT
  },
  {
    label: 'CSV',
    value: CSV_FORMAT
  }
]
