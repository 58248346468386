import { useState, useCallback, useEffect } from 'react'
import { EmployeesService } from 'Core/Service/Employee'
import { useParams, useHistory } from 'react-router-dom'
import { useToast } from '@bonitour/components'
import { useAuthentication } from 'Shared/contexts/Authentication'

const loadingHeading = [
  {
    title: 'Obtendo informações do convite'
  }
]

const signupHeading = [
  {
    title: 'Cadastrando usuário'
  }
]

export const useUserInvite = () => {
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { invite = '' } = useParams()
  const { redirectClient, saveCredentials } = useAuthentication()

  const [inviteInfo, setInviteInfo] = useState({})
  const [heading, setHeading] = useState([])
  const [isLoading, setLoadingStatus] = useState(false)

  const getInviteInfo = useCallback(async (inviteId) => {
    setLoadingStatus(true)
    setHeading(loadingHeading)
    try {
      const invitation = await EmployeesService.getInvite(inviteId)
      const { isRead } = invitation
      if (isRead) {
        addToast('Convite já confirmado')
        push('/auth/user-invite-error')
      }
      setInviteInfo(invitation)
    } catch (error) {
      addToast('Ocorreu um erro ao obter o convite')
      push('/auth/user-invite-error')
    }
    setLoadingStatus(false)
  }, [addToast, push])

  const signupUser = useCallback(async (payload) => {
    setLoadingStatus(true)
    setHeading(signupHeading)
    try {
      const invitation = await EmployeesService.userInviteSignup(inviteInfo, payload).then(saveCredentials).then(redirectClient)
      setInviteInfo(invitation)
    } catch (err) {
      if (typeof err?.data === 'string' && err.data.toLowerCase().includes('password')) {
        addToast('Senha incorreta. Utilize a senha já cadastrada!', 'error')
      } else {
        addToast('Ocorreu um erro ao confirmar o convite')
      }
    }
    setLoadingStatus(false)
  }, [inviteInfo, addToast, saveCredentials, redirectClient])

  useEffect(() => {
    if (!invite) {
      push('/auth/user-invite-error')
    }
    getInviteInfo(invite)
  }, [push, invite, getInviteInfo])

  return {
    isLoading,
    heading,
    signupUser,
    inviteInfo
  }
}
