import { useState, useCallback } from 'react'
import { ZipCodeService } from 'Core/Service/ZipCode'

export const useZipCode = addToast => {
  const [zipCode, setZipCode] = useState({})
  const onZipCodeUpdate = useCallback(
    zipCode => {
      if (zipCode) {
        ZipCodeService.get(zipCode)
          .then(setZipCode)
          .catch(() => {
            addToast('CEP inválido')
          })
      }
    },
    [addToast]
  )
  return [zipCode, onZipCodeUpdate]
}
