/** @jsxRuntime classic */
/** @jsx jsx */
import { H2, P, BREAK_POINTS } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useMediaQuery } from '@bonitour/app-functions'
import { AffiliateInviteImage } from './AffiliateInviteImage'
import { affiliateWelcomeMessageContainer, descriptionContainer } from './AffiliateWelcomeMessage.style'

export const AffiliateWelcomeMessage = ({ companyName = '', percentage = 0 }) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet})`)

  return (
    <div css={affiliateWelcomeMessageContainer}>
      <H2>Parabéns!</H2>
      <P css={descriptionContainer}>
        <span className='company_name__label'>{companyName}</span> convidou você para ser um afiliado e ganhar um prêmio em porcentagem!
      </P>
      {!isMobile && <AffiliateInviteImage className='affiliate_pecentage__image' percentage={percentage} />}
    </div>
  )
}
