import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const percentageDescriptionStyle = css`
  color: ${colors.gray3};
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
  letter-spacing: 0px;
  text-align: left;
`

export const affiliateFormColumn = css`  
  flex-direction: column;
  gap: 0;
  
  & > div {
    margin-top: 0;
  }
  &:first-of-type {
    padding-right: 20px;
  }
  &:last-of-type > div {
    padding: 15px;
    margin-bottom: 15px;
  }

  h4 {
    margin-bottom: 0;
  }
`

export const buttonsRowContainer = css`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const percentageByServiceContainer = css`
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 15px;
    span {
      margin-left: 5px;
    }
    &.round {
      width: 40px;
      padding: 0;
    }
    &.add {
      margin-bottom: 20px;
    }
    &:not([disabled]) {
      border-color: ${colors.primary};
      color: ${colors.primary};
    }
  }
  .service-row {
    display: grid;
    grid-template-columns: 2fr 1fr 40px;
    gap: 10px;
    align-items: end;

    @media (max-width: ${BREAK_POINTS.tableUp}) {
      border-bottom: 1px solid ${colors.gray8};
      padding-bottom: 15px;

      .experience-input {
        grid-column: span 3;
      }
      .percentage-input {
        grid-column: span 2;
      }
    }
  }

`
