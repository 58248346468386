import { string } from 'yup'

export const affiliateInviteSchema = {
  name: string().required('O nome é obrigatório'),
  password: string().required('A senha é obrigatória').min(6, 'A senha é muito curta, mínimo obrigatório de 6 caracteres'),
  passwordConfirmation: string()
    .required('A confirmação de senha é obrigatória')
    .test('test-password-confirmation', 'As senhas devem ser iguais', function (value) {
      return value === this.parent.password
    })
}
