/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'
import { Header, Hamburguer, Content, Footer, Sidebar, Logo, flexRowCentered, colors, BREAK_POINTS } from '@bonitour/components'
import { User } from './User'
import { ProductsMenu } from './ProductsMenu'
import { AuthorizationProvider } from 'Shared/contexts/Authorization'
import { CompanyProvider, useCompany } from 'Shared/contexts/Company'
import { UserProvider } from 'Shared/contexts/User'
import { Link } from 'react-router-dom'
import { Menu } from './Menu'
import { APP_ENVIRONMENT } from 'Shared/config/env'
import { Feature } from 'Shared/contexts/Feature'
import { HelpSection } from './Help'
import { ActivityProvider } from 'Shared/contexts/Activity'
import { CookieConsentHandler } from 'Domains/Cookies/CookieConsentHandler'
import { TermsHandler } from 'Domains/Terms/TermsHandler'

const envStyle = css`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
  text-transform: capitalize;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: none;
  }
`

const LayoutContainer = ({ children, affiliate = false }) => {
  const { id: companyId } = useCompany()
  const [isVisible, setVisibility] = useState(false)
  const closeSidebar = () => setVisibility(false)
  const openSidebar = () => setVisibility(true)
  const hasCompany = Boolean(companyId)

  return (
    <>
      <Header>
        <div css={flexRowCentered}>
          {hasCompany && <Hamburguer onClick={openSidebar} />}
          <Link to={affiliate ? '/affiliate-app' : '/app'}>
            <Logo src={BinamikLogo} />
          </Link>

          {APP_ENVIRONMENT !== 'production' && <span css={envStyle}>{APP_ENVIRONMENT}</span>}
        </div>
        <div css={flexRowCentered}>
          {!affiliate && (
            <>
              <HelpSection />
              <ProductsMenu />
            </>
          )}
          <User affiliate={affiliate} />
        </div>
      </Header>
      <Content>
        {children}
      </Content>
      <CookieConsentHandler />
      <TermsHandler />
      <Footer />
      {hasCompany &&
        <Sidebar isOpen={isVisible} onSidebarClose={closeSidebar}>
          <Menu onNavigation={closeSidebar} />
        </Sidebar>}
    </>
  )
}

export const Layout = (props) => {
  return (
    <UserProvider>
      <CompanyProvider>
        <ActivityProvider>
          <AuthorizationProvider>
            <Feature>
              <LayoutContainer {...props} />
            </Feature>
          </AuthorizationProvider>
        </ActivityProvider>
      </CompanyProvider>
    </UserProvider>
  )
}

export const AffiliateLayout = (props) => {
  return <Layout affiliate {...props} />
}
