export const RolesParser = {
  list ({ roles = [] }) {
    const companyRoles = roles.map(({ id, name, employees_count: employeesCount }) => ({
      id,
      name,
      employeesCount
    }))

    return { roles: companyRoles }
  },
  card ({ meta = {}, roles = [] }) {
    const { total_pages: totalPages = 0, total_entries: total = 0 } = meta
    const companyRoles = roles.map(({ id, name, employees_count: employeesCount }) => ({
      id,
      name,
      employeesCount
    }))

    return { roles: companyRoles, totalPages, total }
  },
  companyRole ({ role }) {
    const {
      id,
      name,
      employees_count: employeesCount,
      users,
      active,
      macro_permissions: macroPermissions
    } = role

    const employees = user => {
      const {
        id,
        name,
        email,
        avatar,
        employee_id: employeeId = ''
      } = user

      return {
        id,
        name,
        email,
        avatar,
        employeeId
      }
    }

    return {
      id,
      name,
      active,
      employeesCount,
      macroPermissions,
      users: users && users.map(user => employees(user))
    }
  },
  macroPermissions ({ macro_permissions: macroPermissions = [] }) {
    const permissions = macroPermissions.map(({ name, translation: { name: translatedName, description } }) => ({
      name,
      description,
      translatedName
    }))

    return { permissions }
  }
}
