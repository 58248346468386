export const TermParser = {
  get: (term) => {
    const {
      legal_agreement: {
        id,
        agreement,
        agreement_type: agreementType,
        created_at: createdAt,
        updated_at: updatedAt,
        version
      }
    } = term

    return { id, agreement, agreementType, createdAt, updatedAt, version }
  }

}
