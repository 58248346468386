import { string, ref } from 'yup'

export const resetPasswordFormSchema = {
  password: string().required('Preencha o campo de senha'),
  passwordConfirmation: string()
    .test('same-password', 'As senhas devem ser iguais', function (passwordConfirmation) {
      return passwordConfirmation === this.resolve(ref('password'))
    })
    .required('A confirmação da senha é obrigatória')
}
