import { AuthApi } from 'Core/Api/Auth'
import { AuthParser } from 'Core/Parser/Auth'
import { orbCore } from './Orb/Service'

const { authDomain } = orbCore

export const AuthService = {
  login (payload) {
    return AuthApi.login(payload)
      .then(AuthParser.payload)
  },
  oauthCallback (payload) {
    return AuthApi.oauthCallback(payload)
      .then(AuthParser.authorization)
  },
  signup (payload) {
    return AuthApi.signup(payload)
      .then(AuthParser.payload)
  },
  forgotPassword (payload) {
    return AuthApi.forgotPassword(payload)
  },
  resetPassword (payload = {}) {
    const { token, password } = payload
    return authDomain.resetPassword({
      token,
      new_password: password
    })
  },
  switchCompany (companyId) {
    return AuthApi.switchCompany(companyId)
      .then(AuthParser.switchCompanyPayload)
  }
}
