import { css } from '@emotion/core'

export const totalizerFieldStyle = css`
& > div {
  cursor: pointer;
  margin-left: 4px;
  display: inline-block;
  .tooltip-on-hover {
    display: none !important;
  }
}
`
