/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, flexRow, Button, HeaderPage } from '@bonitour/components'
import { marginRight, hidden } from 'assets/styles/global'
import { BinamikInvoiceInfo } from './BinamikInvoiceInfo'
import { useBinamikInvoiceDetail } from '../hooks/useBinamikInvoiceDetail'
import { useHistory } from 'react-router-dom'
import { ItemsTable, PaymentsValues } from 'Domains/Financial/components'

const buttonStyle = css`
  padding: 12px 30px;
`
const marginRight16 = marginRight(16)

export const BinamikInvoiceDetails = ({ match }) => {
  const history = useHistory()
  const emitBackClickEvent = () => history.goBack()
  const invoiceId = match.params.binamikInvoiceId ?? 1
  const { onPaymentConfirmation, invoice, receivedAmount, totalAmount, pendingAmount } = useBinamikInvoiceDetail({ invoiceId })
  const isStateClosed = invoice.state === 'closed'

  return (
    <>
      <HeaderPage onBack={emitBackClickEvent} title={`Fatura ${invoiceId}`}>
        <Button onClick={onPaymentConfirmation} css={[buttonStyle, marginRight16, !isStateClosed && hidden]}>
          Confirmar pagamento
        </Button>
      </HeaderPage>

      <Card>
        <BinamikInvoiceInfo invoiceInfo={invoice} isReadOnly />
        <div css={flexRow}>
          <ItemsTable items={invoice.binamikItems} paidValueLabel='Total recebido' />
          <PaymentsValues
            receivedAmount={receivedAmount}
            totalAmount={totalAmount}
            pendingAmount={pendingAmount}
            firstLabel='Total a receber'
            secondLabel='Total recebido'
          />
        </div>
      </Card>
    </>
  )
}
