/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo, useRef } from 'react'
import { useElementBoundingClientRect } from 'Shared/utils/useElementBoundingClientRect'

import { safeOverflowContainer as container } from './SafeOverflow.styles'

export const SafeOverflow = ({ text, alignment = 'left' }) => {
  const safeContainerRef = useRef(null)
  const hintRef = useRef(null)

  const { width: containerWidth } =
    useElementBoundingClientRect(safeContainerRef)

  const { width: hintWidth } = useElementBoundingClientRect(hintRef, 'content')

  const isOverflowing = useMemo(
    () => containerWidth < hintWidth,
    [containerWidth, hintWidth]
  )

  return (
    <span
      className={`safe_overflow__container safe_overflow__align_${alignment}`}
      css={container}
      ref={safeContainerRef}
    >
      <span className='safe_overflow__text'>{text}</span>

      <span
        className={`safe_overflow__hint ${
          isOverflowing ? 'is_overflowing' : 'is_safe'
        }`}
        ref={hintRef}
      >
        {text}
      </span>
    </span>
  )
}
