/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { Button, useToast, GhostButton, Input, InputFormGroup, Row, InputPercentage, LabelWithRequiredSuffix, Modal, Column, H4, Card, Select, TrashIcon, PlusIcon, LoadingAnimation } from '@bonitour/components'
import { useCallback, useMemo, useState } from 'react'
import { affiliateBaseSchema, affiliateSchema } from './AffiliateInviteDialog.schema'
import { affiliateFormColumn, buttonsRowContainer, percentageByServiceContainer, percentageDescriptionStyle } from './AffiliateInviteDialog.style'
import { identity } from '@bonitour/common-functions'
import { AffiliateService } from 'Core/Service/Affiliate/Service'
import { useActivity } from 'Shared/contexts/Activity'
import { loadingOverlay } from 'assets/styles/global'

const CustomPercentageByService = ({
  percentageByService = [],
  onChange = identity,
  onInputBlur = identity,
  errors = []
}) => {
  const { companyActivities = [], partnerActivities = [] } = useActivity()
  const unparsedServices = useMemo(
    () => companyActivities.concat(partnerActivities),
    [companyActivities, partnerActivities]
  )
  const services = useMemo(
    () => unparsedServices
      .map(({ id, name, companyName }) => ({
        value: id,
        label: `${name} - ${companyName}`
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    [unparsedServices]
  )

  const handleOnChange = useCallback(percentageByService => {
    onChange(percentageByService.map(({ serviceId, percentage }, index) => ({
      serviceId,
      percentage,
      duplicate: serviceId && percentageByService.findIndex(service => service.serviceId === serviceId) !== index
    })))
  }, [onChange])

  const addService = useCallback(() => {
    handleOnChange([...percentageByService, { serviceId: '', percentage: 1 }])
  }, [handleOnChange, percentageByService])

  const canAddService = useMemo(
    () => percentageByService?.[percentageByService.length - 1]?.serviceId !== '',
    [percentageByService]
  )

  const onChangeItem = useCallback((key, index) => value => {
    handleOnChange(percentageByService.map((service, serviceIndex) => {
      if (serviceIndex === index) {
        return { ...service, [key]: value }
      }
      return service
    }))
  }, [handleOnChange, percentageByService])

  const removeService = useCallback(index => () => {
    handleOnChange(percentageByService.filter((_, serviceIndex) => serviceIndex !== index))
  }, [handleOnChange, percentageByService])

  return (
    <div css={percentageByServiceContainer}>
      {percentageByService.map((service, index) => (
        <Row key={index} className="service-row">
          <InputFormGroup errorMessage={errors?.[index]?.serviceId} label="Experiência" className="experience-input">
            <Select
              placeholder='Selecione uma experiência'
              options={services}
              value={percentageByService[index].serviceId}
              error={errors?.[index]?.serviceId}
              onChange={onChangeItem('serviceId', index)}
              onBlur={onInputBlur(`percentageByService[${index}].serviceId`)}
            />
          </InputFormGroup>
          <InputFormGroup errorMessage={errors?.[index]?.percentage} label="Prêmio" className="percentage-input">
            <InputPercentage
              value={percentageByService[index].percentage}
              error={errors?.[index]?.percentage}
              onChange={onChangeItem('percentage', index)}
              onBlur={onInputBlur(`percentageByService[${index}].percentage`)}
            />
          </InputFormGroup>
          <GhostButton type="button" className="round" onClick={removeService(index)}><TrashIcon/></GhostButton>
        </Row>
      ))}
      <GhostButton type="button" onClick={addService} className="add" disabled={!canAddService}>
        <PlusIcon/>
        <span>Adicionar</span>
      </GhostButton>
    </div>
  )
}

export const AffiliateInviteDialog = ({
  onClose = identity,
  isVisible = true,
  editingAffiliate = null,
  reloadAffiliates = identity
}) => {
  const initialForm = useMemo(() => affiliateBaseSchema(editingAffiliate), [editingAffiliate])
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(initialForm, affiliateSchema)

  const { add: addToast } = useToast()

  const emitSuccess = useCallback((isEdit) => () => {
    addToast(isEdit ? 'O afiliado foi atualizado com sucesso' : 'Convite enviado com sucesso', 'success')
    reloadAffiliates()
    onClose()
  }, [addToast, onClose, reloadAffiliates])

  const emitFailure = useCallback((isEdit) => (err) => {
    if (err?.data?.errors_msg.includes('uc_create_user_employee_invite::email::taken')) {
      addToast('Este e-mail já está cadastrado', 'error')
    } else {
      addToast(isEdit ? 'Houve um erro ao atualizar o afiliado' : 'Houve um erro ao enviar o convite', 'error')
    }
    onClose()
  }, [addToast, onClose])

  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(() => {
    setLoading(true)
    if (editingAffiliate) {
      AffiliateService.update(editingAffiliate?.id, form)
        .then(() => {
          AffiliateService.updateCustomCommission(editingAffiliate?.id, form)
            .then(emitSuccess(true))
            .catch(emitFailure(true))
            .finally(() => setLoading(false))
        })
        .catch(e => {
          emitFailure(true)(e)
          setLoading(false)
        })
    } else {
      AffiliateService.create(form)
        .then(emitSuccess(false))
        .catch(emitFailure(false))
        .finally(() => setLoading(false))
    }
  }, [editingAffiliate, emitFailure, emitSuccess, form])

  return (
    <Modal title={editingAffiliate ? 'Editar informações do afiliado' : 'Novo afiliado'} isVisible={isVisible} onCloseClick={onClose}>
      {loading && (
        <div css={loadingOverlay}>
          <LoadingAnimation />
        </div>
      )}
      <Row>
        <Column phone={12} desktop={4} customCss={[affiliateFormColumn]}>
          <Row>
            <InputFormGroup errorMessage={errors.email} label={<LabelWithRequiredSuffix label='E-mail' requiredLabel='Obrigatório' />}>
              <Input
                value={form.email}
                onChange={onInputChange('email')}
                onBlur={onInputBlur('email')}
                placeholder='exemplo@gmail.com'
                disabled={Boolean(editingAffiliate)}
              />
            </InputFormGroup>
          </Row>
          <Row>
            <InputFormGroup errorMessage={errors.percentage} label={<LabelWithRequiredSuffix label='Porcentagem de prêmio padrão' requiredLabel='Obrigatório' />}>
              <InputPercentage
                value={form.percentage}
                onChange={onInputChange('percentage')}
              />
            </InputFormGroup>
            <p css={percentageDescriptionStyle}>
              O valor da porcentagem de prêmio é sobre o total da compra, e não sobre o comissionamento da sua empresa.
            </p>
          </Row>

        </Column>
        <Column phone={12} desktop={8} customCss={[affiliateFormColumn]}>
          <Card>
            <H4>Prêmio por experiência</H4>
            <p css={percentageDescriptionStyle}>
              Uma porcercentagem de prêmio diferente da padrão pode ser aplicada para experiências específicas.
            </p>
            <CustomPercentageByService
              percentageByService={form.percentageByService}
              onChange={onInputChange('percentageByService')}
              onInputBlur={onInputBlur}
              errors={errors?.percentageByService}
            />
            <p css={percentageDescriptionStyle}>
            Experiências não adicionadas nesta listagem terão a porcentagem de prêmio padrão aplicada.
            </p>
          </Card>
        </Column>
      </Row>
      <div css={buttonsRowContainer}>
        <GhostButton onClick={onClose}>Cancelar</GhostButton>
        <Button onClick={onSubmit(handleSubmit)}>{editingAffiliate ? 'Salvar mudanças' : 'Enviar convite'}</Button>
      </div>
    </Modal>
  )
}
