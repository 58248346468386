import { AllUsersParser, UsersParser } from 'Core/Parser/User'
import { UserModel } from 'Core/Models/User'
import { orbCore } from './Orb/Service'

const { employeesDomain } = orbCore

export const UserService = {
  getById (id) {
    return employeesDomain.getById(id).then(UsersParser.user)
  },
  getList () {
    return employeesDomain.getList().then(UsersParser.list)
  },
  getPaginatedList (filters, pagination) {
    return employeesDomain.getPaginatedList(UserModel.list(filters, pagination)).then(UsersParser.card)
  },
  getPaginatedAllList (page, perPage) {
    if (!(perPage && page)) {
      return Promise.resolve({})
    }
    return employeesDomain.getPaginatedAllList(perPage, page).then(AllUsersParser.list)
  },
  toggleActive (id) {
    if (!id) return Promise.resolve({})
    return employeesDomain.toggleActive(id)
  },
  editUserRoles (id, roles) {
    if (!id) return Promise.resolve({})
    return employeesDomain.editUserRoles(id, UserModel.roles(roles))
  }
}
