import { AppStore } from './App'

const { REACT_APP_API_HOST } = process.env
export const getImageUrl = image => image ? image.includes(REACT_APP_API_HOST) ? image : `${REACT_APP_API_HOST}${image}` : ''

function companyGetter () {
  try {
    const company = JSON.parse(AppStore.get('company'))
    company.image = getImageUrl(company.image)
    return company
  } catch (error) {
    return {}
  }
}

export const CompanyStore = {
  getId () {
    const { id } = companyGetter()
    return id
  },
  get company () {
    return companyGetter()
  },
  set company (info) {
    const infoString = JSON.stringify(info)
    AppStore.set('company', infoString)
  }
}
