/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AvatarCard, Card, CopyIcon, H1, H3, LoadingAnimation, useToast } from '@bonitour/components'
import { useUser } from 'Shared/contexts/User'
import { useCallback, useEffect, useState } from 'react'
import { AffiliateService } from 'Core/Service/Affiliate/Service'
import { useHistory } from 'react-router-dom'
import { formatMoney } from '@bonitour/common-functions'
import { InfiniteScroll } from 'Shared/components/InfiniteScroll'
import { avatar, companiesContainer, copyButton, loadingContainer, priceCard } from './Dashboard.styles'

export const AffiliateDashboard = () => {
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { user } = useUser()

  const [meta, setMeta] = useState({ currentPage: 0 })
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    if (page > meta.currentPage) {
      setIsLoading(true)
      const pagination = {
        page,
        perPage: 10
      }

      AffiliateService
        .affiliateCompanies(pagination)
        .then(({ affiliateCompanies, meta }) => {
          setCompanies(affiliateCompanies)
          setMeta(meta)
        })
        .catch(() => {
          addToast('Erro ao carregar empresas afiliadas', 'error')
          push('/auth/profiles')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [page, meta.currentPage, addToast, push])

  const loadNextPage = useCallback(() => {
    if (!isLoading && page < meta.totalPages) {
      setPage(page + 1)
    }
  }, [isLoading, meta.totalPages, page])

  const copy = useCallback((link, companyId) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!link) {
      const affiliateCode = user?.affiliates_info?.find(({ company_id: id }) => companyId === id)?.affiliate_code
      if (!affiliateCode) {
        addToast('Código não disponível', 'error')
        return
      }
      try {
        navigator.clipboard.writeText(affiliateCode)
        addToast('Código do afiliado copiado para a área de tranferência', 'success')
      } catch (err) {
        addToast('Erro ao copiar código', 'error')
      }
      return
    }
    try {
      navigator.clipboard.writeText(link)
      addToast('Link do afiliado copiado para a área de tranferência', 'success')
    } catch (err) {
      addToast('Erro ao copiar link', 'error')
    }
  }, [addToast, user?.affiliates_info])

  const onCardClick = useCallback((companyId) => () => {
    push(`/affiliate-app/company/${companyId}`)
  }, [push])

  return (
    <>
      <H1>Bem vindo, {user.name}</H1>
      <H3>Empresas afiliadas</H3>

      <div css={companiesContainer}>
        {companies.map((company) => (
          <AvatarCard
            css={avatar}
            key={company.id}
            avatar={company.companyImage}
            title={company.name}
            onClick={onCardClick(company.id)}
            initialsFallback
          >
            <p>Comissão:</p>
            <Card css={priceCard}>{formatMoney(company.totalCommissionValue)}</Card>
            <button css={copyButton} type='button' onClick={copy(company.affiliateLink, company.id)}>
              <CopyIcon />
              <p>Copiar {company.affiliateLink ? 'link' : 'código'} de afiliado</p>
            </button>
          </AvatarCard>
        ))}
      </div>
      <InfiniteScroll nextPage={loadNextPage} loading={isLoading} />
      {isLoading ? <LoadingAnimation css={loadingContainer} /> : null}
    </>
  )
}
