/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { BREAK_POINTS, LoadingAnimation } from '@bonitour/components'
import { RoleCard } from './RoleCard'
import { loadingContainer } from 'assets/styles/global'

const container = css`
  display: flex;  
  flex-wrap: wrap;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    justify-content: center;
  }
`

export const RolesList = ({
  roles = [],
  loading = false,
  onRoleCardClick: emitRoleCardClick = identity,
  onRoleDelete: emitRoleDelete = identity
}) => {
  return (
    <>
      {loading && <LoadingAnimation size={30} css={loadingContainer} />}
      <div css={container}>
        {!loading && roles.map(({ id, name, employeesCount }) => (
          <RoleCard
            key={id}
            id={id}
            name={name}
            employeesCount={employeesCount}
            onRoleCardClick={emitRoleCardClick}
            onRoleDelete={emitRoleDelete}
          />
        ))}
      </div>
    </>
  )
}
