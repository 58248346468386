/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  EllipsisIcon,
  LinkIcon,
  Manager,
  MinusCircle,
  CheckCircle,
  Popover,
  Reference,
  useToast,
  EditInfoIcon,
  TicketsIcon,
  ForwardIcon
} from '@bonitour/components'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { actionsContainer, actionsRefContainer, affiliateActionsPopover, popoverCloseButton } from './AffiliateActionsCell.style'
import { AffiliateService } from 'Core/Service/Affiliate/Service'
import { identity } from '@bonitour/common-functions'
import { AffiliateInviteDialog } from '../AffiliatesInvite/AffiliateInviteDialog'

const AffiliateActions = ({ openAffiliateEdition = identity, reloadAffiliates = identity, onReinviteAffiliate = identity, affiliate = {}, affiliateResendLoadingIds = [] }) => {
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { id: affiliateId, affiliateLink, status: affiliateStatus } = affiliate

  const isAffiliatePending = useMemo(() => affiliateStatus === 'pending', [affiliateStatus])
  const isAffiliateActive = useMemo(() => affiliateStatus === 'active', [affiliateStatus])

  const onCopyAffiliateLink = useCallback(() => {
    if (isAffiliatePending) {
      return addToast('O afiliado deve aceitar o convite para o link ser criado')
    }
    navigator.clipboard.writeText(affiliateLink).then(
      () => addToast('O link foi copiado com sucesso', 'success')
    ).catch(() => addToast('Houve um erro ao copiar o link', 'error'))
  }, [addToast, affiliateLink, isAffiliatePending])

  const onToggleAffiliateStatus = useCallback(() => {
    if (isAffiliatePending) {
      return addToast('Não é possível inativar um afiliado pendente')
    }
    AffiliateService.toggleActive(affiliateId).then(
      () => {
        addToast('O status do afiliado foi alterado com sucesso', 'success')
        reloadAffiliates()
      }
    ).catch(() => addToast('Houve um erro ao alterar o status do afiliado', 'error'))
  }, [addToast, affiliateId, isAffiliatePending, reloadAffiliates])

  const onOpenAffiliateEdition = useCallback(() => {
    if (isAffiliatePending) {
      return addToast('O afiliado deve aceitar o convite antes de ser editado', 'error')
    }
    openAffiliateEdition()
  }, [addToast, isAffiliatePending, openAffiliateEdition])

  const onOpenAffiliateTickets = useCallback((affiliateId) => () => {
    push(`/app/affiliates/${affiliateId}`)
  }, [push])

  const isLoadingResendInvite = useMemo(() => affiliateResendLoadingIds.includes(affiliateId), [affiliateId, affiliateResendLoadingIds])

  const onResendInvite = useCallback(() => {
    return onReinviteAffiliate(affiliateId)
  }, [affiliateId, onReinviteAffiliate])

  return (
    <div css={actionsContainer}>
      {isAffiliatePending && (
        <div onClick={onResendInvite} className={['action__row', isLoadingResendInvite && 'action__disabled'].join(' ')}>
          <ForwardIcon />
          <span>Reenviar convite</span>
        </div>
      )}
      <div onClick={onCopyAffiliateLink} className='action__row'>
        <LinkIcon />
        <span>Copiar link do afiliado</span>
      </div>
      <div onClick={onOpenAffiliateEdition} className='action__row'>
        <EditInfoIcon />
        <span className='action__label'>Editar informações do afiliado</span>
      </div>
      <div onClick={onToggleAffiliateStatus} className='action__row'>
        {(isAffiliateActive) ? <MinusCircle /> : <CheckCircle />}
        <span>{(isAffiliateActive || isAffiliatePending) ? 'Inativar' : 'Ativar'} afiliado</span>
      </div>
      {!isAffiliatePending && (
        <div onClick={onOpenAffiliateTickets(affiliateId)} className='action__row'>
          <TicketsIcon />
          <span>Tickets vendidos</span>
        </div>
      )}
    </div>
  )
}

export const AffiliateActionsCell = ({ reloadAffiliates = identity, onReinviteAffiliate = identity, affiliate, affiliateResendLoadingIds = [] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isEditionVisible, setIsEditionVisible] = useState(false)

  const handleActionsVisible = useCallback(() => setIsOpen(v => !v), [])

  const openAffiliateEdition = useCallback(() => {
    handleActionsVisible()
    setIsEditionVisible(true)
  }, [handleActionsVisible])

  const onCloseAffiliateEdition = useCallback(() => setIsEditionVisible(false), [])

  return (
    <Manager>
      <AffiliateInviteDialog isVisible={isEditionVisible} onClose={onCloseAffiliateEdition} editingAffiliate={affiliate} reloadAffiliates={reloadAffiliates}/>
      <Reference>
        {({ ref }) => (
          <>
            <div onClick={handleActionsVisible} ref={ref} css={actionsRefContainer(isOpen)}>
              <EllipsisIcon className='ellipsis__icon' />
            </div>
          </>
        )}
      </Reference>
      {isOpen && (
        <>
          <button type='button' onClick={handleActionsVisible} css={popoverCloseButton} />
          <Popover customCss={[affiliateActionsPopover]} preventOverflow position='bottom'>
            <AffiliateActions
              openAffiliateEdition={openAffiliateEdition}
              affiliate={affiliate}
              reloadAffiliates={reloadAffiliates}
              onReinviteAffiliate={onReinviteAffiliate}
              affiliateResendLoadingIds={affiliateResendLoadingIds}
            />
          </Popover>
        </>
      )}
    </Manager>
  )
}
