/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useRef } from 'react'
import axios from 'axios'
import { jsx } from '@emotion/core'
import { Content, Footer, H1, Header, Logo } from '@bonitour/components'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'
import { container, headerLogo, logo, overrideBaseLayout, terms, termContainer } from './Terms.style'
import { useTerms } from './hooks/useTerms'
import { TitlesDictionary } from 'Shared/constants/termsDictionary'

export const Terms = ({ type }) => {
  const { term } = useTerms(type)

  const contentRef = useRef(null)
  useEffect(() => {
    if (type === 'privacy_policy' && contentRef.current && term?.agreementType) {
      const cookieTable = contentRef.current.querySelector('#cookie-table')
      if (cookieTable) {
        axios.get('https://binamik.com.br/cookies-utilizados').then((res) => {
          if (res.data) {
            const responseDom = new DOMParser().parseFromString(res.data, 'text/html')
            const innerTableHtml = responseDom.body.innerHTML
            cookieTable.innerHTML = innerTableHtml.replace(/Nunito Sans/g, 'Mulish')
          }
        })
      }
    }
  }, [term?.agreementType, type])

  return (
    <div>
      <Header css={headerLogo}>
        <Logo css={logo} src={BinamikLogo} />
      </Header>
      <Content css={overrideBaseLayout}>
        <div css={container}>
          <div css={termContainer}>
            <H1>{TitlesDictionary[term?.agreementType]}</H1>
            <div ref={contentRef} css={terms} dangerouslySetInnerHTML={{ __html: term?.agreement }} />
          </div>
        </div>
      </Content>
      <Footer />
    </div>
  )
}
