import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const loginFormContent = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  button {
    width: 100%;
  }
  
  .btn_email {
    margin-top: 0.75rem;
  }

  .btn_google {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    padding: 8px;

    background-color: white;
    border: 1px solid ${colors.gray2};
    color: ${colors.gray3};

    img {
      width: 26px;
      height: 26px;
    }
  }

  .signup-container {
    font-size: 0.825rem;
    display: inline-block;
    font-weight: 600;
    color: ${colors.gray2};
  }

  .or-container {
    width: calc(100% - 1rem);
    display: flex;
    align-items: center;
    text-align: center;
  }
    
  .or-line {
    width: 280px;
    height: 1px;
    background-color: ${colors.gray7};
  }

  .or-text {
    color: ${colors.gray4};
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 10px;
    font-size: 0.875rem;
  }
  
`
