import React, { useCallback } from 'react'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  LoadingTable,
  Card,
  TableHeaderCell
} from '@bonitour/components'
import { TableCheckinStyle } from './style'
import { TableBodyCellAction } from './TableBodyCellAction'

const CheckinTable = ({ isLoading, settings, deleteSetting, regionList = [] }) => {
  const columns = ['Região', 'Status', 'Link do termo', 'Ações']

  const parseCheckinEnabled = (isEnabled) => (isEnabled ? 'Habilitado' : 'Desabilitado')

  const parseRegion = (region) => region.charAt(0).toUpperCase() + region.slice(1)

  const parseLink = (link) => {
    const uri = link && !link.includes('http') ? `https://${link}` : link
    return !uri
      ? (
        'Não cadastrado'
      )
      : (
        <a href={uri} target="_blank" rel="noreferrer">
          {uri}
        </a>
      )
  }

  const parseOccupedRegionList = useCallback(
    (checkingRegion) => regionList.filter((region) => region !== checkingRegion),
    [regionList]
  )

  if (isLoading) return <LoadingTable columns={columns} elementsCount={10} />

  return <Card>
    <TableContainer css={TableCheckinStyle}>
      <TableHeader>
        <TableHeaderRow>
          {columns.map((column, index) => (
            <TableHeaderCell key={`${column}-${index}`}>{column}</TableHeaderCell>
          ))}
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {settings.map(({ id, region, isEnabled, termsLink }) => (
          <TableBodyRow key={id}>
            <TableBodyCell>{parseRegion(region)}</TableBodyCell>
            <TableBodyCell>{parseCheckinEnabled(isEnabled)}</TableBodyCell>
            <TableBodyCell>{parseLink(termsLink)}</TableBodyCell>
            <TableBodyCellAction
              setting={{
                id,
                region,
                isEnabled,
                termsLink
              }}
              deleteSetting={deleteSetting}
              regionList={parseOccupedRegionList(region)}
            />
          </TableBodyRow>
        ))}
      </TableBody>
    </TableContainer>
  </Card>
}

export default CheckinTable
