import { colors, BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'

export const totalizerValue = css`
  height: 100%;
  width: 100%;
  min-width: 120px;
  padding: 10px 0;
  padding-left: 1rem;

  h5 {
    font-size: 12px;
    color: ${colors.gray4};
    margin-bottom: 10px;
  }
  p {
    font-size: 1.125rem;
    color: ${colors.gray2};
    font-weight: 700;
  }
  &.primary p {
    color: ${colors.primary};
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  @media (min-width: ${BREAK_POINTS.tablet}) {
    width: 200px;
    &:not(:first-of-type) {
      padding-left: 20px;
      border-left: 1px solid ${colors.gray7};
    }
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`

export const totalizerSection = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    white-space: nowrap;
    width: 100%;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;

    &:last-of-type {
      margin-top: 1rem;
      justify-content: flex-start;
      width: max-content;
    }
  }


  @media (max-width: ${BREAK_POINTS.tablet}) {
    > div {
      &:last-of-type {
        width: 100%;
      }
    }
  }

  @media (min-width: ${BREAK_POINTS.tablet}) {
    > div {
      flex-direction: row;
    }
  }
`
