import React, { createContext, useContext, useCallback, useMemo, useEffect } from 'react'

import { useQuery } from 'Shared/hooks/useQuery'
import { AuthService } from 'Core/Service/Auth'
import { useCompany } from './Company'
import { useUser } from './User'
import { useHistory } from 'react-router-dom'
import { AffiliateInviteService } from 'Core/Service/Affiliate/Service'

export const AuthenticationContext = createContext({})

export const useAuthentication = () => useContext(AuthenticationContext)

const USER_ALLOWED = [
  '/auth/profiles',
  '/welcome/affiliate'
]
const USER_REQUIRED = [
  '/auth/profiles'
]

const isAllowedRedirectUrl = (url) => {
  const link = document.createElement('a')
  link.href = url
  const hostname = link.hostname
  return hostname === window.location.hostname || hostname.endsWith('.binamik.com.br')
}

export const AuthenticationProvider = (props) => {
  const history = useHistory()
  const { redirectUrl } = useQuery()
  const { updateCompany } = useCompany()
  const { authKey, updateUser, updateAuthKey, profileType } = useUser()

  const stepAfterLogin = useMemo(() => {
    switch (profileType) {
    case 'affiliate':
      return '/affiliate-app'
    case 'agent':
      return '/app'
    default:
      return `/auth/profiles${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`
    }
  }, [profileType, redirectUrl])

  const redirectClient = useCallback(() => {
    if (profileType && redirectUrl && isAllowedRedirectUrl(redirectUrl)) {
      window.location = redirectUrl
    } else {
      history.push(stepAfterLogin)
    }
  }, [history, redirectUrl, stepAfterLogin, profileType])

  useEffect(() => {
    if (authKey && !USER_ALLOWED.some(path => history.location.pathname.startsWith(path))) {
      redirectClient()
    }
    if (!authKey && USER_REQUIRED.some(path => history.location.pathname.startsWith(path))) {
      history.push(`/auth/login${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`)
    }
  }, [authKey, history.location.pathname, stepAfterLogin, redirectUrl, redirectClient, history])

  const saveCredentials = useCallback(({ user, authorization, company }) => {
    updateUser(user)
    updateAuthKey(authorization)
    updateCompany(company)
  }, [updateCompany, updateUser, updateAuthKey])

  const login = useCallback(
    (payload) => AuthService.login(payload).then(
      saveCredentials
    ).then(redirectClient),
    [saveCredentials, redirectClient]
  )

  const oauthLogin = useCallback(
    (token) => AuthService.oauthCallback({ temp_token: token })
      .then(login),
    [login]
  )

  const signup = useCallback(
    (payload) => AuthService.signup(payload).then(
      saveCredentials
    ).then(redirectClient),
    [saveCredentials, redirectClient]
  )

  const signupAffiliate = useCallback(
    (payload) => AffiliateInviteService.userInviteSignup(payload).then(
      saveCredentials
    ).then(redirectClient),
    [saveCredentials, redirectClient]
  )

  const providerData = useMemo(() => (
    {
      login,
      oauthLogin,
      signup,
      signupAffiliate,
      redirectClient,
      saveCredentials,
      authKey
    }
  ), [
    login,
    oauthLogin,
    signup,
    signupAffiliate,
    redirectClient,
    saveCredentials,
    authKey
  ])

  return (
    <AuthenticationContext.Provider value={providerData} {...props} />
  )
}
