/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { AvatarCard, Button, Checkbox, ConfirmDialog, LoadingAnimation, UserIcon } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { loadingContainer } from 'assets/styles/global'
import { useUser } from 'Shared/contexts/User'

const container = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
`

const card = css`
  position: relative;
  cursor: pointer;
`

const checkbox = css`
  position: absolute;
  top: 10px;
  left: 10px;
`

const buttonContainer = css`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`

export const CompanyRoleUsers = ({ users = [], loading = false, onRemoveUsers: emitRemoveUsers = identity }) => {
  const { user = {} } = useUser()
  const { id: currentEmployeeId } = user?.company?.current_employee || {}
  const isDisabled = id => currentEmployeeId === id
  const [selectedUsers, setSelectUsers] = useState([])
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const hasSelectedUsers = selectedUsers?.length > 0
  const hasUsers = users?.length > 0
  const userLabel = selectedUsers.length === 1 ? 'usuário' : 'usuários'

  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)

  const isUserSelected = id => selectedUsers.includes(id)

  const onUserSelect = id => () => {
    if (isUserSelected(id)) {
      setSelectUsers(selectedUsers.filter(employeeId => employeeId !== id))
    } else {
      !isDisabled(id) && setSelectUsers([...selectedUsers, id])
    }
  }

  const onRemoveUsers = () => {
    emitRemoveUsers(selectedUsers)
    setSelectUsers([])
    toggleConfirmActionHidden()
  }

  if (loading) return <LoadingAnimation size={30} css={loadingContainer} />

  return (
    <>
      <ConfirmDialog
        title={`Desvinculação de ${userLabel} do papel de permissão`}
        message='Os usuários selecionados serão desvinculados do papel de permissão. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={onRemoveUsers}
        cancelCallback={toggleConfirmActionHidden}
      />

      <div css={container}>
        {hasUsers && users.map(({ avatar, name, employeeId }) => (
          <AvatarCard css={card} key={employeeId} image={avatar} icon={UserIcon} title={name} onClick={onUserSelect(employeeId)}>
            <Checkbox css={checkbox} checked={isUserSelected(employeeId)} disabled={isDisabled(employeeId)} />
          </AvatarCard>
        ))}
      </div>

      {hasSelectedUsers &&
        <div css={buttonContainer}>
          <Button onClick={toggleConfirmActionVisible}>{`Desvincular ${userLabel}`}</Button>
        </div>}
    </>
  )
}
