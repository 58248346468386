/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { formatMoney, identity } from '@bonitour/common-functions'
import {
  CircleThumbnail,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'
import { jsx } from '@emotion/core'
import { affiliateStatusMap } from '../constants/affiliateStatus'
import { affiliatesListStyles, statusContainer } from './AffiliatesListingTable.style'
import { SafeOverflow } from 'Shared/components/SafeOverflow'
import { AffiliateActionsCell } from './AffiliateActionsCell'
import { ComissionDetails } from '../AffiliateComissionDetails'

const AffiliateStatusCell = ({ status = '' }) => {
  return (
    <span css={statusContainer}>
      <CircleThumbnail size={10} style={{ backgroundColor: affiliateStatusMap[status][1] }} />
      {affiliateStatusMap[status][0]}
    </span>
  )
}

export const AffiliatesListingTable = ({
  reloadAffiliates = identity,
  onReinviteAffiliate = identity,
  affiliates = [],
  affiliateResendLoadingIds = []
}) => {
  const { push } = useHistory()

  const stopPropagation = useCallback(
    event => event.stopPropagation(),
    []
  )
  const onRowClick = useCallback(
    affiliate => () => affiliate.status !== 'pending' && push(`/app/affiliates/${affiliate.id}`),
    [push]
  )

  return (
    <div css={affiliatesListStyles}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Nome do afiliado</TableHeaderCell>
            <TableHeaderCell>Data de criação</TableHeaderCell>
            <TableHeaderCell>Comissão</TableHeaderCell>
            <TableHeaderCell>
              <div className='affiliate__value_box'>Valor total de vendas</div>
            </TableHeaderCell>
            <TableHeaderCell>
              <div className='affiliate__value_box'>Valor total da comissão</div>
            </TableHeaderCell>
            <TableHeaderCell>
              <div className='affiliate__value_box'>Valor já disponível</div>
            </TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Ações</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {affiliates?.map((affiliate) => (
            <TableBodyRow key={affiliate.id} className={`status__${affiliate.status}`} onClick={onRowClick(affiliate)}>
              <TableBodyCell>
                <div className='affiliate__name'>
                  <SafeOverflow text={affiliate.name || affiliate.email || '--'} />
                </div>
              </TableBodyCell>
              <TableBodyCell>
                {affiliate?.acceptDate}
              </TableBodyCell>
              <TableBodyCell>
                <ComissionDetails
                  percentage={affiliate?.percentage}
                  percentageByService={affiliate?.percentageByService}
                />
              </TableBodyCell>
              <TableBodyCell>
                <div className='affiliate__value_box'>
                  {formatMoney(affiliate.totalSoldValue)}
                </div>
              </TableBodyCell>
              <TableBodyCell>
                <div className='affiliate__value_box'>
                  {formatMoney(affiliate.totalCommissionValue)}
                </div>
              </TableBodyCell>
              <TableBodyCell>
                <div className='affiliate__value_box'>
                  {formatMoney(affiliate.totalAvailableValue)}
                </div>
              </TableBodyCell>
              <TableBodyCell>
                <AffiliateStatusCell status={affiliate.status} />
              </TableBodyCell>
              <TableBodyCell className='affiliate__actions_cell' onClick={stopPropagation}>
                <AffiliateActionsCell
                  affiliate={affiliate}
                  reloadAffiliates={reloadAffiliates}
                  onReinviteAffiliate={onReinviteAffiliate}
                  affiliateResendLoadingIds={affiliateResendLoadingIds} />
              </TableBodyCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}
