import { parseAttribute, getImageUrl } from 'Core/Service/Orb/Service'
import { parseActivityAbout } from 'Core/Parser/activityAbout'
import { parseActivityRules } from 'Core/Parser/activityRules'
import { AddressParser } from 'Core/Parser/Address'
import { activityIdParser } from 'Core/Parser/activityId'
import { activityBasicInfoParser } from 'Core/Parser/activityBasicInfo'
import { activityComplementaryInfoParser } from 'Core/Parser/ActivityComplementaryInfo'

const extractInfo = ({
  title: name,
  id,
  image,
  is_partner: isPartner,
  company: { id: companyId, name: companyName } = {},
  restrictions,
  service_time: duration
}) => ({
  name,
  id,
  image: getImageUrl(image),
  isPartner,
  companyId,
  companyName,
  minAge: restrictions?.min_age,
  duration
})

export const parseActivityList = (list = []) => list.map(parseAttribute(extractInfo))

const activityParser = (activity = {}) => {
  const activityRegistry = activityBasicInfoParser(activity)
  const activityId = activityIdParser(activity)
  const activityAbout = parseActivityAbout(activity)
  const activityRules = parseActivityRules(activity)
  const activityComplementaryInfo = activityComplementaryInfoParser(activity)
  const addressInfo = AddressParser(activity)
  const { contacts } = activity

  return {
    activityBase: {
      activityId,
      activityRegistry,
      addressInfo,
      ...contacts
    },
    activityAbout: {
      activityAbout,
      activityRules
    },
    activityComplementaryInfo
  }
}

export const parseActivity = activity => parseAttribute(activityParser)(activity)

const restrictionsParser = (activity = {}) => {
  const activityRules = parseActivityRules(activity)
  const { restrictions } = activityRules

  return {
    ...restrictions
  }
}

export const ActivityParser = {
  instance ({ service = {} }) {
    return activityParser(service)
  },
  restrictions ({ service = {} }) {
    return restrictionsParser(service)
  },
  list ({ services = [] }) {
    return services.map(extractInfo)
  }
}
