/** @jsxRuntime classic */
/** @jsx jsx */
import { Button, Dialog, GhostButton, HomeIcon, LogoutIcon, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { UserInviteService } from 'Core/Service/UserInvite/Service'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AffiliateWelcomeMessage } from './AffiliateWelcomeMessage'
import { AffiliateWelcomeForm } from './AffiliateWelcomeForm'
import { affiliateInviteContainer, closeDialogCss, dialogCss } from './AffiliateWelcome.style'
import { useParams } from 'react-router-dom'
import { AuthenticationProvider, useAuthentication } from 'Shared/contexts/Authentication'
import { UserProvider, useUser } from 'Shared/contexts/User'
import { CompanyProvider } from 'Shared/contexts/Company'
import { AuthorizationProvider, useAuthorization } from 'Shared/contexts/Authorization'
import { ShortcutItem } from 'Domains/Layout/Shortcuts'

const RequireLogoutDialog = () => {
  const { user, authKey } = useUser()
  const hasUser = useMemo(() => Boolean(authKey), [authKey])
  const { logout } = useAuthorization()
  const goHome = useCallback(() => { window.location = '/' }, [])

  return (
    <Dialog isVisible={hasUser} title='Logout necessário!' customContainercss={dialogCss} customButtonCss={[closeDialogCss]}>
      <p>Você já está logado{user?.email && ` com o email ${user?.email}`}.</p>
      <p>Para visualizar o convite de parceria, é necessário sair da conta atual!</p>
      <Button type="button" onClick={logout} className='primary'>
        <ShortcutItem icon={LogoutIcon} label='Sair' />
      </Button>
      <GhostButton type="button" onClick={goHome}>
        <ShortcutItem icon={HomeIcon} label='Voltar ao início' />
      </GhostButton>
    </Dialog>
  )
}

export const AffiliateWelcome = () => {
  useAuthentication()
  const { token } = useParams()

  const { add: addToast } = useToast()
  const [affiliateInvite, setAffiliateInvite] = useState({})

  useEffect(() => {
    UserInviteService.get(token).then(setAffiliateInvite).catch(() => addToast('Ocorreu um erro ao buscar pelo convite'))
  }, [token, addToast])

  return (
    <UserProvider>
      <CompanyProvider>
        <AuthenticationProvider>
          <AuthorizationProvider requireLogin={false}>
            <div css={affiliateInviteContainer}>
              <AffiliateWelcomeMessage companyName={affiliateInvite?.companyName} percentage={affiliateInvite?.affiliatePercentage} />
              <AffiliateWelcomeForm token={token} affiliate={affiliateInvite} />
              <RequireLogoutDialog/>
            </div>
          </AuthorizationProvider>
        </AuthenticationProvider>
      </CompanyProvider>
    </UserProvider>
  )
}
