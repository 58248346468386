export const AddressParser = (activity = {}) => {
  const { address: addressContent = {}, timezone } = activity
  const {
    city_id: city,
    state_id: state,
    country_id: country,
    post_code: zipCode,
    street: address,
    region: district,
    supplement: addressDetail,
    latitude,
    longitude
  } = addressContent

  const latLong = `${latitude},${longitude}`

  return {
    city,
    state,
    country,
    zipCode,
    address,
    district,
    addressDetail,
    latLong,
    timezone
  }
}
