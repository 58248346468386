import { css } from '@emotion/core'
import { colors, flexColumnCentered } from '@bonitour/components'

export const overrideBaseLayout = css`
  max-width: none;
  padding: 0;
  background-color: ${colors.gray15};
`

export const container = css`
  width: 100%;
  height: 100%;
  ${flexColumnCentered}
  align-self: center;
  align-items: inherit;
  max-width: 468px;
`

export const formSubmitGroup = css`
  margin: 0 20px;
`
