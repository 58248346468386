import { orbCore } from 'Core/Service/Orb/Service'
import { PlanParser } from 'Core/Parser/Plan'
import { PlanModel } from 'Core/Models/Plan'

const { planDomain } = orbCore

export const PlanService = {
  getById (companyId) {
    return planDomain.get(companyId).then(PlanParser)
  },
  create (companyId, plan) {
    return planDomain.create(companyId, PlanModel(plan)).then(PlanParser)
  }
}
