import { Login } from 'Domains/Login/Login'
import { Signup } from 'Domains/Signup/Signup'
import { AuthLayout } from 'Domains/AuthLayout/AuthLayout'
import { UserInvite } from 'Domains/Auth/UserInvite/UserInvite'
import { InviteError } from 'Domains/Auth/UserInvite/InviteError'
import { ForgotPassword } from 'Domains/ForgotPassword/ForgotPassword'
import { ResetPassword } from 'Domains/ResetPassword/ResetPassword'
import { CompanyTerms, TouristTerms, PrivacyPolicy, CookiePolicy } from 'Domains/Terms/TermTypes'
import { TicketCanceledConfirmation } from 'Domains/Confirmation/TicketCanceled/TicketCanceledConfirmation'
import { AffiliateWelcome } from 'Domains/Affiliate/AffiliateWelcome/AffiliateWelcome'
import { PublicLayout } from 'Domains/PublicLayout/PublicLayout'
import { ProfileSelector } from 'Domains/ProfileSelector/ProfileSelector'
import { SocialLoginCallback } from 'Domains/Login/SocialLoginCallback'

export const publicRoutes = [
  {
    path: '/termo-de-uso',
    component: CompanyTerms
  },
  {
    path: '/termo-de-uso-viajante',
    component: TouristTerms
  },
  {
    path: '/politica-de-privacidade',
    component: PrivacyPolicy
  },
  {
    path: '/politica-de-cookies',
    component: CookiePolicy
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/login',
        component: Login
      },
      {
        path: '/social-login-callback',
        component: SocialLoginCallback
      },
      {
        path: '/profiles',
        component: ProfileSelector
      },
      {
        path: '/signup',
        component: Signup
      },
      {
        path: '/forgot-password',
        component: ForgotPassword
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword
      },
      {
        path: '/user-invite-error',
        component: InviteError
      },
      {
        path: '/user-invite/:invite',
        exact: true,
        component: UserInvite
      }
    ]
  },
  {
    path: '/welcome',
    component: PublicLayout,
    routes: [
      {
        path: '/affiliate/:token',
        component: AffiliateWelcome
      }
    ]
  },
  {
    path: '/confirm/canceled-ticket/:ticketId',
    component: TicketCanceledConfirmation
  },
  {
    redirect: true,
    path: '/auth/login'
  }
]
