/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { useForm } from '@bonitour/app-functions'
import { AddLabel, Button, Card, Dialog, EditInfoIcon, flexHorizontallyCentered, H2, HorizontalInputFormGroup, MailIcon, P, ToggleInputGroup, useToast } from '@bonitour/components'
import { bold, addLabel, toggle, emailsRecipient, emailsRecipientContainer, mailIcon, mailLabel, editIcon, alertTitle } from './AlertConfigForm.styles'
import { alertConfigSchema } from './AlertConfigForm.schema'
import { identity } from '@bonitour/common-functions'
import { AlertsConfigRecipient } from './AlertsConfigRecipient'
import { marginBottom, marginTop } from 'assets/styles/global'
import { alertTypeMap } from './constants/alertTypeMap'

const marginTop20 = marginTop(20)
const marginBottom20 = marginBottom(20)

export const AlertConfigForm = ({ alert = {}, employees = [], onSave: emitSave = identity }) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(alert, alertConfigSchema)
  const { alertType, active, employeesIds = [], externalContacts = [] } = form
  const [isAlertOpen, setAlertOpen] = useState(true)
  const [isRecipientOpen, setRecipientOpen] = useState(false)
  const recipientsLength = externalContacts?.length + employeesIds?.length || 0
  const hasEmailRecipient = recipientsLength > 0
  const { label, detail } = alertTypeMap[alertType] || {}

  const onAlertToggle = () => setAlertOpen(!isAlertOpen)

  const onRecipientOpen = () => setRecipientOpen(true)

  const onRecipientClose = () => setRecipientOpen(false)

  const onToggleClick = () => onInputChange('active')(!active)

  const onValidationError = () => addToast('Preencha o formulário corretamente')

  const onSaveClick = () => {
    if (active === true && recipientsLength === 0) {
      return addToast(`Informe ao menos um destinatário para o alerta de ${label.toLowerCase()}`)
    } else {
      onSubmit(emitSave(form), onValidationError)
    }
  }

  return (
    <Card css={marginBottom20}>
      <H2 css={alertTitle} onClick={onAlertToggle}>{label}</H2>
      {isAlertOpen &&
        <div css={marginTop20}>
          <HorizontalInputFormGroup id={alertType} errorMessage={errors.active}>
            <ToggleInputGroup css={toggle} checked={active} onChange={onToggleClick}>
              {label}
            </ToggleInputGroup>
          </HorizontalInputFormGroup>
          <Card>
            <P>{detail}</P>
            {!hasEmailRecipient &&
              <div>
                <AddLabel css={addLabel} onClick={onRecipientOpen}>Adicionar destinatários</AddLabel>
              </div>}
            {hasEmailRecipient &&
              <div>
                <P css={bold}>Destinatários a serem alertados:</P>
                <div css={emailsRecipientContainer} onClick={onRecipientOpen}>
                  <p css={emailsRecipient}>{recipientsLength === 1 ? `${recipientsLength} e-mail` : `${recipientsLength} e-mails`}</p>
                  <EditInfoIcon css={editIcon} />
                </div>
              </div>}
            <P css={bold}>Alertar via:</P>
            <div css={[flexHorizontallyCentered, marginBottom20]}>
              <MailIcon css={mailIcon} />
              <p css={mailLabel}>E-mail</p>
            </div>
            <Button onClick={onSaveClick}>Salvar</Button>
          </Card>
        </div>}

      <Dialog title={label} isVisible={isRecipientOpen} onClose={onRecipientClose}>
        <AlertsConfigRecipient
          employees={employees}
          employeesIds={employeesIds}
          externalContacts={externalContacts}
          onExternalContactChange={onInputChange('externalContacts')}
          onEmployeesIdsChange={onInputChange('employeesIds')}
          onClose={onRecipientClose}
        />
      </Dialog>
    </Card>
  )
}
