/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { EmptyResult } from '@bonitour/components'

export const InviteError = () => (
  <EmptyResult
    size={258}
    title='Convite não encontrado'
    subtitle='Convite expirou ou não foi encontrado o código'
  />
)
