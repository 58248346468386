/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H3, H4, flex, InfoSwatch, Row, Column, flexColumn, EmailInputWithSuffix, PhoneSelector } from '@bonitour/components'
import { ContactPhone } from './ContactPhone/ContactPhone'
import { ContactEmail } from './ContactEmail/ContactEmail'
import { identity } from '@bonitour/common-functions'
import { AddLabel } from 'Shared/components/AddLabel'
import { infoIcon, inputContainer, inputStyle, marginBottom20, marginBottom30, marginTop50, phoneInput } from './ContactForm.style'
import { required } from 'assets/styles/global'

const emptyPhone = { title: '', number: '' }
const emptyEmail = { name: '', email: '' }

export const InputWithInfo = ({ inputTitle, tooltipText }) => {
  return (
    <div css={inputContainer}>
      <span css={inputStyle}>
        <H4 css={marginBottom20}>{inputTitle}</H4>
        <InfoSwatch tooltip={tooltipText} size={250} css={infoIcon} />
      </span>
    </div>
  )
}

export const ContactForm = ({
  phones: phoneList = [],
  emails: emailList = [],
  businessEmail = '',
  whatsapp = '',
  phonesErrors = [],
  emailsErrors = [],
  businessEmailErrors = '',
  whatsappErrors = '',
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  onPhoneRemove: emitPhoneRemove = identity,
  onPhoneAdd: emitPhoneAdd = identity,
  onEmailAdd: emitEmailAdd = identity,
  onEmailRemove: emitEmailRemove = identity
}) => {
  const addPhone = () => emitPhoneAdd(emptyPhone)
  const removePhone = index => () => emitPhoneRemove(index)
  const addEmail = () => emitEmailAdd(emptyEmail)
  const removeEmail = index => () => emitEmailRemove(index)

  return (
    <>
      <H3 css={[marginTop50, marginBottom20]}>Contato</H3>
      <H4 css={marginBottom20}>
        E-mail
        <span css={required}>(Obrigatório)</span>
      </H4>
      <div css={marginBottom20}>
        {emailList.map((email, index) => (
          <ContactEmail
            key={`contact-email-${index}`}
            email={email}
            errors={emailsErrors[index]}
            index={index}
            onBlur={emitBlurEvent}
            onChange={emitChangeEvent}
            onDelete={removeEmail(index)}
          />
        ))}
      </div>
      <AddLabel css={[flex, marginBottom30]} onClick={addEmail}>Novo e-mail</AddLabel>
      <H4 css={marginBottom20}>
        Telefone
        <span css={required}>(Obrigatório)</span>
      </H4>
      <div css={marginBottom20}>
        {phoneList.map((phone, index) => (
          <ContactPhone
            key={`contact-phone-${index}`}
            contact={phone}
            errors={phonesErrors[index]}
            index={index}
            onBlur={emitBlurEvent}
            onChange={emitChangeEvent}
            onDelete={removePhone(index)}
          />
        ))}
      </div>
      <AddLabel css={[flex, marginBottom30]} onClick={addPhone}>Novo telefone</AddLabel>
      <Row>
        <Column phone={12} desktop={6} css={[flexColumn, marginBottom20]}>
          <InputWithInfo
            inputTitle='Email de atendimento'
            tooltipText='O email de atendimento será exibido para os viajantes no rodapé dos emails e no aplicativo Binamik Ally'
          />
          <div css={flexColumn}>
            <EmailInputWithSuffix
              id='businessEmail'
              value={businessEmail}
              onChange={emitChangeEvent('businessEmail')}
              onBlur={emitBlurEvent('businessEmail')}
              placeholder='contato@empresa.com.br'
              error={Boolean(businessEmailErrors)}
            >
              Opcional
            </EmailInputWithSuffix>
          </div>
        </Column>
        <Column phone={12} desktop={6} css={flexColumn}>
          <InputWithInfo
            inputTitle='Whatsapp'
            tooltipText='O contato de whatsapp  será exibido para os viajantes no rodapé dos emails e no aplicativo Binamik Ally'
          />
          <div css={flexColumn}>
            <PhoneSelector
              customCss={[phoneInput]}
              value={whatsapp || ''}
              error={Boolean(whatsappErrors)}
              onBlur={emitBlurEvent('whatsapp')}
              onChange={emitChangeEvent('whatsapp')}
            />
          </div>
        </Column>
      </Row>
    </>
  )
}
