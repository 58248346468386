import { useState, useCallback } from 'react'
import { UserService } from 'Core/Service/User'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'Shared/hooks/usePermissions'

const DEFAULT_PAGINATION = { currentPage: 1, perPage: 10 }

export const usePaginatedUsers = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [usersInfo, setUsers] = useState({})
  const [filters, setFilters] = useState({})
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [isLoading, setIsLoading] = useState(false)

  const fetchUserCompanies = useCallback(({ filters, pagination }) => {
    if (isLoading) return
    setIsLoading(true)
    return UserService.getPaginatedList(filters, pagination)
      .then(setUsers)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos usuários')
        hasPermission && addToast('Ocorreu um erro ao buscar os usuários')
      })
      .finally(() => setIsLoading(false))
  }, [addToast, handlePermission, isLoading])

  const onFiltersSubmit = useCallback((newFilters) => {
    setFilters(newFilters)
    setPagination(DEFAULT_PAGINATION)
    fetchUserCompanies({ filters: newFilters, pagination: DEFAULT_PAGINATION })
  }, [fetchUserCompanies])

  const onPaginationChange = useCallback((currentPage, perPage = pagination.perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) return
    setPagination({ currentPage, perPage })
    fetchUserCompanies({ filters, pagination: { currentPage, perPage } })
  }, [fetchUserCompanies, filters, pagination.currentPage, pagination.perPage])

  return {
    onPaginationChange,
    onFiltersSubmit,
    usersInfo,
    pagination,
    isLoading
  }
}
