export const activityBasicInfoParser = (activity = {}) => {
  const {
    document: cnpj,
    name: companyName,
    alias_name: fantasyName,
    state_registration: stateRegistrationCode,
    municipal_registration: municipalRegistrationCode,
    cadastrur: cadastur
  } = activity

  return {
    cnpj: cnpj || '',
    companyName: companyName || '',
    fantasyName: fantasyName || '',
    stateRegistrationCode: stateRegistrationCode || '',
    municipalRegistrationCode: municipalRegistrationCode || '',
    cadastur: cadastur || ''
  }
}
