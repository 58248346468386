import { useState, useCallback, useEffect } from 'react'
import { UserService } from 'Core/Service/User'

export const useUserList = () => {
  const [users, setUsers] = useState([])

  const fetchUsers = useCallback(() => {
    UserService.getList().then(({ users }) => setUsers(users))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return {
    users,
    fetchUsers
  }
}
