/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Terms } from '../Terms'

export const PrivacyPolicy = () => {
  return (
    <Terms type='privacy_policy' />
  )
}
