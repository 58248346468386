/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { AvatarCard, ToggleInputGroup, UserIcon, colors, LoadingAnimation, Card, flexColumnCentered, TooltipOnHover } from '@bonitour/components'
import { head, identity } from '@bonitour/common-functions'
import { useUser } from 'Shared/contexts/User'
import { useState } from 'react'
import { clickable, marginBottom } from 'assets/styles/global'

const marginBottom10 = marginBottom(10)

const card = css`
  margin: 10px;    
  width: 225px;
  min-width: 225px;
  max-width: 225px;

  p {
    width: 100%;
    overflow-wrap: anywhere;
  }
`

const baseCard = css`
  width: 100%;
  height: 285px;
  text-align: center;
  max-width: 230px;
  box-sizing: border-box;
  color: ${colors.gray3};
  font-size: 15px;
  ${flexColumnCentered};
  ${card};
`

const cardDisabled = css`
  background-color: ${colors.gray11};
`

export const UserCard = ({
  id,
  name,
  image,
  roles,
  isActive,
  loadingToggleActiveId,
  onClickUser: emitClickUser = identity,
  onClickToggleActive: emitClickToggleActive = identity,
  onPagination: emitPaginationEvent = identity
}) => {
  const { user = {} } = useUser()
  const [active, setActive] = useState(isActive)
  const { id: currentEmployeeId } = user?.company?.current_employee || {}
  const isDisabled = currentEmployeeId === id
  const hasMoreThanOneRole = roles.length > 1
  const description = roles.join(', ')

  const onUserClick = (id, event) => {
    event.stopPropagation()
    emitClickUser(id)
  }

  const onToggleClick = (id, event) => {
    event.stopPropagation()
    setActive(!active)
    emitClickToggleActive(id, emitPaginationEvent)
  }

  if (loadingToggleActiveId === id) {
    return (
      <Card css={baseCard}>
        <LoadingAnimation />
      </Card>
    )
  }

  return (
    <AvatarCard
      css={[card, clickable, !isActive && cardDisabled]}
      image={image}
      icon={UserIcon}
      title={name || '-'}
      onClick={e => onUserClick(id, e)}
    >
      {!hasMoreThanOneRole && <span css={marginBottom10}>{head(roles)}</span>}
      {hasMoreThanOneRole &&
        <TooltipOnHover text={description} size={180}>
          <span css={marginBottom10}>{`${roles.length} papéis de permissão`}</span>
        </TooltipOnHover>}
      <ToggleInputGroup checked={active} onChange={null} onClick={e => onToggleClick(id, e)} disabled={isDisabled}>
        {active ? 'Ativo' : 'Desativado'}
      </ToggleInputGroup>
    </AvatarCard>
  )
}
