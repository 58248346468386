import { css } from '@emotion/core'
import { colors, flexHorizontallyCentered } from '@bonitour/components'

export const alertTitle = css`
  margin: 0;
  cursor: pointer;
`

export const bold = css`
  font-weight: bold;
`

export const addLabel = css`
  padding: 0;
  margin: 10px 0px 25px;
`

export const toggle = css`
  margin: 20px 0;
`

export const emailsRecipientContainer = css`
  ${flexHorizontallyCentered};
  display: inline-flex;
  margin-bottom: 15px;
  color: ${colors.gray4};
  cursor: pointer;
`

export const emailsRecipient = css`
  font-size: 16px;  
  font-weight: 600;
  margin-right: 5px;
  color: ${colors.gray4};
`

export const editIcon = css`
  font-size: 22px;
  color: ${colors.gray4};
`

export const mailIcon = css`
  font-size: 26px;
  margin-right: 5px;
  color: ${colors.gray4};
`

export const mailLabel = css`
  color: ${colors.gray4};
`
