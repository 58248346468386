/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo, useState } from 'react'
import { useDebounce } from '@bonitour/app-functions'
import { identity, validateEmailAddress } from '@bonitour/common-functions'
import { AddLabel, Button, Input, P, PlusCircleIcon, TrashIcon, useToast } from '@bonitour/components'
import { addRecipient, employeesFilteredContainer, employeeFilteredContainer, addEmployeeIcon, addRecipientLabel, recipientsContainer, recipientItem, recipientItemContainer, removeEmployeeIcon } from './AlertsConfigRecipient.styles'
import { bold } from './AlertConfigForm.styles'

export const AlertsConfigRecipient = ({
  employees = [],
  employeesIds = [],
  externalContacts = [],
  onEmployeesIdsChange: emitEmployeesIdsChange = identity,
  onExternalContactChange: emitExternalContactChange = identity,
  onClose: emitClose = identity
}) => {
  const { add: addToast } = useToast()
  const [query, setQuery] = useState('')
  const queryWithDebounce = useDebounce(query, 200)
  const recipientsLength = externalContacts?.length + employeesIds?.length || 0
  const hasEmailRecipient = recipientsLength > 0

  const recipientEmployee = employeeId => {
    const { name, email } = employees.find(({ id }) => id === employeeId) || {}
    return name || email
  }

  const employeesFiltered = useMemo(() => {
    if (queryWithDebounce) {
      return employees.filter(({ name = '', email = '' }) => {
        const nameQueryLowercase = queryWithDebounce.toLowerCase()
        const nameLowecase = name.toLowerCase()
        const emailLowecase = email.toLowerCase()
        const hasQueryInName = nameLowecase.includes(nameQueryLowercase)
        const hasQueryInEmail = emailLowecase.includes(nameQueryLowercase)
        return hasQueryInName || hasQueryInEmail
      })
    }
    return employees
  }, [queryWithDebounce, employees])

  const hasEmployeeFiltered = employeesFiltered.length > 0

  const onAddEmail = () => {
    if (validateEmailAddress(query)) {
      externalContacts.includes(query) || emitExternalContactChange([...externalContacts, query])
      setQuery('')
    } else {
      addToast('Informe um e-mail válido')
    }
  }

  const onRemoveEmail = email => () => emitExternalContactChange(externalContacts.filter(externalContact => externalContact !== email))

  const onAddEmployee = id => () => employeesIds.includes(id) || emitEmployeesIdsChange([...employeesIds, id])

  const onRemoveEmployee = id => () => emitEmployeesIdsChange(employeesIds.filter(employeeId => employeeId !== id))

  return (
    <>
      <Input css={addRecipient} value={query} onChange={setQuery} placeholder='Adicionar usuário ou e-mail' />

      <div css={employeesFilteredContainer}>
        {hasEmployeeFiltered && employeesFiltered.map(({ id, name }) => (
          <div key={name} css={employeeFilteredContainer} onClick={onAddEmployee(id)}>
            <PlusCircleIcon css={addEmployeeIcon} />
            <p>{name}</p>
          </div>
        ))}
      </div>

      {query && !hasEmployeeFiltered && <AddLabel css={addRecipientLabel} onClick={onAddEmail}>{`Adicionar ${query}`}</AddLabel>}

      <P css={bold}>Destinatários adicionados:</P>
      {!hasEmailRecipient && <P>Nenhum destinatário adicionado</P>}

      <div css={recipientsContainer}>
        {employeesIds.map(employeeId => (
          <div key={employeeId} css={recipientItemContainer}>
            <TrashIcon css={removeEmployeeIcon} onClick={onRemoveEmployee(employeeId)} />
            <p css={recipientItem}>{recipientEmployee(employeeId)}</p>
          </div>
        ))}
        {externalContacts.map(externalContact => (
          <div key={externalContact} css={recipientItemContainer}>
            <TrashIcon css={removeEmployeeIcon} onClick={onRemoveEmail(externalContact)} />
            <p css={recipientItem}>{externalContact}</p>
          </div>
        ))}
      </div>

      <Button onClick={emitClose}>Fechar</Button>
    </>
  )
}
