/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Accordion, CollapsableSection, HeaderPage } from '@bonitour/components'
import { useQuery } from 'Shared/hooks/useQuery'
import { EditCompanyInfo } from './EditCompanyInfo'
import { useCompany } from 'Shared/contexts/Company'

const defaultCursor = css`
  cursor: default;
`

export const EditCompany = () => {
  const { id } = useCompany()
  const { push } = useHistory()
  const { accordion = 1 } = useQuery()
  const setIndicatorActive = accordionValue => push({ search: `?accordion=${accordionValue}` })
  const backToDashboard = () => push('/app')

  useEffect(() => {
    if (!id) push('/app/company/create')
  }, [id, push])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Empresa' />

      <Accordion value={Number(accordion)} onClick={setIndicatorActive}>
        <CollapsableSection
          indicator={1}
          css={defaultCursor}
          title='Informações básicas'
          topics='Registro / Contato / Endereço'
        >
          <EditCompanyInfo onNextStep={backToDashboard} />
        </CollapsableSection>
      </Accordion>
    </>
  )
}
