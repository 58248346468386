import React from 'react'
import { Base } from './Base'
import { Router } from './Router'

import { routes } from 'Routes'
import { ErrorBoundary } from '@sentry/react'
import { SentrySetup } from 'Shared/external/Sentry'
import { ExternalIntegrations } from 'Shared/external/Integrations'

SentrySetup()

ExternalIntegrations()

export const App = (
  <ErrorBoundary>
    <Base>
      <Router routes={routes} />
    </Base>
  </ErrorBoundary>
)
