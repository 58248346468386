/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { inviteSchema } from './InviteForm.schema'
import { identity } from '@bonitour/common-functions'
import { GhostButton, flexRow, useToast, Row, Column, H2, Button, InputFormGroup, Input, CheckboxInputGroup, scrollBar } from '@bonitour/components'
import { marginBottom, marginRight, marginTop } from 'assets/styles/global'

const marginBottom30 = marginBottom(30)
const marginTop50 = marginTop(50)
const marginRight20 = marginRight(20)

const textLeft = css`
  text-align: left;
`

const container = css`
  width: 500px;

  div {
    top: 0 !important;
  }
`

const rolesContainer = css`
  max-height: 160px;
  overflow-y: auto;
  ${scrollBar(5)};
`

export const InviteForm = ({
  roles = [],
  onClose = identity,
  onSuccess = identity,
  isLoading = false
}) => {
  const { add: addToast } = useToast()
  const [inviteForm] = useState({ companyRolesIds: [] })
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(inviteForm, inviteSchema)
  const { email = '', companyRolesIds = [] } = form

  if (roles.length === 0) return null

  const onFailedValidation = () => {
    addToast('Preencha o formulário corretamente')
    if (!companyRolesIds.length) {
      addToast('Adicione a menos um papel para o usuário')
    }
  }

  const onRoleChange = value => () => {
    const { companyRolesIds = [] } = form
    if (companyRolesIds.includes(value)) {
      onInputChange('companyRolesIds')(companyRolesIds.filter(id => id !== value))
    } else {
      onInputChange('companyRolesIds')(companyRolesIds.concat(value))
    }
  }

  const onClick = onSubmit(onSuccess, onFailedValidation)

  return (
    <div css={container}>
      <Row>
        <Column desktop={12} phone={12}>
          <H2 css={marginBottom30}>Convidar usuário</H2>
        </Column>

        <Column desktop={12} phone={12} css={marginBottom30}>
          <InputFormGroup label='E-mail' css={textLeft} errorMessage={errors.email}>
            <Input value={email} onChange={onInputChange('email')} onBlur={onInputBlur('email')} />
          </InputFormGroup>
        </Column>

        <Column desktop={12} phone={12}>
          <InputFormGroup label='Papéis' css={textLeft}>
            <Row css={rolesContainer}>
              {roles.map(({ id, name }) => {
                return (
                  <Column phone={12} desktop={12} key={id}>
                    <CheckboxInputGroup id={id} onChange={onRoleChange(id)} checked={companyRolesIds.includes(id)}>
                      {name}
                    </CheckboxInputGroup>
                  </Column>)
              })}
            </Row>
          </InputFormGroup>
        </Column>

        <Column desktop={12} phone={12}>
          <div css={[flexRow, marginTop50]}>
            <GhostButton css={marginRight20} onClick={onClose}>Cancelar</GhostButton>
            <Button onClick={onClick} disabled={isLoading}>Convidar</Button>
          </div>
        </Column>
      </Row>
    </div>
  )
}
