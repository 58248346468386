import { css } from '@emotion/core'

export const salesListStyles = css`
  padding: 0;
  width: 100%;
  overflow-x: auto;

  .sale__value_box {
    width: 100%;
    max-width: 12ch;
    white-space: normal;
  }
`
