/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H4, HeaderPage } from '@bonitour/components'
import { formatDate, identity } from '@bonitour/common-functions'

import { affiliatePageHeader } from './AffiliateSales.style'

export const AffiliateSalesHeader = ({ onBack = identity, affiliate = {} }) => {
  return (
    <HeaderPage onBack={onBack} title={`Afiliado ${affiliate?.name || affiliate?.email || ''}`} customCss={[affiliatePageHeader]}>
      <H4>Código afiliado: {affiliate?.affiliateCode || '---'}</H4>
      <p>Email: {affiliate?.email ? formatDate(affiliate?.email) : '---'}</p>
      <p>Afiliado desde: {affiliate?.acceptDate ? formatDate(affiliate?.acceptDate) : '---'}</p>
    </HeaderPage>
  )
}
