/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, H2, Label, PaginationContainer, PlusIcon, LoadingTable } from '@bonitour/components'
import { AffiliatesListingTable } from './AffiliatesListingTable/AffiliatesListingTable'
import { affiliatesPageContainer, cardButton, cardIcon, labelCardButton } from './Affiliates.style'
import { AffiliateInviteDialog } from './AffiliatesInvite/AffiliateInviteDialog'
import { CardButton } from 'Shared/components/CardButton'
import { AffiliatesFilter } from './AffiliatesFilter'
import { useAffiliates } from './hooks/useAffiliates'

export const Affiliates = () => {
  const {
    affiliates,
    toggleInvite,
    handleFilterChange,
    reloadAffiliates,
    isInviteOpened,
    meta,
    handlePageChange,
    isLoadingAffiliates,
    reinviteAffiliate,
    affiliateResendLoadingIds
  } = useAffiliates()

  const columns = [
    'Nome do afiliado',
    'Data de criação',
    'Comissão',
    'Valor total de vendas',
    'Valor total da comissão',
    'Valor já disponível',
    'Status'
  ]

  return (
    <div css={affiliatesPageContainer}>
      <H2> Afiliados </H2>
      <CardButton css={cardButton} onClick={toggleInvite}>
        <PlusIcon css={cardIcon} />
        <Label css={labelCardButton}>Novo afiliado</Label>
      </CardButton>
      <AffiliatesFilter onFilter={handleFilterChange} />
      <AffiliateInviteDialog isVisible={isInviteOpened} onClose={toggleInvite} />
      <PaginationContainer
        total={meta.totalEntries}
        pagesQuantity={meta.totalPages}
        onPagination={handlePageChange}
        currentPage={meta.page}
        isLoading={isLoadingAffiliates}
      >
        {isLoadingAffiliates
          ? (
            <LoadingTable columns={columns} elementsCount={meta.perPage} />
          )
          : (
            <Card className="list__card">
              <AffiliatesListingTable affiliates={affiliates} reloadAffiliates={reloadAffiliates} onReinviteAffiliate={reinviteAffiliate} affiliateResendLoadingIds={affiliateResendLoadingIds}/>
            </Card>
          )}
      </PaginationContainer>
    </div>
  )
}
