export const UserInviteParser = {
  instance (userInvite = {}) {
    const {
      user_email: userEmail,
      binamik_roles: binamikRoles,
      company_roles: companyRoles,
      token,
      company_id: companyId,
      invited_at: invitedAt,
      answered_at: answeredAt,
      company_name: companyName,
      company_roles_names: companyRolesNames,
      affiliate_percentage: affiliatePercentage
    } = userInvite
    return {
      userEmail,
      binamikRoles,
      companyRoles,
      token,
      companyId,
      invitedAt,
      answeredAt,
      companyName,
      companyRolesNames,
      affiliatePercentage
    }
  }
}
