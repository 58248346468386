import { colors, flexColumnCentered } from '@bonitour/components'
import { css } from '@emotion/core'

export const headerLogo = css`
  display: flex;
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 5px #000000cc;
`

export const logo = css`
  margin: auto;
`
export const overrideBaseLayout = css`
  max-width: none;
  padding: 0;
  background-color: ${colors.gray15};
`

export const container = css`
  width: 100%;
  height: 100%;
  ${flexColumnCentered}
  align-self: center;
  align-items: inherit;
  max-width: 1200px;
  margin-bottom: 50px;
`
export const terms = css`
  text-align: justify;
  line-height: 1.5;
  font-size: 16px;
  color: ${colors.gray1};

  span {
    font-weight: normal;
  }

  p {
    margin: revert;
    font: revert;

    strong {
      font: revert;
    }
  }

  b {
    font: revert;
  }

  ul {
    padding: revert;

    li {
      list-style: disc;
    }
  }

  h2 {
    font-size: revert;
    font: revert;
    margin: revert;
  }

  h3 {
    font-size: revert;
    font: revert;
    margin: revert;
  }

`

export const termContainer = css`
  @media screen and (max-width: 1240px){
    margin: 0 25px;
  }
`
