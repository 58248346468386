import { useHistory, useLocation } from 'react-router-dom'
import { getQueryParams } from '@bonitour/common-functions'
import { useCallback, useMemo } from 'react'

export const useQuery = () => {
  const { push } = useHistory()
  const { search = '' } = useLocation()
  const queryParams = useMemo(() => getQueryParams(search), [search])

  const changeQueryParam = useCallback((key, value) => {
    const newParams = { ...queryParams, [key]: value }
    push({ search: new URLSearchParams(newParams).toString() })
  }, [push, queryParams])

  const values = useMemo(() => ({ ...queryParams, changeQueryParam }), [queryParams, changeQueryParam])

  return values
}
