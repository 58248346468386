/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, LoadingTable, Select, Row, Column, InputFormGroup, Button, PaginationBar } from '@bonitour/components'
import { SalesListingTable } from './SalesListingTable/SalesListingTable'
import { identity } from '@bonitour/common-functions'
import { useCallback, useMemo, useState } from 'react'
import { ExportFile } from 'Shared/components/ExportFile'
import { FORMAT_OPTIONS } from './constants/AffiliateSalesExport'
import { useAffiliateSalesExport } from './hooks/useAffiliateSalesExport'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useAffiliate } from 'Domains/Affiliate/hooks/useAffiliate'
import { AffiliateTicketStatus } from './constants/AffiliateTicketStatus'
import { submitButton, paginationBarStyle, loadingTableStyle } from './AffiliateSales.style'
import { useForm } from '@bonitour/app-functions'
import { AffiliateSalesFilterSchema } from './form/filter.schema'
import { InfoTotalizerField, InfoTotalizerModal } from 'Shared/components/InfoTotalizer'

const AffiliateFilter = ({
  onSubmit: onFilterSubmit = identity,
  filters = {}
}) => {
  const {
    form,
    onSubmit,
    errors,
    utils: { onInputChange }
  } = useForm(filters, AffiliateSalesFilterSchema)

  const { ticketStatus } = form

  const handleSubmit = onSubmit(onFilterSubmit)

  const [modalData, setModalData] = useState({
    title: '',
    content: '',
    isVisible: false
  })

  const toggleModal = useCallback((additionalInfo = {}) => () => setModalData((prev) => ({
    ...prev,
    isVisible: !prev.isVisible,
    ...additionalInfo
  })), [])

  const affiliateInfoLabel = useMemo(() => <InfoTotalizerField title={'Comissão'} content='Filtro referente a comissão de ingressos do afiliado.' toggleModal={toggleModal}>
    Comissão
  </InfoTotalizerField>, [toggleModal])

  return (
    <Row className="list__card__row">
      <Column phone={12} tabletlandscape={12} desktop={4}>
        <InputFormGroup label={affiliateInfoLabel} errorMessage={errors.ticketStatus}>
          <Select
            options={AffiliateTicketStatus}
            value={ticketStatus}
            onChange={onInputChange('ticketStatus')}
          />
        </InputFormGroup>
      </Column>
      <Column phone={12} tabletlandscape={12} desktop={3}>
        <Button onClick={handleSubmit} css={submitButton}>
          Pesquisar
        </Button>
      </Column>
      <InfoTotalizerModal content={modalData.content} title={modalData.title} isVisible={modalData.isVisible} onClose={toggleModal()} />
    </Row>
  )
}

export const AffiliateSalesBody = ({
  onPageChange = identity,
  onPerPageChange = identity,
  onFilterSubmit = identity,
  sales,
  meta,
  isLoading = false,
  filters = {}
}) => {
  const { affiliate } = useAffiliate()

  const { companyId, affiliateId } = useParams()

  const isByCommonAffiliate = useMemo(() => Boolean(affiliateId), [affiliateId])

  const entityId = useMemo(
    () => companyId || affiliate?.id || affiliateId,
    [affiliate?.id, affiliateId, companyId]
  )

  const { isLoading: isLoadingSalesExport, onExport } = useAffiliateSalesExport({
    entityId,
    isCommonFetch: isByCommonAffiliate
  })

  const columns = [
    'Data da venda',
    'Código da reserva',
    'Código do ticket',
    'Nome da experiência',
    'Valor do ticket',
    'Valor da comissão'
  ]

  return (
    <Card className="list__card" padding={0}>
      <Card>
        <AffiliateFilter onSubmit={onFilterSubmit} filters={filters} />
        {isLoading
          ? (
            <LoadingTable elementsCount={meta.perPage} columns={columns} customCss={[loadingTableStyle]} />
          )
          : (
            <>
              <ExportFile
                onSubmitExport={onExport}
                isLoading={isLoadingSalesExport}
                formatOptions={FORMAT_OPTIONS}
                isEnabledColumnSelect={false}
                filters={filters}
              />
              <SalesListingTable sales={sales} />
            </>
          )}
        <PaginationBar
          currentPage={meta.page}
          perPage={meta.perPage}
          totalPages={meta.totalPages}
          totalElements={meta.totalEntries}
          perPageOptions={[5, 10, 25, 50, 100]}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          customCss={[paginationBarStyle]}
        />
      </Card>
    </Card>
  )
}
