/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { AvatarCard, ConfirmDialog, PartnersIcon, TooltipOnHover, TrashIcon } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { clickable } from 'assets/styles/global'
import { useState } from 'react'

const card = css`
  width: 225px;
  min-width: 225px;
  max-width: 225px;
  margin: 10px;
`

const icon = css`
  font-size: 20px;
`

export const RoleCard = ({
  name = '',
  id = '',
  employeesCount = 0,
  onRoleCardClick: emitRoleCardClick = identity,
  onRoleDelete: emitRoleDelete = identity
}) => {
  const hasEmployees = employeesCount > 0
  const description = employeesCount === 1 ? `${employeesCount} usuário` : `${employeesCount} usuários`
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)

  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const onRoleDelete = () => emitRoleDelete(id)

  const onRoleClick = (id, event) => {
    event.stopPropagation()
    emitRoleCardClick(id)
  }

  const toggleConfirmActionVisible = event => {
    event.stopPropagation()
    setConfirmActionVisible(true)
  }

  return (
    <>
      <ConfirmDialog
        title='Exclusão de papel de permissão'
        message='Esse papel de permissão será excluído permanentemente. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={onRoleDelete}
        cancelCallback={toggleConfirmActionHidden}
      />

      <AvatarCard
        css={[card, clickable]}
        icon={PartnersIcon}
        title={name}
        description={description}
        onClick={event => onRoleClick(id, event)}
      >
        {!hasEmployees &&
          <TooltipOnHover text='Excluir papel de permissão' size={150}>
            <TrashIcon css={icon} onClick={event => toggleConfirmActionVisible(event)} />
          </TooltipOnHover>}
      </AvatarCard>
    </>
  )
}
