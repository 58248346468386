import { css } from '@emotion/core'
import { colors, flexColumn } from '@bonitour/components'

export const floating = css`
  align-items: flex-end;
  margin-bottom: 30px;
  position: fixed;
  bottom: 40px;
  right: 60px;
`

export const buttonContainer = css`
  margin-bottom: 10px;
  margin-right: 5px;
`

export const tagStyle = css`
  background-color: ${colors.gray6};
  color: ${colors.white};
  padding: 8px;
  margin-right: 10px;
  font-size: 14px;
`

export const whiteButton = css`
  background-color: ${colors.white};
  color: ${colors.gray3};
  border-color: transparent;
  box-shadow: 0 0 5px 0.02em rgba(0, 0, 0, 0.25);
`

export const fontSize20 = css`
  font-size: 20px;
`

export const closeButton = css`
  background-color: ${colors.gray6};
  color: ${colors.white};
`

export const popoverContainer = css`
  ${flexColumn};
  align-items: flex-start;
  padding: 30px 20px;
  background-color: ${colors.white};
  overflow-y: auto;
`

export const invitePartnerContainer = css`
  width: 430px;
`

export const padding0 = css`
  padding: 0;
`

export const cardButton = css`
  width: 115px;
  margin: 25px 0 0 10px;
  padding: 10px 20px;

  label {
    width: 100%;
  }
`

export const cardIcon = css`
  width: 22px;
  height: 22px;
  padding: 5px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: var(--round-element-radius, 100%);
`

export const labelCardButton = css`
  cursor: pointer;  
`
