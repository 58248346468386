import { useEffect, useState, useCallback } from 'react'
import { useToast } from '@bonitour/components'
import { IntegrationService } from 'Core/Service/Integration'
import { useParams } from 'react-router'

export const useExpandIntegration = () => {
  const [loading, setLoading] = useState(false)
  const [integration, setIntegration] = useState()
  const { integrationId } = useParams()
  const { add: addToast } = useToast()

  const fetchIntegration = () => {
    setLoading(true)
    IntegrationService.getById(integrationId).then(setIntegration).finally(() => setLoading(false))
  }

  const deleteActivity = useCallback(async (integrationId, integrationActivityId) => {
    try {
      await IntegrationService.remove(integrationId, integrationActivityId)
      addToast('Atividade desvinculada com sucesso', 'success')
      fetchIntegration()
    } catch (error) {
      addToast('Erro ao desvincular atividade')
    }
    // eslint-disable-next-line
  }, [addToast])

  const onEditIntegration = useCallback(async (integrationId, integration) => {
    try {
      await IntegrationService.update(integrationId, integration)
      addToast('Autenticação alterada com sucesso', 'success')
      fetchIntegration()
    } catch (error) {
      addToast('Ocorreu um erro ao editar a integração')
    }
    // eslint-disable-next-line
  }, [addToast])

  useEffect(() => {
    fetchIntegration()
    // eslint-disable-next-line
  }, [integrationId])

  return {
    integration,
    loading,
    fetchIntegration,
    deleteActivity,
    onEditIntegration
  }
}
