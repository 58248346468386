import { orbCore } from 'Core/Service/Orb/Service'
import { BinamikInvoiceParser } from 'Core/Parser/BinamikInvoice'
import { BinamikInvoiceModel } from 'Core/Models/BinamikInvoice'

const { binamikInvoiceDomain } = orbCore

export const BinamikInvoiceService = {
  list ({ filters, pagination } = {}) {
    return binamikInvoiceDomain.list(BinamikInvoiceModel.list({ filters, pagination })).then(BinamikInvoiceParser.list)
  },
  getInvoiceById (invoiceId) {
    return binamikInvoiceDomain.getInvoiceById(invoiceId).then(BinamikInvoiceParser.details)
  },
  approvePayment (invoiceId) {
    return binamikInvoiceDomain.updateStatus(invoiceId)
  }
}
