/** @jsxRuntime classic */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { css, jsx } from '@emotion/core'
import { SidebarSection, LogoMenuItem, CircleThumbnail, ResponsiveImage, colors, flexColumn, flexCenteredSpaceBetweenRow, flex, CloseIcon, BREAK_POINTS } from '@bonitour/components'

import { NavLink } from 'react-router-dom'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'
import { Shortcuts } from './Shortcuts'
import { Products } from './Products'
import { useUser } from 'Shared/contexts/User'
import { getFirstLetter } from 'Shared/utils/getFirstLetter'

const container = css`
  margin: 20px 0 10px 0;
  padding: 15px 10px;
  border: 1px solid ${colors.gray8};
  border-radius: 8px;
`

const noDecorationLine = css`
  text-decoration: none;
`

const imageContainer = css`
  margin-right: 5px;
  background-color: ${colors.gray12};
  font-weight: bold;
  text-transform: capitalize;
  color: ${colors.gray4};
`

const nameStyle = css`
  font-size: 15px;
  font-weight: bold;
  color: ${colors.gray3};
`

const emailStyle = css`
  margin-top: 5px;
  max-width: 200px;
  overflow: hidden;
  font-size: 14px;  
  color: ${colors.gray5};
`

const sectionSize = css`
  width: 230px;
  box-sizing: border-box;
  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    display: none;
  }
`

const sectionsContainer = css`
  display: flex;
  flex-direction: row;
`

const closeIcon = css`
  align-self: center;
  font-size: 24px;
  color: ${colors.gray3};
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`

export const Menu = ({
  onNavigation = identity
}) => {
  const { user } = useUser()
  const { name, avatar, email } = user

  return (
    <div css={sectionsContainer}>
      <SidebarSection>
        <span css={[flex, flexCenteredSpaceBetweenRow]}>
          <NavLink css={noDecorationLine} to='/app'>
            <LogoMenuItem onClick={onNavigation} logoSrc={BinamikLogo} />
          </NavLink>
          <CloseIcon css={closeIcon} onClick={onNavigation} />
        </span>

        <div css={[container, flexCenteredSpaceBetweenRow]}>
          <CircleThumbnail size={30} css={imageContainer}>
            {avatar ? <ResponsiveImage src={avatar} alt={`Foto de ${name}`} /> : getFirstLetter(name)}
          </CircleThumbnail>
          <div css={flexColumn}>
            <p css={nameStyle}>{name}</p>
            <p css={emailStyle}>{email}</p>
          </div>
        </div>

        <Shortcuts />
      </SidebarSection>

      <SidebarSection css={sectionSize}>
        <Products />
      </SidebarSection>
    </div>
  )
}
