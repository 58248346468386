/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  H3,
  Label,
  Input,
  InputZipCodeMask,
  Select,
  flexColumn,
  flexCenteredSpaceBetweenRow,
  InputFormGroup,
  Row,
  Column,
  flexHorizontallyCentered
} from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { required } from 'assets/styles/global'
import { buttonStyle, cep, inputGroup, marginBottom20, marginTop50, marginTop7, padding0, width130 } from './Address.style'

const getLocalityName = (options, key) =>
  options.filter((item) => item.value === key).reduce((acc, item) => acc + item.label, '')

export const Address = ({
  addressInfo = {},
  addressInfoErrors = {},
  onInputBlur: emitInputBlur = identity,
  onChange: emitChangeEvent = identity,
  onCountryChange: emitCountryChangeEvent = identity,
  onStateChange: emitStateChangeEvent = identity,
  onZipCodeBlur: emitZipCodeBlur = identity,
  onLatLongBlur: emitLatLongBlur = identity,
  countries = [],
  states = [],
  cities = [],
  timeZoneOptions = []
}) => {
  const {
    zipCode = '',
    address = '',
    addressDetail = '',
    district = '',
    country = '',
    state = '',
    city = '',
    latLong = '',
    timezone = ''
  } = addressInfo

  const getLatLng = () => {
    const countryName = getLocalityName(countries, country)
    const stateName = getLocalityName(states, state)
    const cityName = getLocalityName(cities, city)
    const addressParam = `${zipCode} ${address} ${addressDetail} ${district} ${countryName} ${stateName} ${cityName}`
    emitLatLongBlur(addressParam)
  }

  return (
    <>
      <H3 css={[marginTop50, marginBottom20]}>
        Endereço
        <span css={required}>(Obrigatório)</span>
      </H3>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20, cep]}>
        <div css={[flexColumn, inputGroup, width130]}>
          <Label htmlFor='addressInfo.zipCode'>CEP</Label>
          <InputZipCodeMask
            id='addressInfo.zipCode'
            value={zipCode}
            error={addressInfoErrors.zipCode}
            onChange={emitChangeEvent('addressInfo.zipCode')}
            onBlur={emitZipCodeBlur}
          />
        </div>
        <div css={[flexColumn, inputGroup, marginTop7]}>
          <Label htmlFor='addressInfo.address'>Endereço</Label>
          <Input
            id='addressInfo.address'
            value={address}
            error={addressInfoErrors.address}
            onChange={emitChangeEvent('addressInfo.address')}
            onBlur={emitInputBlur('addressInfo.address')}
          />
        </div>
        <div css={[flexColumn, inputGroup, width130, marginTop7]}>
          <Label htmlFor='addressInfo.addressDetail'>Número</Label>
          <Input
            id='addressInfo.addressDetail'
            value={addressDetail}
            error={addressInfoErrors.addressDetail}
            onChange={emitChangeEvent('addressInfo.addressDetail')}
            onBlur={emitInputBlur('addressInfo.addressDetail')}
          />
        </div>
        <div css={[flexColumn, inputGroup, marginTop7]}>
          <Label htmlFor='addressInfo.district'>Bairro</Label>
          <Input
            id='addressInfo.district'
            value={district}
            error={addressInfoErrors.district}
            onChange={emitChangeEvent('addressInfo.district')}
            onBlur={emitInputBlur('addressInfo.district')}
          />
        </div>
      </div>

      <Row css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <Column desktop={4} css={padding0}>
          <div css={flexColumn}>
            <Label>País</Label>
            <Select
              placeholder='Selecione um país'
              options={countries}
              value={country}
              error={addressInfoErrors.country}
              onChange={emitCountryChangeEvent}
              onBlur={emitInputBlur('addressInfo.country')}
            />
          </div>
        </Column>
        <Column desktop={4} css={[padding0, marginTop7]}>
          <div css={flexColumn}>
            <Label>Estado</Label>
            <Select
              value={state}
              placeholder='Selecione um estado'
              options={states}
              error={addressInfoErrors.state}
              onChange={emitStateChangeEvent}
              onBlur={emitInputBlur('addressInfo.state')}
            />
          </div>
        </Column>
        <Column desktop={4} css={[padding0, marginTop7]}>
          <div css={flexColumn}>
            <Label>Cidade</Label>
            <Select
              value={city}
              placeholder='Selecione uma cidade'
              options={cities}
              error={addressInfoErrors.city}
              onChange={emitChangeEvent('addressInfo.city')}
              onBlur={emitInputBlur('addressInfo.city')}
            />
          </div>
        </Column>
      </Row>
      <Row>
        <Column desktop={4} >
          <InputFormGroup
            label='Fuso horário'
            errorMessage={addressInfoErrors.timezone}
            id='addressInfo.timezone'
            css={[width130]}
          >
            <Select
              value={timezone}
              onChange={emitChangeEvent('addressInfo.timezone')}
              onBlur={emitChangeEvent('addressInfo.timezone')}
              options={timeZoneOptions}
              placeholder='Selecione o fuso horário'
            />
          </InputFormGroup>
        </Column>

        <Column desktop={4} >
          <InputFormGroup
            label='Latitude e Longitude'
            errorMessage={addressInfoErrors.latLong}
            id='addressInfo.latLong'
            css={[padding0, marginTop7]}
          >
            <Input
              value={latLong}
              onChange={emitChangeEvent('addressInfo.latLong')}
              onBlur={emitChangeEvent('addressInfo.latLong')}
              placeholder='Latitude , Longitude'
            />
          </InputFormGroup>
        </Column>
        <Column desktop={4}>
          <div css={[buttonStyle, flexHorizontallyCentered]} onClick={getLatLng}>Obter coordenadas</div>
        </Column>
      </Row>
    </>
  )
}
