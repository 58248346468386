/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, flexRowCentered, flexHorizontallyCentered, Input, TrashIcon, Email } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { alignTrashIconAtEnd, clickable } from 'assets/styles/global'

import {
  width5,
  width95,
  iconSize,
  nameInputWidth,
  mailInputWidth,
  sameLineInput,
  customCardPadding,
  stylishCard,
  fieldContainer,
  fieldSection
} from './ContactEmail.style'

export const ContactEmail = ({
  errors = [],
  onBlur: emitBlurEvent = identity,
  onChange: emitChangeEvent = identity,
  onDelete: emitDeleteEvent = identity,
  email = {},
  index = 0
}) => {
  const { title = '', address = '' } = email

  return (
    <Card css={[customCardPadding, stylishCard]}>
      <div css={flexRowCentered}>
        <div css={[flexHorizontallyCentered, width95, fieldContainer]}>
          <div css={fieldSection}>
            <p>Contato</p>
            <div css={sameLineInput}>
              <Input
                css={nameInputWidth}
                value={title}
                error={Boolean(errors.title)}
                onBlur={emitBlurEvent(`emails.${index}.title`)}
                onChange={emitChangeEvent(`emails.${index}.title`)}
                placeholder='Ex.Financeiro Isabela'
              />
            </div>
          </div>
          <div css={fieldSection}>
            <p css={sameLineInput}>e o endereço de e-mail é</p>
            <div css={sameLineInput}>
              <Email
                css={mailInputWidth}
                value={address}
                error={Boolean(errors.address)}
                onBlur={emitBlurEvent(`emails.${index}.address`)}
                onChange={emitChangeEvent(`emails.${index}.address`)}
                placeholder='contato@empresa.com.br'
              />
            </div>
          </div>
        </div>
        <div css={[width5, alignTrashIconAtEnd, flexHorizontallyCentered]}>
          {Boolean(index) && <TrashIcon css={[iconSize, clickable]} onClick={emitDeleteEvent} />}
        </div>
      </div>
    </Card>
  )
}
