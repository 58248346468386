/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Email, Password, InputFormGroup, Input, ErrorTooltip, Checkbox } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { useCallback, useEffect } from 'react'
import {
  inputMargin,
  hideError,
  errorTooltip,
  errorTooltipHorizontalForm,
  checkboxContainer,
  checkbox
} from './Form.style'
import { useQuery } from 'Shared/hooks/useQuery'

export const SignupForm = ({
  signup = {},
  signupErrors = {},
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity
}) => {
  const { name = '', email = '', emailConfirmation = '', password = '', serviceTerms } = signup
  const handleChangeTermsConfirmation = useCallback((event) => {
    emitChangeEvent('serviceTerms')(event.target.checked)
  }, [emitChangeEvent])

  const { email: queryEmail } = useQuery()
  useEffect(() => {
    if (queryEmail) {
      emitChangeEvent('email')(queryEmail)
      emitChangeEvent('emailConfirmation')(queryEmail)
    }
  }, [queryEmail, emitChangeEvent])

  return (
    <>
      <InputFormGroup css={inputMargin} errorMessage={signupErrors.name} label='Nome Completo'>
        <Input name='name' value={name} onChange={emitChangeEvent('name')} onBlur={emitBlurEvent('name')} required />
      </InputFormGroup>

      <InputFormGroup css={inputMargin} errorMessage={signupErrors.email} label='E-mail'>
        <Email
          name='email'
          value={email}
          onChange={emitChangeEvent('email')}
          onBlur={emitBlurEvent('email')}
          required
        />
      </InputFormGroup>

      <InputFormGroup css={inputMargin} errorMessage={signupErrors.emailConfirmation} label='Confirmação do E-mail'>
        <Email
          name='email-confirmation'
          value={emailConfirmation}
          onChange={emitChangeEvent('emailConfirmation')}
          onBlur={emitBlurEvent('emailConfirmation')}
          required
        />
      </InputFormGroup>

      <InputFormGroup css={inputMargin} errorMessage={signupErrors.password} label='Senha'>
        <Password
          name='password'
          value={password}
          onChange={emitChangeEvent('password')}
          onBlur={emitBlurEvent('password')}
          required
        />
      </InputFormGroup>

      <div css={checkboxContainer}>
        <ErrorTooltip css={[errorTooltip, errorTooltipHorizontalForm, hideError(serviceTerms || !signupErrors?.serviceTerms)]}>
          {signupErrors?.serviceTerms || ''}
        </ErrorTooltip>

        <Checkbox
          id='privacy_terms_checkbox'
          css={checkbox}
          name='service-terms'
          value={serviceTerms}
          onChange={handleChangeTermsConfirmation}
        />
        <label htmlFor='privacy_terms_checkbox'>
          <p>
            Declaro que li e concordo com os&nbsp;<wbr />
            <a href={`${process.env.REACT_APP_ACCOUNTS_URL}/termo-de-uso`} target='_blank' rel='noopener noreferrer'>Termos</a>
            <wbr />&nbsp;e&nbsp;<wbr />
            <a href={`${process.env.REACT_APP_ACCOUNTS_URL}/politica-de-privacidade`} target='_blank' rel='noopener noreferrer'>Política&nbsp;de&nbsp;Privacidade</a>
          </p>
        </label>
      </div>
    </>
  )
}
