import { css } from '@emotion/core'
import { BREAK_POINTS, colors, flexRowCentered } from '@bonitour/components'

export const priceCard = css`
  background-color: ${colors.indigo3};
  color: ${colors.primary};
  border-color: ${colors.primary};
  padding: 5px 10px;
  margin: 5px 0;
  font-weight: 700;
  font-size: 14px;
`

export const copyButton = css`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  border-top: 1px solid ${colors.gray9};
  padding: 10px;
  color: ${colors.gray4};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 400;
  font-size: 12px;
  i {
    font-size: 24px;
  }
`

export const avatar = css`
  position: relative;
  padding-bottom: 60px;
  cursor: pointer;
`

export const companiesContainer = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    justify-content: center;
  }
`

export const loadingContainer = css`
  ${flexRowCentered};
  width: 100%;
  margin: 50px 0;
`
